import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, PopulationQuery } from '@fgb/core';
import { ErrorStateService } from '@fgb/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
})
export class MaintenancePageComponent implements OnInit {
  showAppleLink = true;
  showGoogleLink = true;
  populations = Populations;
  isCorporate: boolean;

  constructor(
    private router: Router,
    public errorService: ErrorStateService,
    private authService: AuthService,
    public deviceDetector: DeviceDetectorService,
    private populationQuery: PopulationQuery
  ) {
    if (this.authService.checkMaintenceMode()) {
      if (this.authService.hasCurrentRefreshToken()) {
        this.router.navigate(['/']);
      } else {
        this.router.navigate(['login']);
      }
    }
  }

  ngOnInit() {
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
    this.downloadAppLink();
  }

  downloadAppLink() {
    if (this.deviceDetector.os.match('iOS')) {
      this.showGoogleLink = false;
    } else if (this.deviceDetector.os.match('Android')) {
      this.showAppleLink = false;
    }
  }
}
