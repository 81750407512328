<fgb-banner [title]="'polls.page.banner' | translate"></fgb-banner>

<fgb-game-nav></fgb-game-nav>

<div class="container">
  <ng-container *ngIf="polls$ | async as polls">
    <div *ngIf="!polls.length">
      <div class="container">
        <div class="no-items mx-0 w-100 mt-4 no-results-placeholder-div">
          <div class="no-results-placeholder">{{ 'polls.placeholder.description' | translate }}</div>
        </div>
      </div>
    </div>
    <div *ngFor="let poll of polls">
      <fgb-survey-item [survey]="poll"></fgb-survey-item>
    </div>
  </ng-container>
</div>
