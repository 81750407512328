<div class="survey-answer">
  <div
    class="survey-answer-container"
    [ngClass]="{
      'py-3': survey.SurveyType !== surveyType.Predictor,
      'py-0 h-200px': survey.SurveyType === surveyType.Predictor && survey.AnswerDisplayType !== answerDisplayType.Text,
      'py-0 h-300px':  survey.SurveyType === surveyType.Predictor && survey.AnswerDisplayType === answerDisplayType.Text,
      'text-answer': survey.AnswerDisplayType == answerDisplayType.Text,
      'mx-lg-5': survey.SurveyType === surveyType.Poll
    }"
  >
    <div class=" {{ margins }} {{ colDisplayType }}" *ngFor="let answer of survey.SurveyAnswers">
      <div
        class="survey-answer-item cursor-pointer"
        (click)="selectAnswer(answer.SurveyAnswerId)"
        [ngClass]="{
          selected: selectedAnswers.includes(answer.SurveyAnswerId),
          'w-100': survey.SurveyType === surveyType.Predictor,
          'w-90': survey.SurveyType === surveyType.Poll
        }"
      >
        <ng-container [ngSwitch]="survey.AnswerDisplayType">
          <!-- Text with Image -->
          <ng-container *ngSwitchCase="answerDisplayType.ImageAndText">
            <div *ngIf="this.imgDisplayType === 'Portrait'">
              <div class="image-container">
                <img src="{{ answer.ImageURL | contentImage: '1' }}" alt="{{ answer.ImageURL }}" />
              </div>
              <div class="answer-text text-center bg-white d-flex justify-content-center align-items-center">
                <span>{{ answer.Title }}</span>
              </div>
            </div>
            <div *ngIf="this.imgDisplayType === 'Landscape'" class="flex-row" [ngClass]="survey.SurveyType === surveyType.Predictor ? 'd-block predictor' : 'd-flex' ">
              <img class="image-container icon" src="{{ answer.ImageURL | contentImage: '1' }}" alt="{{ answer.ImageURL }}" />
              <div class="answer-text-landscape text-center bg-white font-size-12" [ngClass]="isCorporate ? 'bg-primary-corporate' : 'bg-primary'">
                {{ answer.Title }}
              </div>
            </div>
          </ng-container>
          <!-- Text Only -->
          <ng-container *ngSwitchCase="answerDisplayType.Text">
            <div class="answer-text text-center px-3 text-type col-12 bg-white" [ngClass]="isCorporate ? 'bg-primary-corporate' : 'bg-primary'">
              <span>{{ answer.Title }}</span>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
