<ul class="nav nav-secondary mixed-loyalty-nav mt-0">
  <a
    class="nav-item btn"
    [routerLink]="['/rewards']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
  >
    <fgbcl-loyalty-icon [loyaltyType]="4"></fgbcl-loyalty-icon>
    <label>{{ 'rewards.nav.marketplace' | translate }}</label>
  </a>
  <ng-container *notInPopulation="[population.Free]">
    <a
      class="nav-item btn d-none d-lg-block"
      [routerLink]="['/rewards/wallet']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <fgbcl-loyalty-icon [loyaltyType]="'wallet'"></fgbcl-loyalty-icon>
      <label>{{ 'rewards.nav.wallet' | translate }} </label>
    </a>
  </ng-container>
</ul>
