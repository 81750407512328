<fgb-banner [title]="'account.activity.page.title' | translate" class="d-lg-flex d-none"></fgb-banner>

<div class="container">
  <div class="d-lg-none d-block pt-3">
    <fgb-barcode></fgb-barcode>
  </div>
</div>

<fgb-account-nav></fgb-account-nav>

<div class="container mt-4">
  <div class="points-history">
    <fgb-transaction-history [transactionTypes]="[transactionTypes.loyalty]" [accountView]="true"></fgb-transaction-history>
  </div>
</div>
