import { Component, OnInit } from '@angular/core';
import { PopulationQuery, SurveyType } from '@fgb/core';
import { SurveyQuery } from '@fgb/core';
import { Survey } from '@fgb/core';
import { Observable } from 'rxjs';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-polls-page',
  templateUrl: './polls-page.component.html',
  styleUrls: ['./polls-page.component.scss'],
})
export class PollsPageComponent implements OnInit {
  polls$: Observable<Survey[]>;
  populations = Populations;
  isCorporate: boolean;
  constructor(private surveyQuery: SurveyQuery, private populationQuery: PopulationQuery) {}

  ngOnInit() {
    this.polls$ = this.surveyQuery.selectRelevantSurveys(SurveyType.Poll);
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
  }
}
