<ng-container *ngIf="transactions$ | async as transactions">
  <ng-container *ngIf="transactions$ | async as transactions">
    <div *ngFor="let day of transactions | keyvalue : keepOrder">
      <h5 class="transaction-date mt-3 text-black text-uppercase">{{ day.key }}</h5>
      <div class="mb-0 mb-lg-4">
        <div class="card my-2 py-2 px-3" *ngFor="let transaction of day.value">
          <div class="row">
            <div class="col-8 text-start">
              <div class="font-sans-bold text-black transaction-text">
                {{ transaction.TransactionDescription }}
              </div>

              <div class="transaction-subtext font-sans-bold text-black">
                {{ transaction.TransactionDateUTC | UtcToLocaltimePipe : 'hh:mm a' | uppercase }}
              </div>
            </div>
            <div class="col-4 text-end pt-2">
              <div
                class="transaction-text text-capitalize font-sans-bold"
                [ngClass]="transaction.Amount >= 0 ? 'text-success' : 'text-error'"
              >
                {{ transaction.Amount >= 0 ? '+' : ''
                }}{{ transaction.Amount | FortressCurrencyPipe : (transactionType === transactionTypes.loyalty ? 'PTS' : 'USD') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="(!transactions || !hasTransactions) && transactionType === transactionTypes.loyalty">
      <div class="mt-4 no-result-container">
        <p class="text-black text-center font-size-12 mb-0">{{ 'account.activity.placeholder.history.description' | translate }}</p>
      </div>
    </ng-container>
    <ng-container *ngIf="(!transactions || !hasTransactions) && transactionType === transactionTypes.ecash">
      <div>
        <img class="no-ecash-image d-flex mx-auto" src="assets/images/icons/no-transactions.svg" />
        <h5 class="text-center mt-3 mb-0 font-weight-bold text-uppercase placeholder-title font-size-16">No Transactions</h5>
        <p class="text-info text-center font-size-12 font-default">
          {{ 'account.activity.placeholder.history.description' | translate }}
        </p>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
