import { Component, OnInit, Input } from '@angular/core';
import { Game, config, PopulationQuery } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-game-card',
  templateUrl: './game-card.component.html',
  styleUrls: ['./game-card.component.scss'],
})
export class GameCardComponent implements OnInit {
  populations = Populations;
  isCorporate: boolean;
  @Input() game: Game;

  constructor(private populationQuery: PopulationQuery) {}

  ngOnInit() {
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
  }

  getTeamLogoUrl(teamId: number) {
    return `${config.contentUrl}/image/teams/${teamId}__1_1_1033.png`;
  }
}
