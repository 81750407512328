import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PopulationQuery } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-lotto-detail-page',
  templateUrl: './lotto-detail-page.component.html',
  styleUrls: ['./lotto-detail-page.component.scss'],
})
export class LottoDetailPageComponent implements OnInit {
  id: number;
  isCorporate: boolean;
  populations = Populations;
  constructor(private route: ActivatedRoute, private populationQuery: PopulationQuery) {}

  ngOnInit() {
    this.id = +(this.route.snapshot.paramMap.get('id') || 0);
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
  }
}
