<fgb-banner [title]="'score.predictor.page.banner' | translate"></fgb-banner>

<fgb-game-nav></fgb-game-nav>

<div class="container">
  <div class="border border-primary p-3 mb-3 font-size-14 rounded">
    <div>
      {{ 'scorepredictor.points.how.description' | translate }}
    </div>
  </div>
</div>

<div class="container">
  <ng-container *ngIf="scorePredictors$ | async as scorePredictors">
    <div *ngIf="!scorePredictors.length">
      <div class="no-items w-100 text-center">
        <div class="icon material-icons text-grey">sports_score</div>
        <h5
          class="fw-bold text-primary text-uppercase"
          [ngClass]="isCorporate ? 'text-secondary-corporate corporate-font' : 'text-primary default-font'"
        >
          {{ 'score.predictor.placeholder.title' | translate }}
        </h5>
        <div class="no-results-placeholder-text text-info mb-5">{{ 'score.predictor.placeholder.description' | translate }}</div>
      </div>
    </div>
    <div class="score-predictor-owl">
      <fgb-score-predictor-list></fgb-score-predictor-list>
    </div>
  </ng-container>
</div>
