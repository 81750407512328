import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService, InputType, KeyValuePair, MemberQuery, ProviderName } from '@fgb/core';
import { OutputField } from '@fgb/core/lib/modules/user/models/outputfield.model';
import { tap } from 'rxjs';

@Component({
  selector: 'fgb-endurance-zone',
  templateUrl: './endurance-zone.component.html',
  styleUrls: ['./endurance-zone.component.scss'],
})
export class EnduranceZoneComponent implements OnInit {
  safeUrl: SafeResourceUrl;

  constructor(private authService: AuthService, private memberQuery: MemberQuery, private domSanitizer: DomSanitizer) {}

  ngOnInit() {
    this.logoffEnduranceZoneIframe();
  }

  logoffEnduranceZoneIframe() {
    let user = this.memberQuery.getUserContext();

    let options: KeyValuePair[] = [{ key: 'MemberId', value: user.memberId }];
    this.authService
      .loginThirdPartyObservable(ProviderName.EnduranceZone, 1, options)
      .pipe(
        tap((data) => {
          let outputs = data as OutputField[];
          const hasUrl = outputs.find((x) => x.InputType == InputType.Url);
          if (hasUrl) {
            this.safeUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(hasUrl.Value);
          }
        })
      )
      .toPromise();
  }
}
