<div class="container-fluid">
  <div class="row d-lg-none text-center">
    <div class="col-12 shadow py-1" [ngClass]="isCorporate ? 'bg-primary-corporate text-white' : 'bg-white text-primary'">
      <div class="d-flex justify-content-center align-items-center h-100">
        <span class="icon icon-trophy-1 font-size-16"></span>
        <span class="ps-1 graphie-regular">{{ points$ | async }}</span>
      </div>
    </div>
  </div>
</div>
