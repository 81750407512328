import { Component, OnInit } from '@angular/core';
import { VoucherService, VoucherRedeemModel, BalanceService, config, PopulationQuery, ErrorStateService } from '@fgb/core';
import { take } from 'rxjs/operators';
import { Populations } from 'src/app/shared/utilities/populations';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-vouchers',
  templateUrl: './vouchers.component.html',
  styleUrls: ['./vouchers.component.scss'],
})
export class VouchersComponent implements OnInit {
  voucherCode: string;
  redemptionResult: string;
  successMessage: string = 'Code successfully redeemed';
  isCorporate: boolean;
  populations = Populations;
  disabled: boolean;

  constructor(
    private voucherService: VoucherService,
    private balanceService: BalanceService,
    private populationQuery: PopulationQuery,
    private errorService: ErrorStateService
  ) {}

  ngOnInit() {
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
  }

  redeemVoucher() {
    this.disabled = true;
    if (!this.voucherCode) {
      this.redemptionResult = 'Please enter a valid code';
      this.disabled = false;
      this.clearMessages();
      return;
    }

    let voucher = new VoucherRedeemModel();
    voucher.VoucherCode = this.voucherCode;

    this.redemptionResult = '';
    firstValueFrom(this.voucherService
      .redeemVoucher(voucher))
      .then((item) => {
        this.balanceService.fetchBalance(config.purseConfig.virtualLoyalty).pipe(take(1)).subscribe();
        this.redemptionResult = this.successMessage;
      })
      .finally(() => {
        this.disabled = false;
        this.clearMessages();
      });
  }

  private clearMessages() {
    setTimeout(() => {
      this.redemptionResult = '';
      this.voucherCode = '';
      this.errorService.clearErrors();
    }, 10000);
  }
}
