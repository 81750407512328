<div class="card">
  <div *ngIf="!allowEditing">
    <div *ngIf="member$ | async as member">
      <div class="mb-2">
        <label class="mb-2 personal-info font-sans-bold">{{ 'account.details.email' | translate }}</label>
        <div class="d-flex bg-muted p-2 m-0 mt-1 rounded align-items-center">
          <span class="icon material-icons font-size-24 align-self-center d-flex">mail</span>
          <span class="ms-3 text-black member-details-input">{{ member.EmailAddress1 }}</span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="allowEditing">
    <fgb-edit-account-details></fgb-edit-account-details>
  </div>
</div>
<ng-template #loadingRef>
  <fgb-loading></fgb-loading>
</ng-template>
