<div class="px-4 pt-3">
  <div class="row mb-2 font-sans-bold font-size-12">
    <div class="col-2 col-lg-3">{{ 'pick.player.leaderboard.rank' | translate }}</div>
    <div class="col-6">{{ 'pick.player.leaderboard.name' | translate }}</div>
    <div class="col-4 col-lg-3 text-end">{{ 'pick.player.leaderboard.points' | translate }}</div>
  </div>
  <div
    class="font-size-12 mb-2 fantasy-row"
    *ngFor="let entry of fantasyEntries; let i = index"
    [ngClass]="{ 'current-user-row': entry.IsCurrentUser , 'current-user-border': entry.IsCurrentUser && entry.Position > maximumPosition }"
  >
    <div class="row mb-2" [ngClass]="{ 'rounded text-white p-2 mx-0 mb-2 current-user': entry.IsCurrentUser, 'bg-primary' : !isCorporate && entry.IsCurrentUser, 'bg-primary-corporate' : isCorporate && entry.IsCurrentUser }">
      <div class="col-2 col-lg-3 font-sans-bold">
        {{ entry.Position }}
      </div>
      <div class="col-6 text-capitalize text-truncate" [ngClass]="{ 'text-info': !entry.IsCurrentUser }">
        {{ entry.FullName }}
      </div>
      <div class="col-3 col-lg-3 text-end" [ngClass]="{ 'text-info': !entry.IsCurrentUser }">
        {{ entry.Points / 100 }}
      </div>
    </div>
  </div>
</div>
