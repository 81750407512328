<div>
  <div class="row">
    <div class="col-lg-2 col-3">
      <div class="player-img-container rounded-circle overflow-hidden mx-auto">
        <img src="{{ fantasyHistoryItem.PlayerImage | contentImage : '1' }}" class="h-100 w-100" />
      </div>
    </div>
    <div class="col-6 d-flex my-1 my-lg-0 align-items-center ps-2">
      <div>
        <div class="font-size-14 font-sans-bold">
          {{ fantasyHistoryItem.PlayerName }}
        </div>
        <div class="d-inline-flex">
          <div class="font-default text-black">{{ 'game.versus' | translate }}</div>
          <div class="font-size-12 ms-2 mt-1">{{ fantasyHistoryItem.AwayTeamName }} - {{ gameDate | date : 'dd, MMM' }}</div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-3 d-flex align-items-center justify-content-end pe-lg-3 ps-0">
      <div
        class="text-end font-size-14 font-sans-bold"
        *ngIf="!isPending; else pointsPending"
        [ngClass]="{
          'text-primary': fantasyHistoryItem.PlayerGamePoints > 0 && !isCorporate,
          'text-primary-corporate': fantasyHistoryItem.PlayerGamePoints > 0 && isCorporate,
          'text-info ': fantasyHistoryItem.PlayerGamePoints === 0
        }"
      >
        <span *ngIf="fantasyHistoryItem.PlayerGamePoints > 0">+</span>
        {{ fantasyHistoryItem.PlayerGamePoints / 100 | FortressCurrencyPipe : 'PTS' }}
      </div>
      <ng-template #pointsPending>
        <span class="font-size-14 text-info font-sans-bold text-end">{{ 'pick.player.history.points.pending' | translate }}</span>
      </ng-template>
    </div>
  </div>
</div>
