import { Component, OnInit, Input } from '@angular/core';
import { MobilePassService, LoggingService } from '@fgb/core';
import { tap } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobilePassModalComponent } from '../mobile-pass-modal/mobile-pass-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-mobile-pass',
  templateUrl: './mobile-pass.component.html',
  styleUrls: ['./mobile-pass.component.scss']
})
export class MobilePassComponent implements OnInit {
  @Input() memberId: string;

  constructor(
    private mobilePassService: MobilePassService,
    public deviceDetector: DeviceDetectorService,
    private loggingService: LoggingService,
    private modalService: NgbModal,
    ) { }

  ngOnInit() {
  }

  downloadPass() {
    firstValueFrom(this.mobilePassService.fetchPass(this.memberId).pipe(tap(url => {
      if (this.deviceDetector.isMobile()) {
        // Produce a QR code for the mobile link so a user is redirected to the mobile link
        // when scanning on their phone.
        this.loggingService.info('Desktop detected, formatting QR code.');
        const modal = this.modalService.open(MobilePassModalComponent);
        modal.componentInstance.url = url;
      }})));
  }
}
