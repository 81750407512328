<div class="full-page bg-primary container-fluid w-100">
  <div class="row">
    <div class="col-12">
      <div class="panel rounded overflow-hidden p-0">
        <div class="row">
          <div class="col-lg-7 d-none d-lg-block login-banner"></div>
          <div class="user-login-panel col-lg-5 col-12 text-start bg-white">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="text panel-text font-size-14 text-center">
                    <p>Attempting to login</p>
                    <fgb-loading [size]="'small'"></fgb-loading>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
