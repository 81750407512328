<div class="d-none d-lg-block">
  <fgb-banner [title]="'wallet.page.banner' | translate"></fgb-banner>
</div>

<div class="d-block d-lg-none">
  <div class="container my-3">
    <fgb-barcode></fgb-barcode>
  </div>
</div>

<div class="d-none d-lg-block">
  <fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>
</div>

<ng-container
  *ngIf="
    {
      walletItemsUnclaimed: walletItemsUnclaimed$ | async
    } as walletItems;
    else noWalletItems
  "
>
  <div class="wallet-redeem-carousel pt-lg-3 pt-1">
    <div class="container">
      <ng-container *ngIf="walletItems.walletItemsUnclaimed && walletItems.walletItemsUnclaimed.length; else noWalletItems">
        <fgbcl-rewards-wallet-redeem-list [unRedeemedItems]="walletItems.walletItemsUnclaimed" [ngClass]="isCorporate ? 'corporate' : 'membership'"></fgbcl-rewards-wallet-redeem-list>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #noWalletItems>
  <div class="container">
    <h5 class="no-wallet-items mt-lg-3 mb-3 default-font">{{ 'rewards.wallet.no.items' | translate }}</h5>
    <div class="border rounded">
      <div class="no-wallet-items-description fw-bold font-size-12 text-center text-grey py-3">
        {{ 'rewards.wallet.no.items.description' | translate }}
      </div>
    </div>
  </div>
</ng-template>

<div class="container mt-3 text-center">
  <a class="font-size-14 claimed-text fw-bold" [routerLink]="['/rewards/claimed']">
    {{ 'rewards.wallet.view.claimed' | translate }}
  </a>
</div>

