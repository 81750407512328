import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Injectable } from '@angular/core';

@Injectable()
export class MomentDateFormatter extends NgbDateParserFormatter {
  readonly DT_FORMAT = 'DD MMM, YYYY';

  parse(value: string): NgbDateStruct {
    let result: NgbDateStruct | null = null;
    if (value) {
      value = value.trim();
      let mdt = moment(value, this.DT_FORMAT);
      result = { year: mdt.year(), month: mdt.month() + 1, day: mdt.date() };
    }
    return result as NgbDateStruct;
  }

  format(date: NgbDateStruct): string {
    if (!date) return '';
    let mdt = moment([date.year, date.month - 1, date.day]);
    if (!mdt.isValid()) return '';
    return mdt.format(this.DT_FORMAT);
  }
}
