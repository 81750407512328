import { Component, OnInit } from '@angular/core';
import { PopulationQuery } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';
@Component({
  selector: 'fgb-auctions-page',
  templateUrl: './auctions-page.component.html',
  styleUrls: ['./auctions-page.component.scss'],
})
export class AuctionsPageComponent implements OnInit {
  populations = Populations;
  isCorporate: boolean;
  constructor(private populationQuery: PopulationQuery) {}

  ngOnInit() {
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
  }
}
