<ng-container *ngIf="worksheets$ | async as worksheets; else loadingRef">
  <div *ngFor="let worksheetGroup of worksheets">
    <ng-container *ngIf="worksheetGroup.Items?.length > 0">
      <div [class.row]="isList">
        <div
          *ngFor="let item of worksheetGroup.Items"
          class="{{ colOverride }}"
          [ngClass]="isList && !colOverride ? 'col-lg-4' : ''"
        >
          <div>
            <fgb-worksheet-item [worksheetItem]="item" [worksheetId]="worksheetGroup.WorksheetId"></fgb-worksheet-item>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>

<!-- Loading -->
<ng-template #loadingRef>
  <div class="w-100">
    <fgb-loading></fgb-loading>
  </div>
</ng-template>
