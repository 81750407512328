<fgb-banner title="Rewards"></fgb-banner>

<fgb-mixed-loyalty-nav class="d-none d-lg-block"></fgb-mixed-loyalty-nav>

<fgb-filter-modal [filterTypesList]="filterTypesList" (filterTypesListEmitter)="handleFilterTypesListEmitter($event)"
  [selectedValues]="selectedValues" (selectedValuesEmitter)="handleSelectedValuesEmitter($event)"
  [showOffCanvas]="showOffCanvas" (showOffCanvasEmitter)="handleShowOffCanvasEmitter()">
</fgb-filter-modal>

<div class="container mt-3">
  <fgbcl-mixed-loyalty-list [onlyFavourites]="true" [filterOptionsList]="selectedValues" [ngClass]="{'junior': isJuniorBluebirds}"></fgbcl-mixed-loyalty-list>
</div>