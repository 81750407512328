import { Component, OnInit } from '@angular/core';
import { PopulationQuery } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';
@Component({
  selector: 'fgb-rewards-page',
  templateUrl: './rewards-page.component.html',
  styleUrls: ['./rewards-page.component.scss'],
})
export class RewardsPageComponent implements OnInit {
  populations = Populations;
  isCorporate: boolean;
  constructor(private populationQuery: PopulationQuery) {}
  filterTypesList: { label: string, value: string }[] = [{label: 'reward.filter_modal.all', value: 'all'}];
  selectedValues: string[] = ['all'];
  showOffCanvas: boolean = false;
  isJuniorBluebirds: boolean;
  
  handleFilterTypesListEmitter(filterTypes: {label: string, value: string}[]) {
    this.filterTypesList = [...filterTypes];
  }

  handleSelectedValuesEmitter(selectedValues: string[]) {
    this.selectedValues = [...selectedValues];
  }

  handleShowOffCanvasEmitter() {
    this.showOffCanvas = !this.showOffCanvas;
  }

  ngOnInit() {
    this.isJuniorBluebirds = this.populationQuery.userInPopulation([Populations.JuniorBluebirds]);
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
  }
}
