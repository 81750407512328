<fgb-banner [title]="'account.trophy.page.title' | translate"></fgb-banner>

<fgb-badges-nav></fgb-badges-nav>

<!--Scorecard carousel-->
<div [ngClass]="isCorporate ? 'corporate-carousel-bg' : 'default-carousel-bg'">
  <!-- <fgbcl-scorecard-carousel [version2Design]="true" [icon]="'chevron_right'"></fgbcl-scorecard-carousel> -->
</div>

<div class="container mt-4" [ngClass]="isCorporate ? 'corporate-colors' : 'default-color'">
  <fgbcl-badge-overview-list [phaseThree]="true" [showPlaceholder]="true"></fgbcl-badge-overview-list>
</div>