<div
  class="survey-header d-flex flex-column p-3 justify-content-around text-white"
  [ngClass]="status === scorePredictorStatus.ComingSoon ? 'bg-tertiary' : !canEditAnswer ? 'bg-tertiary' : 'bg-tertiary'"
>
  <div class="score-predictor-header">
    <div class="score-predictor-header-title d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <div class="score-predictor-title">{{ 'score.predictor.title' | translate }}</div>
      </div>
      <div class="score-sponsor-image d-lg-flex align-items-center">
        <ng-container *ngIf="scorePredictor.Sponsor && scorePredictor.Sponsor.SponsorImage">
          <label class="d-none d-lg-block font-weight-normal font-size-8 me-lg-2 mb-0">{{
            'score.predictor.sponsor' | translate
          }}</label>
          <a href="{{ scorePredictor.Sponsor.SponsorLink }}" target="_blank">
            <img
              src="{{ scorePredictor.Sponsor.SponsorImage | contentImage: '6' }}"
              draggable="false"
              alt="Click to access {{ scorePredictor.Sponsor.SponsorText }} website"
            />
          </a>
        </ng-container>
      </div>
    </div>
    <p class="font-size-12">{{ 'score.predictor.description' | translate }}</p>
  </div>
</div>
