<ng-container *ngIf="worksheetItem.WorksheetType == worksheetType.Download">
  <!--Download-->
  <div class="download-card border-0 my-3 rounded position-relative">
    <div>
      <div class="position-absolute worksheet-key text-white">
        <div class="worksheet-title text-uppercase">{{ worksheetItem.Title }}</div>
      </div>
      <img
        class="img-holder rounded"
        [src]="worksheetItem.Image | contentImage : '6' : 'marketplace'"
        [alt]="worksheetItem.Title"
        [useDefault]="true"
        [loyaltyType]="'marketplace'"
        draggable="false"
      />
    </div>

    <div
      (click)="getDownload(worksheetId, worksheetItem.Id)"
      target="_blank"
      class="bg-primary worksheet-download px-5 py-2 text-center cursor-pointer"
    >
      <div class="font-size-14 text-white font-sans-bold">Download</div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="worksheetItem.WorksheetType == worksheetType.Gallery">
  <!-- image -->
  <div class="download-card border-0 my-3 rounded position-relative">
    <div class="bg-activity shadow rounded-top">
      <img
        class="img-holder"
        [src]="worksheetItem.Image | contentImage : '6' : 'marketplace'"
        [alt]="worksheetItem.Title"
        [useDefault]="true"
        [loyaltyType]="'marketplace'"
        draggable="false"
      />
    </div>

    <div class="rounded-bottom worksheet-gallery px-2 py-3 text-start">
      <div class="font-weight-bold download-btn text-white text-center font-size-14 text-uppercase">
        {{ worksheetItem.Title }}
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="worksheetItem.WorksheetType == worksheetType.ProviderVideo">
  <!-- video -->
  <div class="border-0 my-1 rounded">
    <div [ngSwitch]="worksheetItem.ProviderId">
      <ng-container *ngSwitchCase="providerName.Facebook">
        <fgb-facebook-player [streamKey]="worksheetItem.ProviderKey"></fgb-facebook-player>
      </ng-container>
      <ng-container *ngSwitchCase="providerName.YouTube">
        <fgb-youtube-player [streamKey]="worksheetItem.ProviderKey"></fgb-youtube-player>
      </ng-container>
      <ng-container *ngSwitchCase="providerName.StreamingVideoProvider">
        <fgb-svp-player [streamKey]="worksheetItem.ProviderKey"></fgb-svp-player>
      </ng-container>
      <ng-container *ngSwitchCase="providerName.Twitch">
        <fgb-twitch-player [streamKey]="worksheetItem.ProviderKey"></fgb-twitch-player>
      </ng-container>
      <ng-container *ngSwitchCase="providerName.Viemo">
        <fgb-vimeo-player [streamKey]="worksheetItem.ProviderKey"></fgb-vimeo-player>
      </ng-container>
      <ng-container *ngSwitchCase="providerName.Boxcast">
        <fgb-boxcast-player [streamKey]="worksheetItem.ProviderKey"></fgb-boxcast-player>
      </ng-container>
      <ng-container *ngSwitchCase="providerName.Streamable">
        <fgb-streamable-player [streamKey]="worksheetItem.ProviderKey"></fgb-streamable-player>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="worksheetItem.WorksheetType == worksheetType.Link">
  <!-- link -->
  <div class="card border-0 my-3 rounded p-2">
    <div>
      <img
        style="height: 55px; max-width: 200px"
        [src]="worksheetItem.Image | contentImage : '6' : 'marketplace'"
        [alt]="worksheetItem.Title"
        [useDefault]="true"
        [loyaltyType]="'marketplace'"
        draggable="false"
      />

      <div class="my-2 font-weight-bold">{{ worksheetItem.Description }}</div>

      <a
        target="_blank"
        [href]="worksheetItem.LinkUrl"
        class="btn btn-primary bg-primary text-white border-0 font-weight-bold font-size-14"
        >Download</a
      >
    </div>
  </div>
</ng-container>
