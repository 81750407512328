<div class="banner">
  <h1>Leaderboard</h1>
</div>

<fgb-balance-bar></fgb-balance-bar>

<div class="container mt-4">
  <h4 class="text-center">Leaderboard</h4>
  <fgb-leaderboard [toShow]="10"></fgb-leaderboard>
</div>
