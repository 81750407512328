<div class="points-summary-bar d-flex justify-content-between d-lg-none my-1">
  <!-- Back Button -->
  <div class="text-error cursor-pointer d-flex align-items-center d-lg-none font-sans-bold" [routerLink]="[backButtonLink]">
    <span class="icon material-icons">chevron_left</span>
    <span class="font-size-14 font-weight-bold">Back</span>
  </div>
  <!-- Points Bar -->
  <div class="justify-content-center flex-grow-1 flex-shrink-1 h-100">
    <fgb-points-summary></fgb-points-summary>
  </div>
</div>
