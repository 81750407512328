import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Survey, FantasyGameQuery, FantasyGame, PopulationQuery } from '@fgb/core';
import { SurveyQuery } from '@fgb/core';
import { SurveyType } from '@fgb/core';
import { Router } from '@angular/router';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { map } from 'rxjs/operators';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-games-page',
  templateUrl: './games-page.component.html',
  styleUrls: ['./games-page.component.scss'],
})
export class GamesPageComponent implements OnInit {
  polls$: Observable<Survey[]>;
  predictors$: Observable<Survey[]>;
  scorePredictors$: Observable<Survey[]>;
  quizzes$: Observable<Survey[]>;
  currentGames$: Observable<FantasyGame[]>;
  populations = Populations;
  isCorporate: boolean;

  constructor(
    private surveyQuery: SurveyQuery,
    private fantasyQuery: FantasyGameQuery,
    private router: Router,
    private populationQuery: PopulationQuery
  ) {}

  customOptions: OwlOptions = {
    items: 1,
    autoHeight: true,
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    autoplay: false,
    navSpeed: 2000,
    responsive: {
      0: { autoWidth: true },
      740: { autoWidth: false, autoHeight: false },
    },
  };

  customOptions2: OwlOptions = {
    items: 1,
    autoHeight: true,
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    autoplay: false,
    navSpeed: 2000,
    responsive: {
      0: { autoWidth: false },
      740: { autoWidth: true, autoHeight: false },
    },
  };

  ngOnInit() {
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
    this.polls$ = this.surveyQuery.selectFavoriteSurvey(SurveyType.Poll);
    this.predictors$ = this.surveyQuery.selectFavoriteSurvey(SurveyType.Predictor);
    this.quizzes$ = this.surveyQuery.selectFavoriteSurvey(SurveyType.Trivia);
    this.scorePredictors$ = this.surveyQuery.selectScorePredictors().pipe(map((survey) => survey.filter((s) => s.Favourite)));
    this.currentGames$ = this.fantasyQuery.selectCurrentGames();
  }

  goToDetail(gameId: number) {
    this.router.navigate([`games/pickaplayer/${gameId}`]);
  }

  hasActivePlayer(game: FantasyGame) {
    return game.GamePlayers.some((g) => g.IsSelected);
  }
}
