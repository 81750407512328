<div class="survey-container predictor">
  <div class="survey-header col-lg-6 p-0 overflow-hidden">
    <div class="image-container d-flex justify-content-center align-items-center" [class.no-image]="!survey.ImageURL" [ngClass]="isCorporate ? 'bg-primary-corporate' : 'bg-primary'">
      <div class="d-flex flex-column align-items-center">
          <h3 class="text-white predictor-title">{{ 'survey.predictor.title' | translate: { fgbdefault: 'Predictor' } }}</h3>
        </div>
    </div>
    <div class="survey-info d-flex flex-column align-content-between text-black">
      <div class="d-flex justify-content-between mb-4">
        <span class="icon material-icons" [ngClass]="isCorporate ? 'text-primary-corporate' : 'text-primary'">flaky</span>
        <div class="text-end text-black d-flex align-items-center" *ngIf="!survey.HasBeenAnsweredByUser">
          <span class="icon material-icons mx-2">schedule</span>
          <fgbcl-countdown
            [endDateTime]="survey.EndDate"
            [isUTC]="true"
            class="font-size-16 fw-bold"
          ></fgbcl-countdown>
        </div>
      </div>
      <div class="survey-title mb-3">
        <div>{{ survey.SurveyQuestion }}</div>
      </div>
      <div class="d-flex align-items-end mt-4 h-100">
        <button
          *ngIf="!survey.HasBeenAnsweredByUser"
          (click)="submitAnswer()"
          class="btn btn-auction col-6 fw-bold mx-auto"
          [disabled]="disableSubmitButton"
        >
          {{ 'survey.predictor.submit.button' | translate: { fgbdefault: 'Submit' } }}
        </button>
        <div *ngIf="survey.HasBeenAnsweredByUser" class="d-flex justify-content-center w-100 align-items-center" [ngClass]="isCorporate ? 'text-primary-corporate' : 'text-primary'">
          <span class="entered-text font-default">{{ 'survey.predictor.entered' | translate }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-lg-6" [ngClass]="survey.HasBeenAnsweredByUser ? 'd-flex align-items-center justify-content-center' : '' ">
    <ng-container *ngIf="survey.HasBeenAnsweredByUser; else answer">
      <fgb-survey-item-answer-completed-predictor [survey]="survey" class="w-100"></fgb-survey-item-answer-completed-predictor>
    </ng-container>
    <ng-template #answer>
      <div class="h-100 w-100 d-table ps-lg-3">
        <div class="d-table-cell align-middle">
          <fgb-survey-item-answer #itemAnswer [survey]="survey" (predictorAnswers)="hasUserAnswered()"></fgb-survey-item-answer>
        </div>
      </div>
    </ng-template>
  </div>
</div>
