import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, FantasyLeaderboard } from '@fgb/core';
import { DateTime, Interval } from 'luxon';
import { map } from 'rxjs';
import { Season } from 'src/app/shared/utilities/season';

export interface MonthlyLeaderboard {
  monthDisplayText: string;
  entries: FantasyLeaderboard[];
}

@Injectable({ providedIn: 'root' })
export class FantasyService {
  private readonly _dateformat = 'LLL y';

  constructor(private api: ApiService) {}
  getMonthlyLeaderboard(amountToDisplay: number, bySeason: boolean = false) {
    let params = new HttpParams().set('bySeason', bySeason);
    return this.api.get<FantasyLeaderboard>(`fantasy/leaderboard/monthly/${amountToDisplay}?${params.toString()}`).pipe(
      map((items) => {
        let monthlyLeaderboard: MonthlyLeaderboard[] = [];

        if (!items) {
          return monthlyLeaderboard;
        }

        for (let i = 0; i < Season.tenure; i++) {
          const monthIndex = Season.startMonth + i;
          let leaderboard = this.getLeaderboardForMonth(monthIndex, items);
          monthlyLeaderboard.push(leaderboard);
        }

        return monthlyLeaderboard;
      })
    );
  }

  getYearlyLeaderboard(amountToDisplay: number, bySeason: boolean = false) {
    let params = new HttpParams().set('bySeason', bySeason);
    return this.api.get<FantasyLeaderboard>(`fantasy/leaderboard/yearly/${amountToDisplay}?${params.toString()}`);
  }

  get monthSlideIndex() {
    let diffResult = Interval.fromDateTimes(Season.startDateTime, DateTime.now());
    return Math.floor(diffResult.length('months'));
  }

  private getLeaderboardForMonth(month: number, items: FantasyLeaderboard[]): MonthlyLeaderboard {
    const currentDate = DateTime.now();
    const date = Season.getMonthByIndex(month);

    const displayText = date.toFormat(this._dateformat);
    let filteredItems: FantasyLeaderboard[] = [];

    if (items && date.startOf('month') <= currentDate.startOf('month')) {
      filteredItems = items.filter((x: FantasyLeaderboard) => {
        return +x.FantasyMonth === date.month;
      });
    }

    return {
      monthDisplayText: displayText,
      entries: filteredItems,
    };
  }
}
