<div class="text-primary">
  <div class="p-0 mb-2 d-flex flex-wrap flex-column justify-content-around align-items-center barcode-container">
    <ng-container *ngIf="card$ | async as card">
      <div class="row px-3 w-100">
        <div class="card-id text-start col-9 ps-0">
          <h5 class="mb-1 card-name" *ngIf="member$ | async as memberDetails"
            [ngClass]="isCorporate ? 'corporate-primary-color' : 'primary-color'">
            {{ memberDetails.FirstName }}
          </h5>
          <div class="d-flex font-size-10 text-black">
            <div *notInPopulation="populations.JuniorBluebirds">{{ 'member.card.product.code' | translate }}</div>
            <div *inPopulation="populations.JuniorBluebirds">{{ 'member.card.product.code.kid' | translate }}</div>
            <div class="px-2">|</div>
            <div>ID: {{ card.MemberId }}</div>
          </div>
        </div>
        <div class="col-3 d-flex justify-content-end align-items-center pe-0">
          <ng-container *ngIf="isCorporate"><img class="logo" src="assets/images/corporate-black-logo.png"
              draggable="false" /></ng-container>
          <ng-container *ngIf="isJuniorBlueBirds">
            <img class="logo" src="assets/images/kids-logo-v2.png" draggable="false" />
          </ng-container>
          <ng-container *ngIf="!isCorporate && !isJuniorBlueBirds">
            <img class="logo" src="assets/images/logo.svg" draggable="false" />
          </ng-container>
        </div>
      </div>
      <div class="qr mx-3 mt-3 mb-2" *ngIf="barcode$ | async as barcode">
        <qrcode [qrdata]="barcode.Barcode" [width]="256" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode>
      </div>
      <ng-container *ngIf="member$ | async as member">
        <!-- Not Owner Barcode Body -->
        <ng-container>
          <div class="w-100 barcode-content-container px-1"
            [ngClass]="isCorporate ? 'bg-primary-corporate' : 'bg-primary'">
            <div class="barcode-content mx-3">
              <div class="text-white">
                <div class="d-flex justify-content-between align-items-center" *ngIf="card$ | async as card">
                  <!--Name and ID-->
                  <!-- <div class="card-id text-start">
                    <h5 class="mb-1 card-name" *ngIf="member$ | async as memberDetails">
                      {{ memberDetails.FirstName }}
                    </h5>
                    <div class="d-flex font-size-12">
                      <div *notInPopulation="populations.JuniorBluebirds">{{ 'member.card.product.code' | translate }}</div>
                      <div *inPopulation="populations.JuniorBluebirds">{{ 'member.card.product.code.kid' | translate }}</div>
                      <div class="px-2">|</div>
                      <div>ID: {{ card.MemberId }}</div>
                    </div>
                  </div> -->
                  <!--Logo-->
                  <div class="d-flex justify-content-between align-items-center"
                    *notInPopulation="populations.JuniorBluebirds">
                    <div class="d-flex align-items-end mb-1">
                      <div class="card-account-no text-start pe-3" *ngIf="pointsBalance$">
                        <div class="font-size-14 font-sans-bold">{{ pointsBalance$ | async | number:'1.0-0' : 'en-US' }}
                        </div>
                        <label class="mb-1 font-size-12 font-weight-400 mt-2">{{ 'member.card.points.balance' |
                          translate }}</label>
                      </div>
                    </div>
                  </div>
                  <button class="wallet-btn" [routerLink]="['/rewards/wallet']">
                    <span class="icon material-icons material-mobile-dropdown me-1">account_balance_wallet</span>
                    {{ 'navbar.rewards.history' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>