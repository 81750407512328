<ng-container *ngIf="status === scorePredictorStatus.ComingSoon">
  <div class="entry-window-countdown px-3 text-center bg-grey text-white">
    <div class="font-size-12" [innerHTML]="'score.predictor.coming.soon' | translate | markdownTranslate"></div>
    <fgbcl-countdown
      [endDateTime]="survey.SeparateEntryWindow ? survey.EntryStartDate : survey.StartDate"
      [isUTC]="true"
      class="countdown font-size-18 font-weight-bold text-white"
    ></fgbcl-countdown>
  </div>
</ng-container>

<!--Completion Section-->
<ng-container *ngIf="status === scorePredictorStatus.Answered">
  <div class="text-center py-2">
    <fgb-score-predictor-completed
      [survey]="survey"
      [hasSelectedCorrect]="hasSelectedCorrect"
      [pointsWon]="pointsWon"
      [canEdit]="canEdit"
      (editAnswer)="editAnswer.emit()"
    ></fgb-score-predictor-completed>
  </div>
</ng-container>

<!--Submit Button-->
<ng-container *ngIf="status === scorePredictorStatus.EntryOpen">
  <div class="d-flex justify-content-center">
    <button class="btn btn-success font-weight-bold mb-3 mt-4 mt-lg-2" (click)="submitAnswer.emit()">Submit</button>
  </div>
</ng-container>
