import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  PopulationGuard,
  StepType,
  MemberResolver,
  LottoResolver,
  AuctionResolver,
  MemberCardResolver,
  BalanceResolver,
  PurchaseResolver,
  AddressBookResolver,
  CardTokenResolver,
  FantasyGameResolver,
  SurveyResolver,
  RegistrationStepResolver,
  LottoWalletResolver,
  AuctionBidsResolver,
  FantasyHistoryResolver,
  AddressResolver,
  TransactionSuccessGuard,
  SeatingDetailResolver,
  LeaderboardResolver,
  CountryResolver,
  MemberLoyaltyStatusResolver,
  GameDataSource,
  MaintenanceGuard,
} from '@fgb/core';

// General
import { MasterPageComponent } from './pages/master/master-page/master-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { ContactUsPageComponent } from './pages/contact-us-page/contact-us-page.component';
import { TermsPageComponent } from './pages/terms-page/terms-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
// Loyalty
import { RewardsLayoutComponent } from './pages/rewards-layout/rewards-layout.component';
import { RewardsPageComponent } from './pages/rewards-page/rewards-page.component';
import { AuctionsPageComponent } from './pages/auctions-page/auctions-page.component';
import { AuctionDetailPageComponent } from './pages/auction-detail-page/auction-detail-page.component';
import { MarketplaceDetailPageComponent } from './pages/marketplace-detail-page/marketplace-detail-page.component';
import { MarketplacePurchaseSuccessPageComponent } from './pages/marketplace-page/marketplace-purchase-success-page/marketplace-purchase-success-page.component';
import { LottoDetailPageComponent } from './pages/lotto-detail-page/lotto-detail-page.component';
import { LottosSuccessPageComponent } from './pages/lottos-page/lottos-success-page/lottos-success-page.component';
import { WalletPageComponent } from './pages/wallet-page/wallet-page.component';
import { GamesLayoutComponent } from './pages/games-layout/games-layout.component';
import { GamesPageComponent } from './pages/games-page/games-page.component';
import { FantasyGamesPageComponent } from './pages/fantasy-games-page/fantasy-games-page.component';
import { FantasyPlayerSelectPageComponent } from './pages/fantasy-player-select-page/fantasy-player-select-page.component';
import { PredictorPageComponent } from './pages/predictor-page/predictor-page.component';
import { PollsPageComponent } from './pages/polls-page/polls-page.component';
import { AuctionBidDetailsPageComponent } from './pages/auction-bid-details-page/auction-bid-details-page.component';
import { LottoWalletDetailsPageComponent } from './pages/lotto-wallet-details-page/lotto-wallet-details-page.component';
import { QuizPageComponent } from './pages/quiz-page/quiz-page.component';
import { ScorePredictorPageComponent } from './pages/score-predictor-page/score-predictor-page.component';
import { WalletClaimedPageComponent } from './pages/wallet-claimed-page/wallet-claimed-page.component';
// Account
import { AccountDetailsPageComponent } from './pages/account-details-page/account-details-page.component';
import { ActivityPageComponent } from './pages/activity-page/activity-page.component';
import { CardPageComponent } from './pages/card-page/card-page.component';
// Ecash
import { EcashSuccessPageComponent } from './pages/ecash-page/ecash-success-page/ecash-success-page.component';
import { EcashFailurePageComponent } from './pages/ecash-page/ecash-failure-page/ecash-failure-page.component';
import { EcashResultPageComponent } from './pages/ecash-page/ecash-result-page/ecash-result-page.component';
// Events & Tickets
import { ContactsPageComponent } from './pages/contacts-page/contacts-page.component';

// Unauthenticated
import { AuthCallbackComponent } from './modules/user/components/auth/auth-callback/auth-callback.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { LoginAccountsPageComponent } from './pages/login-accounts-page/login-accounts-page.component';
import { SingleSignOnComponent } from './modules/user/components/single-sign-on/single-sign-on.component';
import { MaintenancePageComponent } from './pages/maintenance-page/maintenance-page.component';
// Registration
import { RegistrationLayoutPageComponent } from './modules/registration/pages/registration-layout-page/registration-layout-page.component';
import { RegistrationPaymentComponent } from './modules/registration/pages/steps/registration-payment/registration-payment.component';

// Population Pages
import { TestPopHomePageComponent } from './pages/test-pop-home-page/test-pop-home-page.component';
// Club Pages
import { EarnPageComponent } from './pages/earn-page/earn-page.component';
import { AuctionBidsPageComponent } from './pages/auction-bids-page/auction-bids-page.component';

// Partners Pages
import { LeaderboardPageComponent } from './pages/leaderboard-page/leaderboard-page.component';
import { LottosTermsPageComponent } from './pages/lottos-terms-page/lottos-terms-page.component';
import { MarketplaceTermsPageComponent } from './pages/marketplace-terms-page/marketplace-terms-page.component';
import { TmCallbackComponent } from './shared/components/manage-tickets/tm-callback/tm-callback.component';
import { TmAuthCallbackComponent } from './shared/components/providers/tm/tm-auth-callback/tm-auth-callback.component';
import { WorksheetPageComponent } from './pages/worksheet-page/worksheet-page.component';
import { FantasyLeaderboardPageComponent } from './pages/fantasy-leaderboard-page/fantasy-leaderboard-page.component';
import { V1SingleSignOnComponent } from './modules/user/components/v1-single-sign-on/v1-single-sign-on.component';

import { SportsAllianceCallbackComponent } from './shared/components/providers/sports-alliance/sports-alliance-callback/sports-alliance-callback.component';
import { GalleryPageComponent } from './pages/gallery-page/gallery-page.component';
import { BadgesPageComponent } from './pages/badges-page/badges-page.component';
import { ChallengesPageComponent } from './pages/challenges-page/challenges-page.component';

//Card Linking Pages
import { CardLinkAffiliatesPageComponent } from './pages/card-link-affiliates-page/card-link-affiliates-page.component';
import { CardLinkAllOffersPageComponent } from './pages/card-link-all-offers-page/card-link-all-offers-page.component';
import { CardLinkActivatedOffersPageComponent } from './pages/card-link-activated-offers-page/card-link-activated-offers-page.component';
import { CardLinkOfferDetailsPageComponent } from './pages/card-link-offer-details-page/card-link-offer-details-page.component';
import { CardLinkingPageComponent } from './pages/card-linking-page/card-linking-page.component';
import { CardLinkingSuccessPageComponent } from './pages/card-linking-success-page/card-linking-success-page.component';
import { provideRegistrationConfig } from '@fgb/portal-component-library/src/lib/registration';
import { SavingsPageComponent } from './pages/savings-page/savings-page.component';
import { MarketplaceWalletDetailsComponent } from '@fgb/portal-component-library/src/lib/loyalty/wallet';
import { Populations } from './shared/utilities/populations';

const population = Populations;

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [MaintenanceGuard],
    component: MasterPageComponent,
    resolve: {
      member: MemberResolver,
      memberCard: MemberCardResolver,
      balances: BalanceResolver,
    },
    children: [
      {
        path: '',
        canActivate: [PopulationGuard],
        data: {
          exclude: ['testPop'],
          popRedirect: 'test',
          gameType: GameDataSource.Club,
        },
        component: HomePageComponent,
        resolve: {
          leaderboard: LeaderboardResolver,
          surveys: SurveyResolver,
        },
      },
      {
        path: 'cards',
        component: CardPageComponent,
      },
      {
        path: 'test',
        canActivate: [PopulationGuard],
        data: {
          include: ['testPop'],
          popRedirect: '',
        },
        component: TestPopHomePageComponent,
      },
      {
        path: 'gallery',
        component: GalleryPageComponent,
      },
      {
        path: 'worksheet',
        component: WorksheetPageComponent,
      },
      {
        path: 'rewards',
        component: RewardsLayoutComponent,
        children: [
          {
            path: '',
            component: RewardsPageComponent,
          },
          {
            path: 'auctions',
            canActivate: [PopulationGuard],
            data: { exclude: population.JuniorBluebirds, popRedirect: ['/'] },
            children: [
              {
                path: '',
                canActivate: [PopulationGuard],
                data: { exclude: population.JuniorBluebirds, popRedirect: ['/'] },
                resolve: { auctions: AuctionResolver },
                component: AuctionsPageComponent,
              },
              {
                path: 'details/:id',
                canActivate: [PopulationGuard],
                data: { exclude: population.JuniorBluebirds, popRedirect: ['/'] },
                resolve: {
                  balances: BalanceResolver,
                  auctions: AuctionResolver,
                },
                component: AuctionDetailPageComponent,
              },
            ],
          },
          {
            path: 'marketplace',
            children: [
              {
                path: '',
                redirectTo: '/rewards',
                pathMatch: 'full',
              },
              {
                path: 'details/:id',
                children: [
                  {
                    path: '',
                    resolve: {
                      balances: BalanceResolver,
                      cardTokens: CardTokenResolver,
                      country: CountryResolver,
                    },
                    data: { exclude: population.JuniorBluebirds, popRedirect: ['/'] },
                    component: MarketplaceDetailPageComponent,
                  },
                  {
                    path: 'terms',
                    component: MarketplaceTermsPageComponent,
                  },
                ],
              },
              {
                path: 'purchase/success',
                canActivate: [TransactionSuccessGuard],
                component: MarketplacePurchaseSuccessPageComponent,
                resolve: {
                  balances: BalanceResolver,
                  purchase: PurchaseResolver,
                },
              },
            ],
          },
          {
            path: 'events',
            redirectTo: '/rewards',
            pathMatch: 'full',
          },
          {
            path: 'raffles',
            children: [
              {
                path: '',
                redirectTo: '/rewards',
                pathMatch: 'full',
              },
              {
                path: 'details/:id',
                children: [
                  {
                    path: '',
                    resolve: { balances: BalanceResolver, lottos: LottoResolver },
                    component: LottoDetailPageComponent,
                  },
                  {
                    path: 'terms',
                    component: LottosTermsPageComponent,
                    resolve: { lottos: LottoResolver },
                  },
                ],
              },
              {
                path: 'purchase/success',
                canActivate: [TransactionSuccessGuard],
                component: LottosSuccessPageComponent,
              },
            ],
          },
          {
            path: 'wallet',
            children: [
              {
                path: '',
                component: WalletPageComponent,
                resolve: {
                  purchases: PurchaseResolver,
                  lottoWalletItems: LottoWalletResolver,
                  auctionBids: AuctionBidsResolver,
                },
              },
              {
                path: 'details/:id',
                component: MarketplaceWalletDetailsComponent,
                resolve: { purchases: PurchaseResolver },
              },
              {
                path: 'raffledetails/:id',
                component: LottoWalletDetailsPageComponent,
                resolve: { lottoWalletItems: LottoWalletResolver },
              },
            ],
            resolve: {
              purchases: PurchaseResolver,
              lottoWalletItems: LottoWalletResolver,
              auctionBids: AuctionBidsResolver,
            },
          },
          {
            path: 'bids',
            children: [
              {
                path: '',
                component: AuctionBidsPageComponent,
                resolve: { bids: AuctionBidsResolver },
              },
              {
                path: 'details/:id',
                component: AuctionBidDetailsPageComponent,
                resolve: { bids: AuctionBidsResolver },
              },
            ],
          },
          {
            path: 'claimed',
            children: [
              {
                path: '',
                component: WalletClaimedPageComponent,
                resolve: {
                  purchases: PurchaseResolver,
                  lottoWalletItems: LottoWalletResolver,
                  auctionBids: AuctionBidsResolver,
                },
              },
            ],
          },
        ],
      },
      {
        path: 'account',
        component: AccountDetailsPageComponent,
        resolve: {
          member: MemberResolver,
          balances: BalanceResolver,
          seating: SeatingDetailResolver,
          memberLoyaltyStatus: MemberLoyaltyStatusResolver,
        },
      },
      {
        path: 'activity',
        canActivate: [PopulationGuard],
        data: { exclude: population.JuniorBluebirds, popRedirect: ['/'] },
        component: ActivityPageComponent,
      },
      {
        path: 'linkcard',
        children: [{ path: '', component: CardLinkingPageComponent }],
      },
      {
        path: 'linkcardSuccess',
        children: [{ path: '', component: CardLinkingSuccessPageComponent }],
      },
      {
        path: 'affiliates',
        component: CardLinkAffiliatesPageComponent,
        children: [
          { path: 'alloffers', component: CardLinkAllOffersPageComponent },
          { path: 'myoffers', component: CardLinkActivatedOffersPageComponent },
          { path: '', pathMatch: 'full', redirectTo: 'alloffers' },
        ],
      },
      //card linking offer detail
      {
        path: 'offerdetail/:id',
        component: CardLinkOfferDetailsPageComponent,
      },
      {
        path: 'cardtoken',
        children: [
          { path: 'success', component: EcashSuccessPageComponent },
          { path: 'failure', component: EcashFailurePageComponent },
          { path: 'result', component: EcashResultPageComponent },
        ],
      },
      { path: 'faq', component: FaqPageComponent },
      { path: 'terms', component: TermsPageComponent },
      {
        path: 'contact',
        component: ContactUsPageComponent,
      },
      { path: 'badges', component: BadgesPageComponent },
      { path: 'challenges', component: ChallengesPageComponent },
      { path: 'savings', component: SavingsPageComponent },
      {
        path: 'contacts',
        component: ContactsPageComponent,
        resolve: { addressBookResolver: AddressBookResolver },
      },
      {
        path: 'games',
        resolve: {
          surveys: SurveyResolver,
          games: FantasyGameResolver,
        },
        component: GamesLayoutComponent,
        children: [
          { path: '', component: GamesPageComponent },
          {
            path: 'pickaplayer',
            component: FantasyGamesPageComponent,
            resolve: { games: FantasyGameResolver, history: FantasyHistoryResolver },
          },
          {
            path: 'pickaplayer/fantasyleaderboard',
            component: FantasyLeaderboardPageComponent,
          },
          {
            path: 'pickaplayer/:id',
            component: FantasyPlayerSelectPageComponent,
            resolve: { games: FantasyGameResolver },
          },
          { path: 'predictor', component: PredictorPageComponent },
          { path: 'scorepredictor', component: ScorePredictorPageComponent },
          { path: 'polls', component: PollsPageComponent },
          { path: 'quiz', component: QuizPageComponent },
        ],
      },
      // Club Pages
      {
        path: 'earn',
        canActivate: [PopulationGuard],
        data: { exclude: population.JuniorBluebirds, popRedirect: ['/'] },
        component: EarnPageComponent,
      },
      {
        path: 'leaderboard',
        component: LeaderboardPageComponent,
        resolve: { leaderboard: LeaderboardResolver },
      },
      { path: '404page', component: NotFoundPageComponent },
      { path: 'signin-ticketmaster', component: TmCallbackComponent },
    ],
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginPageComponent, canActivate: [MaintenanceGuard] },
  { path: 'login/accounts', component: LoginAccountsPageComponent, canActivate: [MaintenanceGuard] },
  {
    path: 'register',
    resolve: { registrationStep: RegistrationStepResolver, country: CountryResolver },
    children: [
      {
        path: 'payment',
        children: [
          {
            path: '',
            component: RegistrationPaymentComponent,
            data: { registrationStep: StepType.Payment },
            resolve: { member: MemberResolver, address: AddressResolver },
          },
        ],
      },
    ],
  },
  {
    path: 'register',
    component: RegistrationLayoutPageComponent,
    loadChildren: () => import('@fgb/portal-component-library/src/lib/registration').then((m) => m.RegistrationModule),
    providers: [provideRegistrationConfig()],
  },
  { path: 'callback', component: AuthCallbackComponent },
  { path: 'sso', component: SingleSignOnComponent },
  { path: 'login/singleSignOn/:providerId', component: SingleSignOnComponent },
  { path: 'login/singleSignOn/:providerId/:clubId', component: SingleSignOnComponent },
  { path: 'login/ss/login.aspx', component: V1SingleSignOnComponent },
  { path: 'maintenance', component: MaintenancePageComponent },
  { path: 'tm-auth-callback', component: TmAuthCallbackComponent },
  { path: 'sa-callback', component: SportsAllianceCallbackComponent },
  { path: '**', redirectTo: '404page' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
