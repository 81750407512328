import { Component, Input, OnInit } from '@angular/core';
import {
  MixedLoyaltyItemType,
  MixedLoyaltyQuery,
  ProcessProviderId,
  RewardsWalletService,
  RewardsWalletItem,
  RewardsWalletQuery,
  PopulationQuery
} from '@fgb/core';
// import { BarcodeOptions } from '@fgb/portal-component-library/src/lib/user/barcode';
import { Observable, firstValueFrom } from 'rxjs';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-wallet-page',
  templateUrl: './wallet-page.component.html',
  styleUrls: ['./wallet-page.component.scss'],
})
export class WalletPageComponent implements OnInit {
  @Input() marketplaceFilterTypes: ProcessProviderId[] = [0, 1, 2, 3, 4, 5];
  @Input() itemTypes: MixedLoyaltyItemType[] = [MixedLoyaltyItemType.All];
  walletItemsUnclaimed$: Observable<RewardsWalletItem[]>;
  populations = Populations;
  isCorporate: boolean;
  // barcodeOptions: BarcodeOptions = { showSeating: true, showLogo: false, showDiscount: false };
  constructor(
    private populationQuery: PopulationQuery,
    private mixedLoyaltyQuery: MixedLoyaltyQuery,
    private rewardWalletItemsService: RewardsWalletService,
    private rewardWalletQuery: RewardsWalletQuery
  ) {}

  async ngOnInit() {
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
    firstValueFrom(this.rewardWalletItemsService.fetchWalletItems());
    this.walletItemsUnclaimed$ = this.rewardWalletQuery.selectRewardWalletItemsByStatus(false);

    await this.mixedLoyaltyQuery.walletFetch();
  }
}
