import { Component, OnDestroy, OnInit, DestroyRef, inject } from '@angular/core';
import { CartQuery, LoggingService, MemberQuery, Population, PopulationQuery, SignalRHub } from '@fgb/core';
import { Observable, Subject, Subscription, combineLatest } from 'rxjs';
import { Populations } from 'src/app/shared/utilities/populations';
import { NavigationService, NavigationSettings } from '@fgb/portal-component-library/src/lib/shared/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fgb-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.scss'],
})
export class MasterPageComponent implements OnInit, OnDestroy {
  private _destroyRef = inject(DestroyRef);
  observable$: Subscription;
  totalItems$: Observable<number>;
  screenType = ScreenType;
  private _destroyed$ = new Subject<void>();
  populations = Populations;
  population: Population | undefined;
  populationID: string | undefined;
  navigationSettings: NavigationSettings;

  constructor(
    private cartQuery: CartQuery,
    private signalRHub: SignalRHub,
    private logService: LoggingService,
    private populationQuery: PopulationQuery,
    private memberQuery: MemberQuery,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    this.signalRHub.connectionEstablished$.pipe(takeUntil(this._destroyed$)).subscribe((connection) => {
      this.logService.debug('SignalR connection', connection);
    });
    this.population = this.populationQuery.getCurrentPopulation();
    this.totalItems$ = this.cartQuery.updateTotalItems();

    this.observable$ = combineLatest([this.signalRHub.connectionEstablished$, this.memberQuery.userContext$]).subscribe(
      ([connection, memberDetail]) => {
        this.logService.debug('SignalR connection', connection);
        this.populationID = memberDetail?.populationId;
        document.body.classList.add(`PopId-${this.populationID}`);
      }
    );
    this.navigationService.onNavigationChanged.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((navigationSettings) => {
      this.navigationSettings = navigationSettings;
    });
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
