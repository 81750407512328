import { Component, OnInit, Input } from '@angular/core';
import { PopulationQuery, Survey, SurveyType } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-survey-item-quiz',
  templateUrl: './survey-item-quiz.component.html',
  styleUrls: ['./survey-item-quiz.component.scss', '../../survey.scss'],
})
export class SurveyItemQuizComponent implements OnInit {
  @Input() survey: Survey;
  @Input() hideTitle: boolean = false;
  surveyType = SurveyType;
  populations = Populations;
  isCorporate: boolean;
  comingSoon: boolean;

  constructor(private populationQuery: PopulationQuery) {}

  ngOnInit() {
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
    this.comingSoon = !!this.survey.ComingSoon && this.survey.StartDate > this.survey.RequestTime;
  }
}
