<fgb-banner [title]="'games.page.predictor.title' | translate"></fgb-banner>

<fgb-game-nav></fgb-game-nav>

<div class="container">
  <ng-container *ngIf="predictors$ | async as predictors">
    <div *ngIf="!predictors.length">
      <div class="container">
        <div class="no-items mx-0 w-100 mt-4 no-results-placeholder-div">
          <div class="no-results-placeholder">{{ 'predictor.placeholder.description' | translate }}</div>
        </div>
      </div>
    </div>
    <div *ngFor="let predictor of predictors">
      <fgb-survey-item [survey]="predictor"></fgb-survey-item>
    </div>
  </ng-container>
</div>
