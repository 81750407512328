import { Component, OnInit, Input } from '@angular/core';
import { LeaderboardQuery, LeaderboardEntry, LeaderboardService } from '@fgb/core';
import { firstValueFrom } from 'rxjs';
@Component({
  selector: 'fgb-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss'],
})
export class LeaderboardComponent implements OnInit {
  @Input() toShow: number;
  @Input() showViewFull: boolean;
  data: LeaderboardEntry[] = [];
  today: Date = new Date();

  constructor(private leaderboardQuery: LeaderboardQuery, private leaderboardService: LeaderboardService) {}

  ngOnInit() {
    firstValueFrom(this.leaderboardService
      .fetchLeaderboard())
      .then(() => {
        this.data = this.leaderboardQuery.formatArrayWithUser(this.toShow, this.leaderboardQuery.getValue().leaderboard);
      });
  }
}
