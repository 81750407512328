<ul class="nav nav-secondary account-details-nav" [ngClass]="{'corporate-nav' : isCorporate}" *notInPopulation="populations.JuniorBluebirds">
  <a class="nav-item" [routerLink]="['/account']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <div class="icon material-icons">person</div>
    <label class="font-sans-bold">{{ 'account.nav.profile' | translate }}</label>
  </a>
  <a class="nav-item" [routerLink]="['/activity']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
    <div class="icon material-icons">history</div>
    <label class="font-sans-bold">{{ 'account.nav.activity' | translate }}</label>
  </a>
</ul>
