<div class="card border-0 shadow align-items-center" *ngIf="game; else noGame">
  <div class="row w-100">
    <div class="col-lg-3 col-2 d-flex align-items-center m-lg-0 me-3">
      <div class="team-logo">
        <img alt="" src="{{ getTeamLogoUrl(game.AwayTeamId) }}" draggable="false" />
      </div>
    </div>
    <div class="col-9">
      <div class="game-date-box text-start">
        <div class="font-weight-bold team-name mt-1">{{ game.TeamNames.AwayTeamName }}</div>
        <div class="font-size-14 mb-1 text-primary game-date">
          {{ game.Date | date: 'd MMM, HH:mm' }}
        </div>
      </div>
      <div class="buy-tickets p-1 rounded bg-primary">
        <a href="https://tickets.cardiffcityfc.co.uk/?utm_source=rewards&utm_medium=referral&utm_campaign=rewards_general" target="_blank">
          <div class="d-flex text-white align-items-center justify-content-center">
            <span class="icon material-icons">confirmation_number</span>
            <p class="font-size-14 mb-0 ms-1">{{ 'game.card.button.text' | translate }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
<ng-template #noGame>
  <div class="align-content-center">
    <h3>No game!</h3>
  </div>
</ng-template>
