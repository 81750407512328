<div class="fantasy-panel rounded overflow-hidden">
  <div class="row mx-0">
    <div class="col-12 px-0" [ngClass]="{'col-lg-7': horizontal, 'bg-primary-corporate': isCorporate, 'bg-primary': !isCorporate}">
      <div class="game-summary text-white h-100">
        <div class="d-none d-lg-flex flex-column h-100 justify-content-between">
          <div class="text-center line-height-18">
            <p class="mb-2 font-size-12">{{ 'pick.player.time.left' | translate }}</p>
            <fgb-countdown class="font-size-14 font-sans-bold" [endDateTime]="game.EndDate"></fgb-countdown>
          </div>
          <div class="row team-vs-team d-flex justify-content-center align-items-center mx-0">
            <div class="team home col-4">
              <img
                *ngIf="game.HomeTeamLogo"
                class="d-flex ms-auto me-auto mt-3 mt-lg-0"
                src="{{ game.HomeTeamLogo | contentImage : '6' }}"
              />
            </div>
            <div class="versus col-4 text-center">
              <div class="font-size-30 font-default text-white">{{ 'game.versus' | translate}}</div>
            </div>
            <div class="team away col-4">
              <img
                *ngIf="game.AwayTeamLogo"
                class="d-flex ms-auto me-auto mt-3 mt-lg-0"
                src="{{ game.AwayTeamLogo | contentImage : '6' }}"
              />
            </div>
          </div>
          <div class="line-height-18 font-size-12 text-center">
            <div class="date">{{ gameDate }} | {{ gameTime }}</div>
          </div>
        </div>
        <div class="d-block d-lg-none">
          <div class="row">
            <div class="col-4 line-height-18">
              <p class="mb-lg-0 mb-3 font-size-12 mt-lg-0 mt-1">{{ 'pick.player.time.left' | translate }}</p>
              <fgb-countdown class="font-size-14 font-sans-bold" [endDateTime]="game.EndDate"></fgb-countdown>
            </div>
            <div class="col-8">
              <div class="row d-flex justify-content-center align-items-center mx-0">
                <div class="team home col-4">
                  <img *ngIf="game.HomeTeamLogo" class="d-flex mx-auto" src="{{ game.HomeTeamLogo | contentImage : '6' }}" />
                </div>
                <div class="versus col-4 text-center">
                  <div class="font-size-30 font-default text-white">{{ 'game.versus' | translate}}</div>
                </div>
                <div class="team away col-4">
                  <img *ngIf="game.AwayTeamLogo" class="d-flex mx-auto" src="{{ game.AwayTeamLogo | contentImage : '6' }}" />
                </div>
              </div>
              <div class="line-height-18 font-size-12 text-center">
                <div class="dates mt-1">{{ gameDate }} | {{ gameTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 py-2 px-3" [ngClass]="{ 'col-lg-5': horizontal }">
      <div class="d-none d-lg-block">
        <div class="player-select text-center">
          <div class="player-image mx-auto rounded-circle border overflow-hidden">
            <img
              *ngIf="selectedPlayer && selectedPlayer.Image"
              src="{{ selectedPlayer.Image | contentImage : '6' }}"
              class="w-100 h-100 m-auto"
            />
            <img *ngIf="!selectedPlayer" src="assets/images/no-player-image.png" class="w-100 h-100 m-auto" />
          </div>

          <fgb-fantasy-player-select-confirm
            [isGamePending]="isGamePending"
            [game]="game"
            [buttonState]="buttonState"
            [selectedPlayer]="selectedPlayer"
            (button)="buttonClick()"
          >
          </fgb-fantasy-player-select-confirm>
        </div>
      </div>
      <div class="d-block d-lg-none">
        <div class="player-select text-center">
          <div class="row">
            <div class="col-12">
              <fgb-fantasy-player-select-confirm
                [isGamePending]="isGamePending"
                [game]="game"
                [buttonState]="buttonState"
                [selectedPlayer]="selectedPlayer"
                (button)="buttonClick()"
              >
              </fgb-fantasy-player-select-confirm>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
