import { Component, OnInit } from '@angular/core';
import { TermsAndConditionsType, PopulationQuery } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-terms-page',
  templateUrl: './terms-page.component.html',
  styleUrls: ['./terms-page.component.scss'],
})
export class TermsPageComponent implements OnInit {
  populations = Populations;
  isCorporate: boolean;
  termsAndConditionsType = TermsAndConditionsType.General;
  constructor(
    private populationQuery: PopulationQuery
  ) {}

  ngOnInit() {
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
  }
}
