<div class="survey-answer">
  <div class="text-center font-size-14 font-sans-bold pt-1 d-none d-lg-block" *ngIf="pointsWon > 0 && !isJuniorBluebirds" [ngClass]="isCorporate ? 'primary-corporate-color' : 'primary-color'">
    +{{ pointsWon }} {{ 'polls.points.won' | translate }}
  </div>
  <div class="survey-answer-container py-3 mb-lg-4">
    <div class="col-12 col-lg-6 p-0 my-2 my-lg-3" *ngFor="let answer of survey.SurveyAnswers">
      <div class="survey-answer-item w-90">
        <fgb-survey-item-answer-completed-poll-item
          [answer]="answer"
          [entryCount]="survey.SurveyEntryCount"
          [displayType]="survey.AnswerDisplayType"
        ></fgb-survey-item-answer-completed-poll-item>
      </div>
    </div>
  </div>
</div>
