import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  config,
  CompanyQuery,
  Company,
  CompanyOfferModel,
  CompanyOfferQuery,
  MemberDetails,
  MemberQuery,
  PopulationQuery,
  WorksheetQuery,
  WorksheetService,
  MixedLoyaltyItem,
  MixedLoyaltyQuery,
  MixedLoyaltyItemType,
  Scorecard,
  ScorecardQuery,
  ScorecardService,
  AnnouncementService,
  SurveyType,
  SurveyQuery,
  Survey,
} from '@fgb/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { firstValueFrom, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';
import { Populations } from 'src/app/shared/utilities/populations';

declare var twttr: any;

@Component({
  selector: 'fgb-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, AfterViewInit {
  config = config;
  @Input() take?: number;
  memberDetails$: Observable<MemberDetails | undefined>;
  companies$: Observable<Company[]>;
  featuredOffers$: Observable<CompanyOfferModel[]>;
  featuredPartners$: Observable<Company[]>;
  scorecard$: Observable<Scorecard[]>;
  hasWorksheets$: Observable<boolean>;
  polls$: Observable<Survey[]>;
  populations = Populations;
  isCorporate: boolean;
  isJuniorBluebird: boolean;
  @Input() showAllView: boolean;
  mixedLoyaltyItems$: Observable<MixedLoyaltyItem[]>;
  customOptions: OwlOptions = {
    autoHeight: true,
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    autoplay: OwlCarouselSettings.Autoplay,
    autoplayTimeout: OwlCarouselSettings.AutoplayTimeout,
    autoplaySpeed: OwlCarouselSettings.AutoplaySpeed,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 1 },
      740: { items: 1 },
      900: { items: 1 },
    },
  };

  customOptions2: OwlOptions = {
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    pullDrag: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: { items: 2 },
      540: { items: 4 },
      740: { items: 6 },
    },
  };

  constructor(
    private companyQuery: CompanyQuery,
    private companyOfferQuery: CompanyOfferQuery,
    public memberQuery: MemberQuery,
    private router: Router,
    private populationQuery: PopulationQuery,
    private worksheetQuery: WorksheetQuery,
    private worksheetService: WorksheetService,
    private mixedLoyaltyService: MixedLoyaltyQuery,
    private scorecardQuery: ScorecardQuery,
    private scorecardService: ScorecardService,
    private announcementService: AnnouncementService,
    private surveyQuery: SurveyQuery
  ) {}

  ngAfterViewInit(): void {
    twttr.widgets.load();
  }

  ngOnInit() {
    this.featuredOffers$ = this.companyOfferQuery.selectFeaturedOffers();
    this.featuredPartners$ = this.companyQuery.selectFavouriteCompanies();
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.companies$ = this.companyQuery.selectFavouriteCompanies().pipe(
      map((companies) => {
        return companies;
      })
    );
    this.scorecard$ = this.scorecardQuery.selectAll();
    firstValueFrom(this.scorecardService.fetchScorecards());
    firstValueFrom(this.announcementService.fetchAnnouncements());
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
    this.isJuniorBluebird = this.populationQuery.userInPopulation([this.populations.JuniorBluebirds]);

    this.polls$ = this.surveyQuery.selectFavoriteSurvey(SurveyType.Poll);
    const currentUrl = this.router.url.replace('/', '').split('?')[0];

    if (!this.worksheetQuery.hasRoute(currentUrl)) {
      firstValueFrom(this.worksheetService.fetchWorksheetByRoute(currentUrl));
    }

    this.hasWorksheets$ = this.worksheetQuery.getWorksheetByRoute(currentUrl).pipe(
      tap((x) => console.log(x)),
      map((w) => w.some((i) => i.WorksheetId > 0))
    );

    this.mixedLoyaltyItems$ = this.mixedLoyaltyService
      .selectFavouriteMixedLoyaltyItems(true, [MixedLoyaltyItemType.Lotto, MixedLoyaltyItemType.Marketplace])
      .pipe(map((items) => items.slice(0, this.take)));
  }

  navigateToOffer(offerId: number) {
    this.router.navigateByUrl(`offers/details/${offerId}`);
  }

  navigateToFirstOfferFromAffiliate(affiliate: Company) {
    this.companyOfferQuery
      .selectCompanyOffers(affiliate.CompanyId)
      .pipe(
        take(1),
        tap((offer) => {
          if (offer[0]) {
            this.router.navigateByUrl(`offers/details/${offer[0].OfferId}`);
          }
        })
      )
      .subscribe();
  }
  /** Checks whether a given partner has any affiliated offers */
  hasOffers(affiliate: Company) {
    return !!this.companyOfferQuery.getCount((entity) => entity.CompanyId == affiliate.CompanyId);
  }
}
