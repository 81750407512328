import { Component, HostListener, OnInit } from '@angular/core';
import { CardLinkingQuery, CardLinkingService, CardLinkingSettings, LinkedCard, MemberQuery } from '@fgb/core';
import { firstValueFrom, Observable } from 'rxjs';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
@Component({
  selector: 'fgb-card-linking-page',
  templateUrl: './card-linking-page.component.html',
  styleUrls: ['./card-linking-page.component.scss'],
})
export class CardLinkingPageComponent implements OnInit {
  screenType = ScreenType;
  currentTab: any = 'payment';
  linkedCardList$: Observable<LinkedCard[]>;
  memberId: string;
  dateFormat: string = 'MMMM D, YYYY hh:mm a';
  cardLinkingSettings: CardLinkingSettings = {
    allowMultipleCardLinking: false,
  };

  constructor(
    private cardLinkingServices: CardLinkingService,
    private cardLinkingQuary: CardLinkingQuery,
    private memberQuery: MemberQuery
  ) {}

  ngOnInit(): void {
    this.memberId = this.memberId || this.memberQuery.getUserContext().memberId;
    firstValueFrom(this.cardLinkingServices.fetchFidelLinkedCard());
    this.getLinkedCards();
  }

  /* to reset the fist tab again from mobile view */
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.currentTab = window.innerWidth > 991.98 ? 'payment' : this.currentTab;
  }

  /* Fetch the list of linked cards from the api*/
  getLinkedCards() {
    this.linkedCardList$ = this.cardLinkingQuary.selectAll();
  }
}
