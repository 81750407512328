<div class="score col-12 p-0">
  <div
    class="score-predictor-countdown"
    *ngIf="status === scorePredictorStatus.EntryOpen || status === scorePredictorStatus.Answered"
  >
    <ng-container *ngIf="canEditAnswer">
      <span class="icon icon-clock pe-1 text-danger"></span>
      <fgbcl-countdown
        [endDateTime]="survey.SeparateEntryWindow ? survey.EntryEndDate : survey.EndDate"
        [isUTC]="true"
        class="countdown font-size-14 font-weight-bold text-danger"
      ></fgbcl-countdown>
    </ng-container>
  </div>
  <div
    class="d-flex flex-row justify-content-center mt-3 mt-lg-0"
    [ngClass]="{ 'align-with-submit-body': status === scorePredictorStatus.ComingSoon }"
  >
    <div class="px-3 px-lg-5" *ngIf="status === scorePredictorStatus.ComingSoon">
      <ng-container *ngTemplateOutlet="homeImage"></ng-container>
    </div>
    <div class="align-self-center" [ngClass]="status === scorePredictorStatus.ComingSoon ? 'col-4 col-lg-3' : 'd-lg-none col-12'">
      <ng-container *ngTemplateOutlet="dateTime"></ng-container>
    </div>
    <div class="px-3 px-lg-5" *ngIf="status === scorePredictorStatus.ComingSoon">
      <ng-container *ngTemplateOutlet="awayImage"></ng-container>
    </div>
  </div>
  <form
    *ngIf="status !== scorePredictorStatus.ComingSoon"
    class="row no-gutters justify-content-center align-with-submit-body"
    [ngClass]="
      status == scorePredictorStatus.EntryOpen
        ? 'py-3'
        : status === scorePredictorStatus.Answered && canEditAnswer
        ? 'align-with-submit-body'
        : 'default'
    "
    [formGroup]="scoreForm"
  >
    <div class="d-flex align-items-center justify-content-center col-5">
      <ng-container *ngTemplateOutlet="homeImage"></ng-container>
      <!--Home Count-->
      <div class="d-inline-block px-2 pt-2 w-60" *ngIf="status !== scorePredictorStatus.ComingSoon">
        <fieldset [disabled]="status != scorePredictorStatus.EntryOpen">
          <fgb-number-increment-input
            formControlName="home"
            [ngClass]="[
              survey.ProcessStatus === 2 && hasSelectedCorrect
                ? 'text-success'
                : survey.ProcessStatus === 2 && !hasSelectedCorrect
                ? 'text-danger'
                : ''
            ]"
          >
          </fgb-number-increment-input>
        </fieldset>
      </div>
    </div>
    <div class="d-none d-lg-block col-2 align-self-center">
      <ng-container *ngTemplateOutlet="dateTime"></ng-container>
    </div>
    <div class="d-flex align-items-center justify-content-center col-5">
      <!--Away Count-->
      <div class="w-60 d-inline-block px-2 pt-2" *ngIf="status !== scorePredictorStatus.ComingSoon">
        <fieldset [disabled]="status != scorePredictorStatus.EntryOpen">
          <fgb-number-increment-input
            formControlName="away"
            [ngClass]="[
              survey.ProcessStatus === 2 && hasSelectedCorrect
                ? 'text-success'
                : survey.ProcessStatus === 2 && !hasSelectedCorrect
                ? 'text-danger'
                : ''
            ]"
          >
          </fgb-number-increment-input>
        </fieldset>
      </div>
      <ng-container *ngTemplateOutlet="awayImage"></ng-container>
    </div>
  </form>
</div>

<!--Entry Window Date-->
<ng-template #dateTime>
  <div
    class="text-center font-size-12"
    [ngClass]="
      status === scorePredictorStatus.Answered && canEditAnswer
        ? 'align-with-submit-time'
        : status === scorePredictorStatus.Answered || status === scorePredictorStatus.ComingSoon
        ? 'mt-2 mt-lg-3'
        : 'mt-2'
    "
  >
    {{
      survey.SeparateEntryWindow
        ? (survey.EntryEndDate | UtcToLocaltimePipe : 'DD MMM, HH:mm')
        : (survey.EndDate | UtcToLocaltimePipe : 'DD MMM, HH:mm')
    }}
  </div>
</ng-template>

<!--Away Image-->
<ng-template #awayImage>
  <div
    class="d-inline-block d-flex w-40"
    [ngClass]="{ 'justify-content-start px-2': status !== scorePredictorStatus.ComingSoon }"
  >
    <ng-container *ngIf="survey.SurveyAnswers.length && survey.SurveyAnswers[homeTeam]">
      <img
        class="away-team-image"
        alt=""
        src="{{ survey.SurveyAnswers[awayTeam].ImageURL | contentImage : '7' }}"
        draggable="false"
      />
    </ng-container>
  </div>
</ng-template>

<!--Home Image-->
<ng-template #homeImage>
  <div class="d-inline-block d-flex w-40" [ngClass]="{ 'justify-content-end px-2': status !== scorePredictorStatus.ComingSoon }">
    <ng-container *ngIf="survey.SurveyAnswers.length && survey.SurveyAnswers[homeTeam]">
      <img
        class="home-team-image"
        alt=""
        src="{{ survey.SurveyAnswers[homeTeam].ImageURL | contentImage : '7' }}"
        draggable="false"
      />
    </ng-container>
  </div>
</ng-template>
