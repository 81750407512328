<div
  ngbDropdown
  class="notification-button pt-lg-1 mx-lg-2"
  [class.open]="isOpen"
  (openChange)="toggleNotificationCenter($event)"
  #notificationDropdown="ngbDropdown"
>
  <button
    class="dropdown-toggle notification-btn btn px-lg-4 border-right" [class.border-left]="!isJuniorBluebirds"
    id="notificationDropdown"
    attr.aria-label="{{ 'nav.notification.screenreader' | translate }}"
    tabindex="-1"
    ngbDropdownToggle
  >
    <span class="icon notification-bell text-white material-icons material-mobile-nav">notifications</span>
    <ng-container *ngIf="unseenNotificationCount$ | async">
      <span class="notification-badge">
        <div class="count-notification">{{ unseenNotificationCount$ | async }}</div>
      </span>
    </ng-container>
  </button>

  <div ngbDropdownMenu aria-labelledby="notificationDropdown" class="notification-dropdown shadow dropdown-menu border-0">
    <div class="notification-header d-flex pl-3 border-bottom">
      <div class="text-black font-sans-bold font-size-14 text-capitalize">
        {{ 'navbar.notifications.title' | translate : { fgbdefault: 'Notifications' } }}
      </div>
      <ng-container>
        <span class="icon material-icons close-btn" (click)="notificationDropdown.close()">close</span>
      </ng-container>
    </div>
    <fgbcl-notification-hub (goToNotification)="closeDropDown(notificationDropdown)"></fgbcl-notification-hub>
  </div>
  <div class="notification-mobile-backdrop"></div>
</div>
