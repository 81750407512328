import { Component, OnInit, Input } from '@angular/core';
import { Survey, PopulationQuery } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-survey-item-answer-completed-poll',
  templateUrl: './survey-completed-poll.component.html',
  styleUrls: ['./survey-completed-poll.component.scss', '../../../survey.scss'],
})
export class SurveyCompletedPollComponent implements OnInit {
  @Input() survey: Survey;
  @Input() pointsWon: number;
  populations = Populations;
  isJuniorBluebirds: boolean;
  isCorporate: boolean
  constructor(private populationQuery: PopulationQuery) {}

  ngOnInit() {
    this.isJuniorBluebirds = this.populationQuery.userInPopulation([Populations.JuniorBluebirds]);
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
  }
}
