<div class="container d-flex align-items-center">
  <div class="card shadow border-bottom-0 mx-auto">
    <div class="card-body text-center py-4">
      <div class="money-icon icon svg svg-danger mb-3 mx-auto" [inlineSVG]="'assets/images/icons/money-bag.svg'"></div>
      <h3 class="card-heading text-danger">Load Failed</h3>
      <div class="my-4 sub-heading-modal">
        <div class="fw-bold">Unfortunately, the transaction was not processed.</div>
        <div class="mt-3 text-info">Please try again or contact our support team.</div>
      </div>
      <div class="btn-container mt-3 d-block">
        <button [routerLink]="['/ecash']" type="button" class="btn btn-danger mx-2 mt-4 btn-text fw-bold">
          Back to eCash
        </button>
      </div>
    </div>
  </div>
</div>
