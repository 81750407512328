import { Component } from '@angular/core';
import { Populations } from 'src/app/shared/utilities/populations';
import { PopulationQuery } from '@fgb/core';

@Component({
  selector: 'fgb-challenges-page',
  templateUrl: './challenges-page.component.html',
  styleUrl: './challenges-page.component.scss',
})
export class ChallengesPageComponent {
  isCorporate: boolean;
  populations = Populations;

  constructor(private populationQuery: PopulationQuery) {}

  ngOnInit(): void {
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
  }
}
