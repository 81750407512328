import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, formatNumber } from '@angular/common';

@Pipe({ name: 'FortressCurrencyPipe' })
export class FortressCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {}

  transform(value: any, currencyCode?: string, display?: string, digitsInfo?: string, locale?: string) {
    if (currencyCode && currencyCode === 'PTS') {
      value = formatNumber(value, 'en-GB', '1.0-0');
      return value + ' Pts';
    } else {
      return this.currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);
    }
  }
}
