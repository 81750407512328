<div class="survey-answer survey-type-5 answer-type-{{ displayType }}">
  <div class="survey-answer-item mb-2"
        *ngFor="let answer of selectedAnswers" [ngClass]="{'w-100': displayType === answerDisplayType.Text}">
        <ng-container [ngSwitch]="displayType">
          <!-- Text -->
          <ng-container *ngSwitchCase="answerDisplayType.Text">
            <div class="d-flex flex-row">
              <div class="answer-text-landscape text-center text-white bg-primary" *notInPopulation="populations.Hospitality">
                <span>{{ answer.Title }}</span>
              </div>
              <div class="answer-text-landscape text-center text-white bg-primary-corporate" *inPopulation="populations.Hospitality">
                <span>{{ answer.Title }}</span>
              </div>
            </div>
          </ng-container>
          <!-- Image and Text -->
          <ng-container *ngSwitchCase="answerDisplayType.ImageAndText">
            <div class="d-flex flex-column">
              <img class="image-container" src="{{ answer.ImageURL | contentImage: '7' }}" alt="{{ answer.ImageURL }}" />
              <div class="text-center text-white bg-primary py-1 px-1 rounded-bottom" *notInPopulation="populations.Hospitality">
                <span class="font-size-14">{{ answer.Title }}</span>
              </div>
              <div class="text-center text-white bg-primary-corporate py-1 px-1 rounded-bottom" *inPopulation="populations.Hospitality">
                <span class="font-size-14">{{ answer.Title }}</span>
              </div>
            </div>
          </ng-container>
          <!-- Image Only -->
          <ng-container *ngSwitchCase="answerDisplayType.Image">
            <div class="d-flex flex-column">
              <img class="image-container" src="{{ answer.ImageURL | contentImage: '7' }}" alt="{{ answer.ImageURL }}" />
            </div>
          </ng-container>
        </ng-container>
      </div>

  </div>
  <div class="d-flex flex-column align-items-center">
    <div class="text-center fw-bold font-size-12 text-auction my-1">{{ 'survey.predictor.submitting' | translate: { fgbdefault: 'Thank you for submitting your selection' } }}</div>
    <div class="text-center text-black mb-lg-5 mb-3 instructions w-100">
      {{ 'survey.predictor.instructions' | translate : { fgbdefault: 'Please come back after the conclusion of this predictor to view your results.' } }}
    </div>
  </div>
