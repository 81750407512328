<div
  class="details"
  [ngClass]="sameLine ? 'text-center d-flex justify-content-around align-items-baseline sticky-cta mt-0 mt-lg-4' : 'ms-lg-0'"
  [ngSwitch]="buttonState"
  *ngIf="!isGamePending"
>
  <ng-container *ngSwitchCase="'player-link'">
    <div class="d-flex justify-content-between align-items-center d-lg-block">
      <img class="player-image d-block d-lg-none" src="{{ selectedPlayer.Image | contentImage : '3' }}" />
      <div>
        <div class="question-text pe-1">
          <span class="font-size-14 font-sans-bold">{{ selectedPlayer.PlayerName }}</span>
        </div>
        <button
          class="btn btn-success btn-select shadow font-sans-bold mb-2"
          [routerLink]="['/games/pickaplayer/' + game.GameId]"
          routerLinkActive="router-link-active"
        >
          {{ 'pick.player.change.player.button' | translate }}
        </button>
    </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'no-player-link'">
    <div class="question-text font-sans-bold font-size-14">{{ 'pick.player.no.player.selected' | translate }}</div>
    <button
      class="btn btn-success btn-select font-sans-bold mb-2"
      [routerLink]="['/games/pickaplayer/' + game.GameId]"
      routerLinkActive="router-link-active"
    >
      {{ 'pick.player.select.player.button' | translate }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'make-selection'">
    <div class="font-sans-bold text-black font-size-14 pe-1 mb-lg-2 mb-4 mt-lg-0 mt-3">
      {{ 'pick.player.no.player.selected' | translate }}
    </div>
    <button class="btn btn-success btn-select btn-submit font-sans-bold mb-2" [disabled]="true">
      {{ 'pick.player.submit.button' | translate }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'changing-player'">
    <div class="d-flex justify-content-between align-items-center d-lg-block">
      <img class="player-image d-block d-lg-none" src="{{ selectedPlayer.Image | contentImage : '3' }}" />
      <div>
        <div class="question-text pe-1">
          <span class="font-sans-bold font-size-14">{{ selectedPlayer.PlayerName }}</span>
        </div>
        <button class="btn btn-success btn-select font-sans-bold mb-2" (click)="buttonClick()">
          {{ 'pick.player.change.player.button' | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'confirm'">
    <div class="d-flex justify-content-between align-items-center d-lg-block">
      <img class="player-image d-block d-lg-none" src="{{ selectedPlayer.Image | contentImage : '3' }}" />
      <div>
        <div class="question-text font-size-14 pe-1">
          <span class="player-selected font-sans-bold text-black">{{ selectedPlayer.PlayerName }}</span>
        </div>
    
        <button class="btn btn-success btn-select btn-submit shadow font-sans-bold mb-2" (click)="buttonClick()">
          {{ 'pick.player.submit.button' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
