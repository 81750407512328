<fgb-banner [title]="'games.page.title' | translate"></fgb-banner>

<fgb-balance-bar></fgb-balance-bar>
<fgb-game-nav></fgb-game-nav>

<ng-container
  *ngIf="
    {
      polls: polls$ | async,
      predictors: predictors$ | async,
      quizzes: quizzes$ | async,
      currentGames: currentGames$ | async
    } as surveys;
    else noSurveys
  "
>
  <ng-container
    *ngIf="
      !!surveys.quizzes.length || !!surveys.polls.length || !!surveys.predictors.length || !!surveys.currentGames.length;
      else noSurveys
    "
  >
    <!--Quiz-->
    <div class="container games">
      <div *ngIf="surveys.quizzes && !!surveys.quizzes.length">
        <div class="title">
          <h4 class="text-center text-black" [ngClass]="isCorporate ? 'corporate-font' : 'default-font'">
            {{ 'games.page.trivia.title' | translate }}
          </h4>
        </div>
        <div class="d-block d-lg-none">
          <ng-container *ngFor="let quiz of surveys.quizzes">
            <fgb-survey-item [survey]="quiz"></fgb-survey-item>
          </ng-container>
        </div>
        <owl-carousel-o class="d-none d-lg-block" [options]="customOptions2">
          <ng-container *ngFor="let quiz of surveys.quizzes">
            <ng-template carouselSlide [width]="900">
              <fgb-survey-item [survey]="quiz"></fgb-survey-item>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
      <!--Polls-->
      <div *ngIf="surveys.polls && !!surveys.polls.length">
        <div class="title">
          <h4 class="text-center text-black mt-4" [ngClass]="isCorporate ? 'corporate-font' : 'default-font'">
            {{ 'games.page.polls.title' | translate }}
          </h4>
        </div>
        <div class="d-block d-lg-none">
          <ng-container *ngFor="let poll of surveys.polls">
            <fgb-survey-item [survey]="poll"></fgb-survey-item>
          </ng-container>
        </div>
        <owl-carousel-o class="d-none d-lg-block" [options]="customOptions2">
          <ng-container *ngFor="let poll of surveys.polls">
            <ng-template carouselSlide [width]="900">
              <fgb-survey-item [survey]="poll"></fgb-survey-item>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
      <!--Predictor-->
      <div *ngIf="surveys.predictors && !!surveys.predictors.length">
        <div class="title">
          <h4 class="text-center text-black mt-4" [ngClass]="isCorporate ? 'corporate-font' : 'default-font'">
            {{ 'games.page.predictor.title' | translate }}
          </h4>
        </div>
        <div class="d-block d-lg-none">
          <ng-container *ngFor="let predictor of surveys.predictors">
            <fgb-survey-item [survey]="predictor"></fgb-survey-item>
          </ng-container>
        </div>
        <owl-carousel-o class="d-none d-lg-block" [options]="customOptions2">
          <ng-container *ngFor="let predictor of surveys.predictors">
            <ng-template carouselSlide [width]="900">
              <fgb-survey-item [survey]="predictor"></fgb-survey-item>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
      <!--Pick a Player-->
      <!-- <ng-container *ngIf="surveys.currentGames && !!surveys.currentGames.length">
        <div class="title">
          <h4 class="text-center text-black mt-4">{{ 'games.page.pick.player.title' | translate }}</h4>
        </div>
        <owl-carousel-o class="d-block d-lg-none" [options]="customOptions">
          <ng-container *ngFor="let game of surveys.currentGames">
            <ng-template carouselSlide [width]="322">
              <fgb-fantasy-game-panel
                [game]="game"
                (button)="goToDetail(game.GameId)"
                [buttonState]="hasActivePlayer(game) ? 'player-link' : 'no-player-link'"
              ></fgb-fantasy-game-panel>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
        <owl-carousel-o class="d-none d-lg-block" [options]="customOptions2">
          <ng-container *ngFor="let game of surveys.currentGames">
            <ng-template carouselSlide [width]="450">
              <fgb-fantasy-game-panel
                [game]="game"
                (button)="goToDetail(game.GameId)"
                [buttonState]="hasActivePlayer(game) ? 'player-link' : 'no-player-link'"
              ></fgb-fantasy-game-panel>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </ng-container> -->
    </div>
  </ng-container>
</ng-container>

<!--Placeholder Information-->
<ng-template #noSurveys>
  <div class="no-items w-100 text-center">
    <div
      class="icon svg no-results-placeholder-icon fw-bold text-center"
      [inlineSVG]="'assets/images/placeholders/no-games.svg'"
    ></div>
    <div
      class="no-results-placeholder-title fw-bold text-uppercase"
      [ngClass]="isCorporate ? 'text-secondary-corporate corporate-font' : 'text-primary default-font'"
    >
      {{ 'featured.games.placeholder.title' | translate }}
    </div>
    <div class="no-results-placeholder-text text-info mb-5">{{ 'featured.games.placeholder.description' | translate }}</div>
  </div>
</ng-template>
