<div class="w-100 register-panel">
  <div class="overflow-hidden p-0 register-panel">
    <div class="row">
      <div class="d-flex d-lg-none justify-content-center mb-3 bg-primary p-2" [class.bg-corporate-primary]="isCorporate">
        <img src="assets/images/logo.svg" width="45" height="45" class="h-100" draggable="false" *inPopulation="[populations.Membership]"/>
        <img width="45" height="45" src="assets/images/login-registration/kid-logo.png" draggable="false" *inPopulation="[populations.JuniorBluebirds]"/>
        <img width="45" height="45" src="assets/images/login-registration/corporate-logo.png" draggable="false" *inPopulation="[populations.Hospitality]"/>
      </div>
      <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center login-banner">
        <img width="288" height="288" src="assets/images/login-registration/logo.png" draggable="false" *inPopulation="[populations.Membership]"/>
        <img width="288" height="288" src="assets/images/login-registration/kid-logo.png" draggable="false" *inPopulation="[populations.JuniorBluebirds]"/>
        <img width="288" height="288" src="assets/images/login-registration/corporate-logo.png" draggable="false" *inPopulation="[populations.Hospitality]"/>
      </div>
      <div class="user-login-panel col-lg-6 col-12 text-start bg-white">
        <div class="container h-100">
          <div class="row h-100">
            <div class="col-12">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
