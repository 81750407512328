<div class="number-increment">
  <div class="arrow-container" *ngIf="!control?.disabled">
    <!-- Increment Button -->
    <a class="arrow up" [class.disabled]="innerValue === maxLimit" (click)="increment()"></a>
  </div>
  <input class="rounded-0" id="numberInput" type="text" [(ngModel)]="innerValue" #input [disabled]="control?.disabled" />
  <div class="arrow-container" *ngIf="!control?.disabled">
    <!-- Decrement Button -->
    <a class="arrow down" [class.disabled]="innerValue === 0" (click)="decrement()"></a>
  </div>
</div>
