import { Component, OnInit } from '@angular/core';
import { ErrorStateService } from '@fgb/core';

@Component({
  selector: 'fgb-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss']
})
export class ErrorsComponent implements OnInit {
  constructor(public errorService: ErrorStateService) {}

  ngOnInit() {}
}
