<div class="d-flex align-items-center"
     [class.h-100]="survey.AnswerDisplayType === answerDisplayType.Text"
  >
  <div class="text-center w-100">
    <ng-container [ngSwitch]="survey.ProcessStatus">
      <!-- Waiting for Processing -->
      <div *ngSwitchCase="0">
        <fgb-predictor-completed-pending [selectedAnswers]="selectedAnswers" [displayType]="survey.AnswerDisplayType"></fgb-predictor-completed-pending>
      </div>
      <!-- Processed -->
      <div *ngSwitchCase="2">
        <fgb-predictor-completed-processed
          [survey]="survey"
          [selectedAnswers]="selectedAnswers"
          [correctAnswer]="correctAnswer"
          [hasSelectedCorrect]="hasSelectedCorrect"
          [pointsWon]="pointsWon"
          [displayType]="survey.AnswerDisplayType"
          class="h-100"
        >
        </fgb-predictor-completed-processed>
      </div>
    </ng-container>
  </div>
</div>
