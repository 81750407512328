import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketListComponent } from './components/ticket-transfer/ticket-list/ticket-list.component';
import { TicketTopUpListComponent } from './components/ticket-top-up/ticket-topup-list/ticket-topup-list.component';
import { TicketTopupCheckoutComponent } from './components/ticket-top-up/ticket-topup-checkout/ticket-topup-checkout.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { RouterModule } from '@angular/router';
import { SelectedTicketsTableComponent } from './components/ticket-transfer/selected-tickets-table/selected-tickets-table.component';
import { TransferFormComponent } from './components/ticket-transfer/transfer-form/transfer-form.component';
import { TicketTransferWindowComponent } from './components/ticket-transfer/ticket-transfer-window/ticket-transfer-window.component';
import { TicketDetailsUnloadedComponent } from './components/ticket-top-up/ticket-details/ticket-details-unloaded/ticket-details-unloaded.component';
import { TicketUnloadedListComponent } from './components/ticket-top-up/ticket-unloaded-list/ticket-unloaded-list.component';
import { TicketLoadedListComponent } from './components/ticket-top-up/ticket-loaded-list/ticket-loaded-list.component';
import { EcashModule } from '../ecash/ecash.module';
import { TicketDetailsLoadedComponent } from './components/ticket-top-up/ticket-details/ticket-details-loaded/ticket-details-loaded.component';
import { TicketTopupCheckoutListComponent } from './components/ticket-top-up/ticket-topup-checkout-list/ticket-topup-checkout-list.component';
import { TicketTransferLogComponent } from './components/ticket-transfer/ticket-transfer-log/ticket-transfer-log.component';
import { UserModule } from '../user/user.module';
import { SelectedTicketsCountComponent } from './components/ticket-transfer/selected-tickets-count/selected-tickets-count.component';
import { EventsModule } from '../events/events.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { TicketListItemComponent } from './components/ticket-transfer/ticket-list-item/ticket-list-item.component';
import { TicketTransferStatusComponent } from './components/ticket-transfer/ticket-transfer-status/ticket-transfer-status.component';

@NgModule({
  declarations: [
    TicketListComponent,
    SelectedTicketsTableComponent,
    TransferFormComponent,
    TicketTransferWindowComponent,
    TicketTransferLogComponent,
    TicketTopUpListComponent,
    TicketTopupCheckoutComponent,
    TicketDetailsUnloadedComponent,
    TicketUnloadedListComponent,
    TicketLoadedListComponent,
    TicketDetailsLoadedComponent,
    TicketTopupCheckoutListComponent,
    SelectedTicketsCountComponent,
    TicketListItemComponent,
    TicketTransferStatusComponent,
  ],
  exports: [
    TicketListComponent,
    SelectedTicketsTableComponent,
    TicketTransferWindowComponent,
    TicketTransferLogComponent,
    TransferFormComponent,
    TicketTopupCheckoutComponent,
    TicketDetailsUnloadedComponent,
    TicketUnloadedListComponent,
    TicketLoadedListComponent,
    TicketTopUpListComponent,
    SelectedTicketsCountComponent,
    TicketListItemComponent,
  ],
  imports: [
    NgbModule,
    CommonModule,
    UserModule,
    SharedModule,
    FormsModule,
    QRCodeModule,
    RouterModule,
    ReactiveFormsModule,
    EcashModule,
    EventsModule,
    InlineSVGModule.forRoot()
  ]
})
export class TicketsModule { }
