<fgb-banner [title]="name$ | async" class="banner d-lg-flex d-none"></fgb-banner>

<div class="container">
  <div class="d-lg-none d-block pt-3">
    <fgb-barcode></fgb-barcode>
  </div>
</div>

<fgb-account-nav></fgb-account-nav>

<div class="container mt-4">
  <div class="row">
    <h5 class="mb-2 mt-0 d-none d-lg-block col-lg-6" [ngClass]="isCorporate ? 'corporate-font' : 'default-font'">
      {{ 'account.member.card.title' | translate }}
    </h5>
    <h5 class="mb-2 mt-0 col-12 col-lg-6" [ngClass]="isCorporate ? 'corporate-font' : 'default-font'">
      {{ 'account.member.detail.title' | translate }}
    </h5>
  </div>
  <div class="row">
    <fgb-member-card showSeating="true" class="col-6 d-none d-lg-block"></fgb-member-card>
    <fgb-account-details class="col-lg-6"></fgb-account-details>
  </div>
</div>
