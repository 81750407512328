import { Component, HostListener, OnInit } from '@angular/core';
import { CardLinkOfferOptions, CardLinkOfferType } from '@fgb/core';

@Component({
  selector: 'fgb-card-linking-success-page',
  templateUrl: './card-linking-success-page.component.html',
  styleUrls: ['./card-linking-success-page.component.scss'],
})
export class CardLinkingSuccessPageComponent implements OnInit {
  getScreenWidth: number;

  cardLinkOfferOptionsRecommendedOffers: CardLinkOfferOptions = {
    offerType: CardLinkOfferType.OffersYouMayLike,
    limit: 4,
    showMoreButton: false,
    showMoreIncrement: 2,
  };

  constructor() {}

  ngOnInit(): void {
    this.getScreenWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
  }
}
