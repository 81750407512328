 <div class="d-none d-lg-block">
   <fgb-banner [title]="'affiliates.rewards.page.title' | translate"></fgb-banner>
 </div>
<ng-container *ngIf="cardCount == 0">
  <div class="container pt-3">
    <button class="btn btn-tertiary affiliate-link-your-card mb-3 rounded" [routerLink]="['/linkcard']">
      <span class="icon material-icons">credit_card</span>{{ 'affiliate-link-card-button-title' | translate }}
    </button>
  </div>
</ng-container>
<ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.all.offers.tab'">
  <ul class="nav nav-secondary">
    <a
      class="nav-item card-linking-offer-nav"
      [routerLink]="['/affiliates/alloffers']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <span class="icon material-icons">style</span>
      <label>{{ 'affiliate-tab-all-offers-text' | translate }}</label>
    </a>
    <a
      class="nav-item card-linking-offer-nav"
      [routerLink]="['/affiliates/myoffers']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <span class="icon material-icons">credit_score</span>
      <label>{{ 'affiliate-tab-my-offers-text' | translate }}</label>

      <ng-container *ngIf="activatedOffersCount$ | async as activatedCount"
        ><label class="fw-normal p-0">({{ activatedCount }})</label></ng-container
      >
    </a>
  </ul>
</ng-container>
<router-outlet></router-outlet>
