import { Component, OnInit } from '@angular/core';
import { MemberDetails, config, BalanceQuery, MemberQuery, MemberCard, MemberCardQuery } from '@fgb/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'fgb-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent implements OnInit {
  member$: Observable<MemberDetails | undefined>;
  combinedCard$: Observable<number | undefined>;
  loyaltyPointsCard$: Observable<number | undefined>;
  card$: Observable<MemberCard | undefined>;
  allowEditing: boolean = false;

  constructor(private memberQuery: MemberQuery, private balanceQuery: BalanceQuery, private memberCardQuery: MemberCardQuery) {}

  ngOnInit() {
    this.member$ = this.memberQuery.selectMemberDetails();
    this.combinedCard$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.loyaltyPointsCard$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.card$ = this.memberCardQuery.selectCurrentCard();
  }
}
