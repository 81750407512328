import { Component, OnInit, OnDestroy } from '@angular/core';
import { TransactionSuccessQuery, TransactionSuccessService, LottoItem, PopulationQuery } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-lottos-success-page',
  templateUrl: './lottos-success-page.component.html',
  styleUrls: ['./lottos-success-page.component.scss'],
})
export class LottosSuccessPageComponent implements OnInit, OnDestroy {
  public lottoItem: LottoItem;
  public totalPrice: number;
  public quantity: number;
  populations = Populations;
  isCorporate: boolean;

  constructor(
    private transactionSuccessQuery: TransactionSuccessQuery,
    private transactionSuccessService: TransactionSuccessService,
    private populationQuery: PopulationQuery
  ) {}

  ngOnInit() {
    const transaction = this.transactionSuccessQuery.getValue();
    this.lottoItem = transaction.item as LottoItem;
    this.totalPrice = transaction.totalPrice;
    this.quantity = transaction.quantity;
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
  }

  ngOnDestroy(): void {
    this.transactionSuccessService.clear();
  }
}
