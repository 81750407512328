<div class="card" [ngClass]="{ 'py-2 mt-3 mt-lg-0': accountView }">
  <div class="card-body p-3 px-lg-4">
    <div>
      <h1 *ngIf="ecashView" class="history-heading d-none d-lg-block">TRANSACTIONS HISTORY</h1>
    </div>
    <div class="row mx-lg-1">
      <div class="col-12" [ngClass]="{ 'col-lg-7': accountView, 'col-lg-9': ecashView }">
        <fgbcl-date-range-picker [(startDate)]="transactionStartDate" [(endDate)]="transactionEndDate"></fgbcl-date-range-picker>
      </div>
      <div class="col-12" [ngClass]="{ 'col-lg-2 offset-lg-3': accountView, 'col-lg-3': ecashView }">
        <button
          type="button"
          class="search-button font-sans-bold d-block mx-auto border-0 text-white bg-success w-100"
          (click)="search()"
        >
          {{ 'account.activity.search.button' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<fgb-transaction-list
  *ngIf="selectedValues; else noSearch"
  [startDate]="selectedValues.start"
  [endDate]="selectedValues.end"
  [memberId]="memberId"
  [transactionType]="selectedValues.type"
></fgb-transaction-list>

<ng-template #noSearch>
  <div class="no-search"></div>
</ng-template>
