import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  AuthService,
  BalanceQuery,
  Card,
  CardQuery,
  config,
  MemberCard,
  MemberCardQuery,
  MemberDetails,
  MemberQuery,
  PopulationQuery,
  SportsAllianceAuthService,
} from '@fgb/core';
import { Router, NavigationStart } from '@angular/router';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { Populations } from 'src/app/shared/utilities/populations';
@Component({
  selector: 'fgb-mobile-nav-menu',
  templateUrl: './mobile-nav-menu.component.html',
  styleUrls: ['./mobile-nav-menu.component.scss'],
})
export class MobileNavMenuComponent implements OnInit, OnDestroy {
  memberDetails$: Observable<MemberDetails | undefined>;
  memberCard$: Observable<MemberCard | undefined>;
  selectedMember: string;
  cards$: Observable<Card[]>;
  loyaltyPointsCard$: Observable<number | undefined>;
  populations = Populations;
  isCorporate: boolean;
  isJuniorBluebirds: boolean;
  constructor(
    private authService: AuthService,
    private router: Router,
    private memberQuery: MemberQuery,
    private memberCardQuery: MemberCardQuery,
    private cardQuery: CardQuery,
    private balanceQuery: BalanceQuery,
    private sportsAllianceService: SportsAllianceAuthService,
    private populationQuery: PopulationQuery
  ) {}
  private routerSubscription: Subscription;
  public isCollapsed = true;

  ngOnInit() {
    this.initMemberAccounts();
    this.routerSubscription = this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        tap(() => (this.isCollapsed = true))
      )
      .subscribe();
    this.isJuniorBluebirds = this.populationQuery.userInPopulation([Populations.JuniorBluebirds]);
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
    this.loyaltyPointsCard$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
  }

  navigateToHome() {
    this.router.navigate(['/']);
  }
  
  initMemberAccounts() {
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.memberCard$ = this.memberCardQuery.selectCurrentCard();
    this.cards$ = this.cardQuery.selectCards();
    this.memberCard$.subscribe((card) => {
      console.log(card)
    })
    this.memberDetails$.subscribe((mem) => {
      console.log(mem)
    })
    this.selectedMember = this.memberQuery.getUserContext().memberId;
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  logout() {
    firstValueFrom(this.sportsAllianceService
      .logout()
      .pipe(tap(() => this.router.navigate(['/login']))));
  }

  loginWithCard(memberId: string) {
    firstValueFrom(this.authService.switchUser(memberId));
  }
}
