<div class="survey-answer text-center" [ngClass]="hasSelectedCorrect ? 'my-lg-5 my-2' : 'my-2'">
  <div class="row">
    <div class="col-12 col-lg-6 order-1">
      <div class="survey-answer-container text-answer text-white row">
        <div [ngClass]="hasSelectedCorrect ? 'col-12' : 'col-12'">
          <div
            class="survey-answer-item my-3"
            [ngClass]="hasSelectedCorrect ? 'correct' : 'incorrect '"
            *ngFor="let answer of selectedAnswers"
          >
            <div class="answer-text text-center px-3 text-type">
              <span>{{ answer.Title }}</span>
            </div>
          </div>
        </div>
        <div class="col-12" *ngIf="!hasSelectedCorrect">
          <div class="survey-answer-item correct my-3">
            <div class="answer-text text-center px-3 text-type">
              <span>{{ correctAnswer.Title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-6 order-2">
      <ng-container *ngIf="hasSelectedCorrect; else incorrect">
        <div class="survey-message text-success">
          <div>
            <ng-container *ngIf="pointsWon; else noPoints">
              <div class="font-sans-bold font-size-18 mt-4 mt-lg-0">
                {{ 'trivia.correct.answer.title' | translate }}
              </div>
              <div class="points-won font-sans-bold font-size-18 mt-2" [ngClass]="{'invisible': isJuniorBluebirds}">
                + {{ pointsWon }} {{ 'trivia.correct.answer.description' | translate }}
              </div>
            </ng-container>
            <ng-template #noPoints>
              <p class="font-sans-bold font-size-18 mt-4 mt-4 mt-lg-0">
                {{ 'trivia.correct.answer.title' | translate }}
              </p>
            </ng-template>
          </div>
        </div>
      </ng-container>
      <ng-template #incorrect>
        <div class="survey-message incorrect text-danger text-center">
          <div>
            <div class="text-center font-sans-bold font-size-18 mt-4 mt-4 mt-lg-0">
              {{ 'trivia.wrong.answer.title' | translate }}
            </div>
            <div class="text-center points-won font-sans-bold font-size-18 mt-2">{{ 'trivia.wrong.answer.description' | translate }}</div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
