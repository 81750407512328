<div class="survey-answer row my-3">
  <div class="col-md-6 order-md-0 order-12">
    <div
      class="survey-answer-container"
      [ngClass]="hasSelectedCorrect ? 'justify-content-lg-end justify-content-center me-0 me-lg-4' : 'justify-content-around'"
    >
      <div
        class="survey-answer-item m-2"
        [ngClass]="hasSelectedCorrect ? 'correct' : 'incorrect'"
        *ngFor="let answer of selectedAnswers"
      >
        <div class="image-container">
          <img src="{{ answer.ImageURL | contentImage: '1' }}" alt="{{ answer.ImageURL }}" />
        </div>
        <div class="answer-text d-flex justify-content-center align-items-center text-white">
          <span>{{ answer.Title }}</span>
        </div>
      </div>
      <div class="survey-answer-item correct m-2" *ngIf="!hasSelectedCorrect">
        <div class="image-container">
          <img src="{{ correctAnswer.ImageURL | contentImage: '1' }}" alt="{{ correctAnswer.ImageURL }}" />
        </div>
        <div class="answer-text text-center text-center text-white d-flex justify-content-center align-items-center">
          <span>{{ correctAnswer.Title }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="survey-answer-text-container col-md-6 order-md-12 order-0">
    <ng-container *ngIf="hasSelectedCorrect; else incorrect">
      <div class="survey-message text-success">
        <div>
          <ng-container *ngIf="pointsWon; else noPoints">
            <div class="font-sans-bold font-size-18">{{ 'trivia.correct.answer.title' | translate }}</div>
            <div class="points-won font-sans-bold font-size-18 mt-2" *notInPopulation="populations.JuniorBluebirds">
              + {{ pointsWon }} {{ 'trivia.correct.answer.description' | translate }}
            </div>
          </ng-container>
          <ng-template #noPoints>
            <div class="font-sans-bold font-size-18">{{ 'trivia.correct.answer.title' | translate }}</div>
          </ng-template>
        </div>
      </div>
    </ng-container>
    <ng-template #incorrect>
      <div class="survey-message text-danger incorrect">
        <div>
          <div class="text-center font-sans-bold font-size-18">{{ 'trivia.wrong.answer.title' | translate }}</div>
          <div class="text-center points-won font-sans-bold mt-2">{{ 'trivia.wrong.answer.description' | translate }}</div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
