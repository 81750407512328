<!--Banner-->
<fgb-banner [title]="'home.page.banner' | translate" class="d-none d-lg-block"></fgb-banner>

<!--Member Card-->
<div class="d-block d-lg-none">
  <div class="container py-3">
    <fgb-barcode></fgb-barcode>
  </div>
</div>

<!--Scorecards carousel-->
<ng-container *notInPopulation="[populations.Free]">
  <div>
    <fgbcl-scorecard-carousel
      [showAllView]="true"
      [phaseThree]="true"
      icon="chevron_right"
      [ngClass]="{ corporate: isCorporate }"
    ></fgbcl-scorecard-carousel>
  </div>
</ng-container>

<!--Vouchers-->
<div class="container my-4">
  <fgb-vouchers></fgb-vouchers>
</div>

<!-- Savings -->
<ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.savings'">
  <ng-container *notInPopulation="[populations.JuniorBluebirds]">
    <div class="savings-container container mt-4 mb-3">
      <div class="savings-card p-3 text-white" [ngClass]="{ 'bg-primary-corporate': isCorporate, 'bg-primary': !isCorporate }">
        <div class="d-flex flex-column flex-lg-row align-items-center justify-content-center">
          <div class="col-lg-1 ms-2"><span class="icon material-icons savings-icon">savings</span></div>
          <div class="col-lg-8 text-lg-start text-center">
            <div class="card-heading">{{ 'savings.widget.title' | translate }}</div>
            <div class="card-body font-size-14">{{ 'savings.widget.desc' | translate }}</div>
          </div>
          <div class="col-lg-3 col-12">
            <button
              type="button"
              class="btn card-btn border bg-white"
              [ngClass]="{ 'text-primary-corporate': isCorporate, 'text-primary': !isCorporate }"
              [routerLink]="['/savings']"
            >
              <span class="ms-1 wallet-button-text font-size-14 font-heading fw-bold">{{
                'savings.widget.button' | translate
              }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<!-- Featured Rewards -->
<ng-container *ngIf="mixedLoyaltyItems$ | async as mixedLoyaltyItems">
  <div *ngIf="mixedLoyaltyItems.length > 0">
    <div class="featured-rewards-container pb-2">
      <div class="container">
        <fgbcl-featured-rewards [ngClass]="{ corporate: isCorporate, junior: isJuniorBluebird }"></fgbcl-featured-rewards>
      </div>
    </div>
  </div>
</ng-container>

<!-- Announcements -->
<div class="container">
  <fgbcl-announcements></fgbcl-announcements>
</div>

<div class="container pt-3">
  <div class="text-center mt-3">
    <a href="https://www.facebook.com/cardiffcityfc" target="_blank" class="px-2">
      <img
        class="social-media-logo"
        src="assets/images/social-media-icons/facebook.svg"
        draggable="false"
        *notInPopulation="populations.Hospitality"
      />
      <img
        class="social-media-logo"
        src="assets/images/social-media-icons/facebook-corporate.svg"
        draggable="false"
        *inPopulation="populations.Hospitality"
      />
    </a>
    <a href="https://www.instagram.com/cardiffcityfc/" target="_blank" class="px-2">
      <img
        class="social-media-logo"
        src="assets/images/social-media-icons/instagram.svg"
        draggable="false"
        *notInPopulation="populations.Hospitality"
      />
      <img
        class="social-media-logo"
        src="assets/images/social-media-icons/instagram-corporate.svg"
        draggable="false"
        *inPopulation="populations.Hospitality"
      />
    </a>
    <a href="https://twitter.com/CardiffCityFC" target="_blank" class="px-2">
      <img
        class="social-media-logo"
        src="assets/images/social-media-icons/x.png"
        draggable="false"
        *notInPopulation="populations.Hospitality"
      />
      <img
        class="social-media-logo"
        src="assets/images/social-media-icons/x-corporate.svg"
        draggable="false"
        *inPopulation="populations.Hospitality"
      />
    </a>
    <a href="https://www.youtube.com/channel/UCfBVy8PAMwyNbac6D0Mk8gQ" target="_blank" class="px-2">
      <img
        class="social-media-logo"
        src="assets/images/social-media-icons/youtube.svg"
        draggable="false"
        *notInPopulation="populations.Hospitality"
      />
      <img
        class="social-media-logo"
        src="assets/images/social-media-icons/youtube-corporate.svg"
        draggable="false"
        *inPopulation="populations.Hospitality"
      />
    </a>
    <a href="https://www.threads.net/@cardiffcityfc" target="_blank" class="px-2">
      <img
        class="social-media-logo"
        src="assets/images/social-media-icons/threads.svg"
        draggable="false"
        *notInPopulation="populations.Hospitality"
      />
      <img
        class="social-media-logo"
        src="assets/images/social-media-icons/threads-corporate.svg"
        draggable="false"
        *inPopulation="populations.Hospitality"
      />
    </a>
    <a href="https://www.tiktok.com/@cardiffcityfc" target="_blank" class="px-2">
      <img
        class="social-media-logo"
        src="assets/images/social-media-icons/tiktok.svg"
        draggable="false"
        *notInPopulation="populations.Hospitality"
      />
      <img
        class="social-media-logo"
        src="assets/images/social-media-icons/tiktok-corporate.svg"
        draggable="false"
        *inPopulation="populations.Hospitality"
      />
    </a>
  </div>
</div>
