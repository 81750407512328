import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CardLinkOffer, CardLinkOfferOptions, CardLinkOffersQuery, CardLinkOffersService, CardLinkOfferType } from '@fgb/core';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'fgb-card-link-offer-details-page',
  templateUrl: './card-link-offer-details-page.component.html',
  styleUrls: ['./card-link-offer-details-page.component.scss'],
})
export class CardLinkOfferDetailsPageComponent implements OnInit, OnDestroy {
  offerId: number;
  cardLinkOfferDetail$: Observable<CardLinkOffer | undefined>;

  private _destroyed$ = new Subject<void>();

  cardLinkOfferOptionsRecommendedOffers: CardLinkOfferOptions;

  cardLinkOfferOptionsOtherOffers: CardLinkOfferOptions;
  constructor(
    private route: ActivatedRoute,
    private cardLinkOffersService: CardLinkOffersService,
    private cardLinkOffersQuery: CardLinkOffersQuery,
    private router: Router
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this._destroyed$)).subscribe((params) => {
      this.offerId = params['id'];
      this.cardLinkOfferOptionsRecommendedOffers = {
        offerType: CardLinkOfferType.OffersYouMayLike,
        limit: 4,
        showMoreButton: false,
        showMoreIncrement: 4,
        excludingOfferId: this.offerId,
      };
    });

    firstValueFrom(this.cardLinkOffersService.fatchAffiliateOffer());
    this.getOfferDetailsAndOtherOffer(this.offerId);
  }

  getOfferDetailsAndOtherOffer(offerId: number) {
    this.cardLinkOfferDetail$ = this.cardLinkOffersQuery.selectEntity(offerId);
    this.cardLinkOfferDetail$.pipe(takeUntil(this._destroyed$)).subscribe((offer) => {
      this.cardLinkOfferOptionsOtherOffers = {
        offerType: CardLinkOfferType.OtherOffers,
        limit: 4,
        showMoreButton: false,
        showMoreIncrement: 4,
        companyID: offer?.CompanyId,
        excludingOfferId: offerId,
      };
    });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
