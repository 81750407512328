import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { fromEvent, merge, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fgb-number-increment-input',
  templateUrl: './number-increment-input.component.html',
  styleUrls: ['./number-increment-input.component.scss'],
})
export class NumberIncrementInputComponent implements AfterViewInit, OnInit, OnDestroy, ControlValueAccessor {
  @Input() maxLimit: number = 999;
  innerValue: number = 0;
  onChangeCallback = (_: any) => {};
  onTouchedCallback = () => {};
  control: AbstractControl | any;
  private numberChange$: Subject<any> = new Subject<void>();
  private _unsubscribeAll = new Subject<void>();
  @ViewChild('input') inputText: ElementRef<HTMLElement>;

  constructor(@Optional() @Self() private ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  ngOnInit(): void {
    if (this.ngControl) {
      this.control = this.ngControl.control;
      this.control.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit(): void {
    merge(this.numberChange$, fromEvent(this.inputText.nativeElement, 'input').pipe(map((event: any) => +event.target.value)))
      .pipe(
        map((value) => {
          if (value <= 0 || isNaN(value)) {
            return 0;
          }
          if (value >= this.maxLimit) {
            return this.maxLimit;
          }
          return value;
        }),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe((val) => {
        this.writeValue(val);
      });
  }

  increment(): void {
    this.numberChange$.next(++this.innerValue);
  }

  decrement(): void {
    this.numberChange$.next(--this.innerValue);
  }

  writeValue(value: number): void {
    this.innerValue = value;
    this.onChangeCallback(this.innerValue);
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
}
