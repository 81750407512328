import { Component, OnInit } from '@angular/core';
import { PopulationQuery } from '@fgb/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-earn-page',
  templateUrl: './earn-page.component.html',
  styleUrls: ['./earn-page.component.scss'],
})
export class EarnPageComponent implements OnInit {
  deviceType: string;
  populations = Populations;
  isCorporate: boolean;
  dropdownColour = 'primary';
  constructor(private deviceDetector: DeviceDetectorService, private populationQuery: PopulationQuery) {}

  ngOnInit() {
    this.deviceType = this.deviceDetector.os.toUpperCase();
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
    if (this.isCorporate) {
      this.dropdownColour = 'primary-corporate';
    }
  }
}
