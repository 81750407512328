<div (click)="add(addNewContactForm)" class="add-new-contact-btn text-primary">
  <span class="icon-plus-circle"></span>
  Add New Contact
</div>
<ng-container *ngIf="addressBook$ | async as addressBook; else loading">
  <ng-container *ngIf="addressBook.length > 0; else noContacts">
    <div class="d-none d-lg-block">
      <table class="table bg-white">
        <thead>
          <tr>
            <td class="sub-title">Full Name</td>
            <td class="sub-title">Email</td>
            <td class="text-center"></td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of addressBook">
            <td>{{ item.FullName }}</td>
            <td>{{ item.EmailAddress }}</td>
            <td>
              <div class="d-flex justify-content-around">
                <button (click)="edit(item, editContactForm)" class="btn btn-primary btn-remove-contact">Edit</button>
                <button
                  class="btn btn-danger btn-remove-contact"
                  confirmModal
                  [confirmContent]="confirmContent"
                  (confirm)="removeContact(item.Id)"
                >
                  <span class="icon-trash"></span>
                </button>
                <ng-template #confirmContent
                  ><div class="text-center">
                    Are you sure that you want to delete {{ item.FullName }} from your contacts?
                  </div></ng-template
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-block d-lg-none">
      <div class="card p-4">
        <div class="mt-3 mb-3" *ngFor="let item of addressBook">
          <div>
            <div class="sub-title">Full Name</div>
            <div class="result-field bg-muted p-2">{{ item.FullName }}</div>
          </div>
          <div>
            <div class="sub-title">Email</div>
            <div class="result-field bg-muted p-2">{{ item.EmailAddress }}</div>
          </div>
          <div class="row">
            <div class="container mt-2 mb-3">
              <button (click)="edit(item, editContactForm)" class="col-3 btn btn-primary btn-remove-contact">Edit</button>
              <button (click)="open(confirmDelete)" class="col-2 offset-7 btn btn-danger btn-remove-contact">
                <span class="icon-trash"></span>
              </button>
            </div>
          </div>
          <ng-template #confirmDelete let-modal>
            <div class="modal-body">
              <div body class="text-center color-secondary p-3 font-size-14">
                Are you sure that you want to delete {{ item.FullName }} from your contacts?
              </div>
              <div class="btn-container">
                <button type="button" class="btn btn-danger" (click)="modal.close('Close click')">Cancel</button>
                <button type="button" class="btn btn-success" (click)="removeContact(item.Id)">Confirm</button>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #noContacts>
    <div class="no-contacts text-center">
      <div class="icon svg no-contacts-icon" [inlineSVG]="'assets/images/icons/address-book.svg'"></div>
      <p class="text-primary">No Contacts</p>
      <span>List Empty</span>
    </div>
  </ng-template>
</ng-container>
<ng-template #loading>
  <fgb-loading></fgb-loading>
</ng-template>
<!-- Add New Modal -->
<ng-template #addNewContactForm let-modal>
  <div class="modal-header">
    <h4 class="modal-title text-center text-dark" id="modal-basic-title">Add Contact</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group h-100 d-flex flex-column justify-content-between">
      Full Name
      <input type="text" class="form-control bg-muted" [(ngModel)]="fullName" />
      Email
      <input type="email" class="form-control bg-muted" [(ngModel)]="email" />
      <button class="btn btn-danger" [disabled]="!fullName || !email" (click)="addContact()">Add</button>
    </div>
    <fgb-errors></fgb-errors>
  </div>
</ng-template>

<!-- Edit Modal -->
<ng-template #editContactForm let-modal>
  <div class="modal-header text-white">
    <h4 class="modal-title text-center text-dark" id="modal-basic-title">Edit Contact</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group h-100 d-flex flex-column justify-content-between">
      Full Name
      <input type="text" class="form-control bg-muted" placeholder="Full Name" [(ngModel)]="fullName" />
      Email
      <input type="email" class="form-control bg-muted" placeholder="Email Address" [(ngModel)]="email" />
      <button class="btn btn-success" [disabled]="!fullName || !email" (click)="updateContact()">Save</button>
    </div>
    <fgb-errors></fgb-errors>
  </div>
</ng-template>
