import { Component, OnInit } from '@angular/core';
import { MemberQuery, PopulationQuery } from '@fgb/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-account-details-page',
  templateUrl: './account-details-page.component.html',
  styleUrls: ['./account-details-page.component.scss'],
})
export class AccountDetailsPageComponent implements OnInit {
  name$: Observable<string>;
  populations = Populations;
  isCorporate: boolean;
  constructor(private memberQuery: MemberQuery, private populationQuery: PopulationQuery) {}

  ngOnInit() {
    this.name$ = this.memberQuery.selectMemberDetails().pipe(
      map((m) => {
        if (m) {
          return `${m.FirstName} ${m.Surname}`;
        }
        return '';
      })
    );
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
  }
}
