import { Component, OnInit } from '@angular/core';
import { PopulationQuery, SurveyQuery } from '@fgb/core';
import { Survey } from '@fgb/core';
import { Observable } from 'rxjs';
import { SurveyType } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-quiz-page',
  templateUrl: './quiz-page.component.html',
  styleUrls: ['./quiz-page.component.scss'],
})
export class QuizPageComponent implements OnInit {
  quizzes$: Observable<Survey[]>;
  populations = Populations;
  isCorporate: boolean;
  constructor(private surveyQuery: SurveyQuery, private populationQuery: PopulationQuery) {}

  ngOnInit() {
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
    this.quizzes$ = this.surveyQuery.selectRelevantSurveys(SurveyType.Trivia);
  }
}
