<ng-container *ngIf="scorePredictors && scorePredictors.length">
  <h5 *ngIf="displayTitle" class="text-center mt-3 mb-0">PREDICT THE SCORE</h5>
  <div *ngIf="displayTitle" class="font-size-14">Enter your prediction for this match to be in chance to Win Points</div>
  <div class="score-predictor-owl">
    <owl-carousel-o [options]="scorePredictorOptions" #scorePredictorCarousel>
      <ng-container *ngFor="let scorePredictor of scorePredictors">
        {{ scorePredictor.SurveyQuestion }}
        <ng-template carouselSlide>
          <fgb-score-predictor-item
            [survey]="scorePredictor"
            (removeScorePredictor)="removeFromCarousselNoRefresh(scorePredictor.SurveyId)"
          ></fgb-score-predictor-item>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</ng-container>
