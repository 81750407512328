import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BalanceQuery, PopulationQuery } from '@fgb/core';
import { config } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-points-summary',
  templateUrl: './points-summary.component.html',
  styleUrls: ['./points-summary.component.scss'],
})
export class PointsSummaryComponent implements OnInit {
  pointsBalance$: Observable<number | undefined>;
  populations = Populations;
  isCorporate: boolean;
  isJuniorBluebirds: boolean;
  constructor(private balanceQuery: BalanceQuery, private populationQuery: PopulationQuery) {}

  ngOnInit() {
    this.pointsBalance$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
    this.isJuniorBluebirds = this.populationQuery.userInPopulation([this.populations.JuniorBluebirds])
  }
}
