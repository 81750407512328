import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EMPTY, from, fromEvent, merge, Observable, Subject } from 'rxjs';
import { ContactService, Contact, Category, PopulationQuery } from '@fgb/core';
import { debounceTime, exhaustMap, mapTo, repeat, takeUntil, tap } from 'rxjs/operators';
import { Populations } from 'src/app/shared/utilities/populations';
@Component({
  selector: 'fgb-contact-us-page',
  templateUrl: './contact-us-page.component.html',
  styleUrls: ['./contact-us-page.component.scss'],
})
export class ContactUsPageComponent implements OnInit, AfterViewInit {
  contactUsForm: FormGroup;
  categories$: Observable<Category[]>;
  sentSuccessfully$: Subject<any> = new Subject<void>();
  showSuccessMessage$: Observable<boolean>;
  submitBtn$: Observable<any>;
  selectedCategory: string;
  populations = Populations;
  isCorporate: boolean;
  @ViewChild('submitBtn') submitBtn: ElementRef<HTMLButtonElement>;

  constructor(private formBuilder: FormBuilder, private contactService: ContactService, private populationQuery: PopulationQuery) {}

  ngOnInit(): void {
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
    this.contactUsForm = this.formBuilder.group({
      category: ['', Validators.required],
      message: [{ value: '', disabled: true }, Validators.required],
    });
    this.categories$ = this.contactService.getCategories();
    this.showSuccessMessage$ = merge(this.contactUsForm.valueChanges.pipe(mapTo(false)), this.sentSuccessfully$);
  }

  onCategoryChange() {
    this.contactUsForm.controls.message.enable();
  }
  ngAfterViewInit(): void {
    this.submitBtn$ = fromEvent(this.submitBtn.nativeElement, 'click');
    this.submitBtn$
      .pipe(
        // Prevent from clicking the button multiple times and spamming the server
        debounceTime(1000),
        exhaustMap(() => {
          this.contactUsForm.markAllAsTouched();

          if (this.contactUsForm.valid) {
            const value: Contact = this.contactUsForm.value;
            return from(this.contactService.sendMessage(value)).pipe(
              tap(() => {
                // Clear message after successfully sent
                this.contactUsForm.get('message')?.patchValue('');
                this.contactUsForm.get('message')?.markAsUntouched();
                this.contactUsForm.get('category')?.patchValue("");
                this.contactUsForm.get('message')?.disable();
                this.sentSuccessfully$.next(true);
              })
            );
          }

          this.sentSuccessfully$.next(false);
          return EMPTY;
        }),
        takeUntil(this.contactUsForm.valueChanges),
        repeat()
      )
      .subscribe();
  }
}
