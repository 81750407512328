<fgb-banner [title]="'fantasy.page.banner' | translate"></fgb-banner>

<fgb-game-nav></fgb-game-nav>

<div class="container">
  <ng-container
    *ngIf="
      {
        pendingGames: pendingGames$ | async,
        currentGames: currentGames$ | async
      } as games;
      else noGames
    "
  >
    <ng-container *ngIf="!!games.currentGames.length || !!games.pendingGames.length; else noGames">
      <ng-container *ngIf="games.currentGames && !!games.currentGames.length">
        <div class="d-lg-block d-none">
          <div class="row fantasy-game">
            <div class="col-lg-6 col-12">
              <fgb-fantasy-game-panel
                [game]="games.currentGames[0]"
                (button)="goToDetail(games.currentGames[0].GameId)"
                [buttonState]="hasActivePlayer(games.currentGames[0]) ? 'player-link' : 'no-player-link'"
              ></fgb-fantasy-game-panel>
            </div>
            <div class="col-lg-6 col-12">
              <div *ngIf="games.currentGames[1]">
                <fgb-fantasy-game-panel-next-game [game]="games.currentGames[1]"> </fgb-fantasy-game-panel-next-game>
              </div>
            </div>
          </div>
        </div>
        <div class="d-block d-lg-none mobile-carousel">
          <owl-carousel-o [options]="customOptions2">
            <ng-template carouselSlide>
              <fgb-fantasy-game-panel
                [game]="games.currentGames[0]"
                (button)="goToDetail(games.currentGames[0].GameId)"
                [buttonState]="hasActivePlayer(games.currentGames[0]) ? 'player-link' : 'no-player-link'"
              ></fgb-fantasy-game-panel
            ></ng-template>
            <ng-template carouselSlide *ngIf="games.currentGames[1]">
              <fgb-fantasy-game-panel-next-game [game]="games.currentGames[1]"> </fgb-fantasy-game-panel-next-game>
            </ng-template>
          </owl-carousel-o>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <div class="row">
    <div class="col-lg-6 col-12">
      <h5 class="fw-bold mt-4 mb-3 font-size-22">{{ 'pick.player.history.title' | translate }}</h5>
      <fgb-fantasy-history-list> </fgb-fantasy-history-list>
    </div>

    <div class="col-lg-6 col-12">
      <h5 class="fw-bold mt-4 mb-3 font-size-22">{{ 'pick.player.leaderboard.title' | translate }}</h5>
      <div class="card leaderboard-card rounded border-disabled">
        <div class="nav d-flex align-items-center justify-content-center">
          <a
            class="nav-item w-25 text-center"
            [ngClass]="{ selected: showMonthly, corporate: isCorporate }"
            (click)="showMonthly = true"
          >
            <label class="font-size-12 fw-bold text-center">{{ 'pick.player.leaderboard.monthly' | translate }}</label>
          </a>
          <a
            class="nav-item w-25 text-center"
            [ngClass]="{ selected: !showMonthly, corporate: isCorporate }"
            (click)="showMonthly = false"
          >
            <label class="font-size-12 fw-bold text-center">{{ 'pick.player.leaderboard.yearly' | translate }}</label>
          </a>
        </div>
        <div class="leaderboard-nav" *ngIf="showMonthly; else yearly">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let item of monthlyLeaderboard$ | async">
              <div class="py-1 bg-disabled leaderboard-date-toggle">
                <h2 class="text-center fw-bold font-size-12 mb-0 month-position">{{ item.monthDisplayText }}</h2>
              </div>
              <fgb-fantasy-leaderboard [fantasyEntries]="item.entries"></fgb-fantasy-leaderboard>
            </ng-template>
          </owl-carousel-o>
        </div>
        <ng-template #yearly>
          <div class="py-1 bg-disabled mt-1 year-toggle">
            <h2 class="text-center fw-bold font-size-12 mb-0 month-position">{{ 'fantasy.leaderboard.year' | translate }}</h2>
          </div>
          <fgb-fantasy-leaderboard [fantasyEntries]="yearlyLeaderboard$ | async"></fgb-fantasy-leaderboard>
        </ng-template>
        <div class="leaderboard-button text-center w-100">
          <a class="fw-bold text-info font-size-14" [routerLink]="['/games/pickaplayer/fantasyleaderboard']">{{
            'pick.player.view.full.leaderboard' | translate
          }}</a>
        </div>
      </div>
    </div>
  </div>

  <!--Placeholder Information-->
  <ng-template #noGames>
    <div class="w-100 text-center no-items pb-3 pb-lg-0">
      <div class="no-results-placeholder-description card border">
        {{ 'fantasy.page.placeholder' | translate }}
      </div>
    </div>
  </ng-template>

  <!--Rules-->
  <h5 class="game-title text-start mt-4">{{ 'pick.player.rules.title' | translate }}</h5>

  <div class="games-rules-container mt-lg-3 mb-5 pb-5">
    <div class="row">
      <div ngbAccordion #a="ngbAccordion" [destroyOnHide]="false" class="mt-3 w-100 mt-lg-0">
        <div ngbAccordionItem id="ngb-panel-0">
          <div ngbPanelHeader>
            <h2 ngbAccordionHeader class="mt-0 font-heading">
              <button ngbAccordionButton class="font-size-14 rule-question-titles">
                {{ 'pick.player.rules3.title' | translate }}
              </button>
            </h2>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <div class="row py-0">
                <div class="content col-12 font-size-14 text-black">
                  <div class="fantasy text-black">
                    <div
                      class="font-size-12"
                      [innerHTML]="'pick.player.rules3.description' | translate | markdownTranslate"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div ngbAccordionItem id="ngb-panel-1">
          <div ngbPanelHeader>
            <h2 ngbAccordionHeader class="mt-0 font-heading">
              <button ngbAccordionButton class="font-size-14 rule-question-titles">
                {{ 'pick.player.rules2.title' | translate }}
              </button>
            </h2>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <div class="row">
                <div class="content col-12 font-size-14 text-black">
                  <div class="fantasy text-black">
                    <div
                      class="font-size-12"
                      [innerHTML]="'pick.player.rules2.description' | translate | markdownTranslate"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div ngbAccordionItem id="ngb-panel-2">
          <div ngbPanelHeader>
            <h2 ngbAccordionHeader class="mt-0 font-heading">
              <button ngbAccordionButton class="font-size-14 rule-question-titles">
                {{ 'pick.player.rules1.title' | translate }}
              </button>
            </h2>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <div class="row">
                <div class="content col-12 font-size-14 text-black">
                  <div class="fantasy text-black">
                    <div
                      class="font-size-12"
                      [innerHTML]="'pick.player.rules1.description' | translate | markdownTranslate"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
