<div class="full-page w-100 d-md-flex d-block px-0">
  <!--Left Side-->
  <div class="left-side-login">
    <img class="shared-login-banner" draggable="false" src="assets/images/login-registration/logo.png" draggable="false"/>
  </div>
  <!--Right Side-->
  <div class="p-lg-4 right-side-login">
    <div class="login-container">
      <div class="d-flex flex-column justify-content-center my-5 py-lg-5 py-0 w-lg-70">
        <span class="material-icons mb-3 text-center font-primary text-primary" [class.corporate-primary-color]="isCorporate"> engineering </span>
        <div class="text-container d-flex flex-column align-items-center">
          <h5 class="text-center maintenance-title font-primary font-size-22" [class.corporate-primary-color]="isCorporate">
            {{ 'maintenancepage.currentlyunavailable.title' | translate }}
          </h5>
          <div
            class="text-center maintenance-description font-size-14"
            [innerHTML]="'maintenancepage.currentlyunavailable.description' | translate | markdownTranslate"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
