import { Pipe, PipeTransform } from '@angular/core';
import { UserContext, config, MemberQuery } from '@fgb/core';

@Pipe({ name: 'contentImage' })
export class ContentImagePipe implements PipeTransform {
  constructor(private memberQuery: MemberQuery) {}

  transform(value: any, variantId: any, loyaltyType?: string) {
    if (!value) {
      if (!loyaltyType) {
        return `${config.contentUrl}/images/noimage.png`;
      } else {
        switch (loyaltyType) {
          case 'marketplace': {
            return 'assets/images/placeholders/marketplace.svg';
          }
          case 'auction': {
            return 'assets/images/placeholders/auctions.svg';
          }
          case 'lotto': {
            return 'assets/images/placeholders/lottos.svg';
          }
          case 'wallet': {
            return 'assets/images/placeholders/marketplace.svg';
          }
          default: {
            return 'assets/images/placeholders/rewards.svg';
          }
        }
      }
    } else {
      let currentUser = this.memberQuery.getValue().user
        || ({ clubId: config.defaultClubId, lcid: config.defaultLcid } as UserContext);

      const imageName = value.substring(0, value.indexOf('.'));
      const fileExtension = value.substring(value.indexOf('.'));

      return `${config.contentUrl}/image/${imageName}__${currentUser.clubId}_${variantId}_${currentUser.lcid}${fileExtension}`;
    }
  }
}
