<nav
  class="navbar navbar-dark navbar-expand-lg d-none d-lg-flex"
  [ngClass]="isCorporate ? 'bg-primary-corporate navbar-corporate' : 'bg-primary navbar-default'"
>
  <a class="navbar-brand py-0" [routerLink]="['/']" routerLinkActive="router-link-active">
    <ng-container *ngIf="!isCorporate && !isJuniorBluebirds"
      ><img class="ms-2 h-100" src="assets/images/logo.svg" draggable="false"
    /></ng-container>
    <ng-container *ngIf="isCorporate"
      ><img class="ms-2 h-100" src="assets/images/corporate-logo.png" draggable="false"
    /></ng-container>
    <ng-container *ngIf="isJuniorBluebirds"
      ><img class="ms-2 h-100" src="assets/images/kids-logo-v2.png" draggable="false"
    /></ng-container>
  </a>

  <button
    class="navbar-toggler"
    type="button"
    (click)="isCollapsed = !isCollapsed"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Left Side Content -->
  <div class="navbar-collapse" [ngbCollapse]="isCollapsed" id="navbarSupportedContent">
    <div class="nav-container">
      <ul class="navbar-nav me-auto" #greedyNav>
        <li class="nav-item active">
          <a class="nav-link mx-1" [routerLink]="['/rewards']" routerLinkActive="router-link-active">{{
            'navbar.rewards' | translate
          }}</a>
        </li>
        <li class="nav-item active">
          <a class="nav-link mx-1" [routerLink]="['/challenges']" routerLinkActive="router-link-active">{{
            'navbar.challenges' | translate
          }}</a>
        </li>
        <li class="nav-item active">
          <a class="nav-link mx-1" [routerLink]="['/games/quiz']" routerLinkActive="router-link-active">{{
            'navbar.games' | translate
          }}</a>
        </li>
        <li class="nav-item active" *notInPopulation="[populations.JuniorBluebirds]">
          <a class="nav-link mx-1" [routerLink]="['/earn']" routerLinkActive="router-link-active">{{
            'navbar.earnpoints' | translate
          }}</a>
        </li>
        <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.savings'">
          <li class="nav-item active" *notInPopulation="[populations.JuniorBluebirds]">
            <a class="nav-link mx-1" [routerLink]="['/savings']" routerLinkActive="router-link-active">{{
              'navbar.saving' | translate
            }}</a>
          </li>
        </ng-container>
        <li class="nav-item active">
          <a class="nav-link mx-1" [routerLink]="['/gallery']" routerLinkActive="router-link-active">{{
            'navbar.gallery' | translate
          }}</a>
        </li>
        <li class="nav-item active" *inPopulation="populations.JuniorBluebirds">
          <a class="nav-link mx-1" [routerLink]="['/worksheet']" routerLinkActive="router-link-active">{{
            'navbar.activities' | translate
          }}</a>
        </li>
        <!-- <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.nav.gallery'">
          <li class="nav-item active" *notInPopulation="[populations.JuniorBluebirds, populations.Free]">
            <a class="nav-link mx-1" [routerLink]="['/gallery']" routerLinkActive="router-link-active">{{
              'navbar.gallery' | translate
            }}</a>
          </li>
        </ng-container> -->
        <li class="nav-item active more-dropdown">
          <div ngbDropdown role="group">
            <a class="nav-link mx-1 text-white" ngbDropdownToggle>
              <span class="me-1">{{ 'nav.help' | translate }}</span>
            </a>
            <div ngbDropdownMenu class="py-2 mt-2 me-4 graphie-regular" #greedyDropdown>
              <a ngbDropdownItem [routerLink]="['/faq']" class="rounded-top ps-2 mb-1 text-black d-block font-weight-normal">
                {{ 'nav.faq' | translate }}
              </a>

              <a ngbDropdownItem [routerLink]="['/contact']" class="ps-2 text-black mb-1 d-block font-weight-normal">
                {{ 'nav.contact' | translate }}
              </a>
              <a ngbDropdownItem [routerLink]="['/terms']" class="ps-2 text-black d-block font-weight-normal">
                {{ 'nav.term' | translate }}
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- Right Side Content -->
    <div class="d-flex align-items-center flex-nowrap my-2 my-lg-0">
      <div class="balance font-size-14 text-white" *notInPopulation="[populations.JuniorBluebirds]">
        <div class="d-flex align-items-center justify-content-end" *ngIf="loyaltyPointsCard$">
          <span class="icon material-icons currency-icons pe-1">emoji_events</span>
          <span class="ps-1 font-sans-bold">{{ loyaltyPointsCard$ | async | FortressCurrencyPipe : 'PTS' }}</span>
        </div>
      </div>
      <fgb-notification-button></fgb-notification-button>
      <!--New Dropdown (Needs Manage Tickets update before being looked at again)-->
      <div class="member" ngbDropdown #ddAccount="ngbDropdown" [autoClose]="'outside'" focusTab>
        <button
          class="nav-link btn text-white d-flex align-items-center mx-2 px-0 justify-content-end"
          id="namedropdown"
          ngbDropdownToggle
          aria-label="Account Menu Dropdown"
          tabindex="-1"
        >
          <div *ngIf="memberDetails$ | async as memberDetails; else memberDetailsLoading" class="user-box h-100 d-inline-block">
            <div class="font-size-14 text-start">{{ memberDetails!.FirstName }}</div>
          </div>
        </button>

        <div
          ngbDropdownMenu
          (click)="closeDropDown(ddAccount)"
          aria-labelledby="namedropdown"
          class="text-center dropdown-menu account-dropdown mt-2"
        >
          <div class="text-black border-bottom">
            <a
              [routerLink]="['/account']"
              routerLinkActive="router-link-active"
              class="d-flex align-items-center account-btn text-black bg-white py-0 px-2 font-size-14 nav-item-fixed-height rounded-top"
              ngbDropdownItem
            >
              <div class="dropdown-link text-start">
                <div class="d-flex">
                  <span class="icon material-icons material-dropdown-icon me-2 text-black">person</span>
                  <div class="font-sans-bold">{{ 'nav.account' | translate }}</div>
                </div>
                <div class="nav-bar-little-text text-start ms-4 ps-2" *notInPopulation="populations.JuniorBluebirds">
                  {{ (card$ | async)?.ProductCode }}
                </div>
                <div class="nav-bar-little-text text-start ms-4 ps-2" *inPopulation="populations.JuniorBluebirds">
                  {{ 'member.card.product.code.kid' | translate }}
                </div>
              </div>
            </a>
          </div>

          <ng-container *ngIf="cards$ | async as cards">
            <div *ngIf="cards.length > 1">
              <div class="text-secondary ms-3">
                <div class="d-flex align-items-center account-btn bg-white py-0 px-2 nav-item-fixed-height" ngbDropdownItem>
                  <div class="icon icon-user-male-pictures me-2 font-size-16 text-primary"></div>
                  <div class="dropdown-link mt-1 text-start">
                    <div class="font-size-14 text-black">{{ 'nav.switch.account' | translate }}</div>
                  </div>
                </div>
              </div>
              <div class="select-account-container">
                <ng-container *ngFor="let card of cards">
                  <hr class="text-muted w-75 my-0" />
                  <div class="text-secondary">
                    <button
                      (click)="loginWithCard(card.MemberId)"
                      class="d-flex align-items-center account-btn text-secondary bg-white py-0 px-2 nav-item-fixed-height switch-accounts"
                      ngbDropdownItem
                      aria-label="Login to alternate account"
                    >
                      <div class="ten-game-club" [ngClass]="{ 'border-0': card.MemberId == selectedMember }">
                        <span class="icon icon-check" *ngIf="card.MemberId == selectedMember"></span>
                      </div>
                      <div class="dropdown-link mt-1 text-start">
                        <div class="font-size-14">{{ card.Name }}</div>
                        <div class="font-size-12 text-info">{{ 'nav.member.id' | translate }} {{ card.MemberId }}</div>
                      </div>
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <div class="text-black border-bottom">
            <a
              [routerLink]="['/rewards/wallet']"
              routerLinkActive="router-link-active"
              class="d-flex align-items-center account-btn text-black bg-white py-0 px-2 nav-item-fixed-height"
              ngbDropdownItem
            >
              <span class="icon material-icons material-dropdown-icon me-2 text-black">account_balance_wallet</span>
              <div class="dropdown-link text-start">
                <div class="font-size-14 font-sans-bold">{{ 'nav.wallet' | translate }}</div>
              </div>
            </a>
          </div>
          <div>
            <button
              class="logout-btn d-flex align-items-center bg-white px-2 nav-item-fixed-height rounded-bottom"
              (click)="logout()"
              ngbDropdownItem
              aria-label="Log out"
            >
              <span class="icon material-icons material-dropdown-icon me-2 text-error">power_settings_new</span>
              <div class="dropdown-link text-start">
                <div class="py-1 font-size-14 font-sans-bold text-error" ngbDropdownItem (click)="logout()">
                  {{ 'nav.log.out' | translate }}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>

<ng-template #memberDetailsLoading>
  <div class="user-box d-block text-center text-on-primary px-4">
    <fgb-loading [size]="'small'"></fgb-loading>
  </div>
</ng-template>
