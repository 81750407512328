import { Component, Input, OnInit } from '@angular/core';
import { PopulationQuery } from '@fgb/core';
import { Populations } from '../../utilities/populations';

@Component({
  selector: 'fgb-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  @Input() title: string = '';
  populations = Populations;
  isCorporate: boolean;
  constructor(private populationQuery: PopulationQuery) {}

  ngOnInit() {
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
  }
}
