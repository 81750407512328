import { Component, OnInit } from '@angular/core';
import { BalanceQuery, config, PopulationQuery } from '@fgb/core';
import { Observable } from 'rxjs';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-balance-bar',
  templateUrl: './balance-bar.component.html',
  styleUrls: ['./balance-bar.component.scss'],
})
export class BalanceBarComponent implements OnInit {
  points$: Observable<number | undefined>;
  eCash$: Observable<number | undefined>;
  populations = Populations;
  isCorporate: boolean;
  constructor(private balanceQuery: BalanceQuery, private populationQuery: PopulationQuery) {}

  ngOnInit() {
    this.points$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.eCash$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
  }
}
