import { Component, OnInit, Input } from '@angular/core';
import { Survey, SurveyAnswer } from '@fgb/core';
import { PopulationQuery } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-survey-completed-text',
  templateUrl: './survey-completed-quiz-text.component.html',
  styleUrls: ['./survey-completed-quiz-text.component.scss', '../../../../survey.scss']
})
export class SurveyCompletedQuizTextComponent implements OnInit {
  @Input() survey: Survey;
  @Input() selectedAnswers: SurveyAnswer[];
  @Input() correctAnswer: SurveyAnswer;
  @Input() hasSelectedCorrect: boolean;
  @Input() pointsWon: number;
  
  isJuniorBluebirds: boolean;
  populations = Populations;

  constructor(private populationQuery: PopulationQuery) {}

  ngOnInit() {
    this.isJuniorBluebirds = this.populationQuery.userInPopulation([Populations.JuniorBluebirds]);
  }

}
