import { Component, OnInit } from '@angular/core';
import { PopulationQuery, ProcessStatus, SurveyQuery } from '@fgb/core';
import { Survey } from '@fgb/core';
import { Observable } from 'rxjs';
import { SurveyType } from '@fgb/core';
import { map } from 'rxjs/operators';
import { Populations } from 'src/app/shared/utilities/populations';
@Component({
  selector: 'fgb-predictor-page',
  templateUrl: './predictor-page.component.html',
  styleUrls: ['./predictor-page.component.scss'],
})
export class PredictorPageComponent implements OnInit {
  predictors$: Observable<Survey[]>;
  scorePredictors$: Observable<Survey[]>;
  populations = Populations;
  isCorporate: boolean;
  constructor(private surveyQuery: SurveyQuery, private populationQuery: PopulationQuery) {}

  ngOnInit() {
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
    this.predictors$ = this.surveyQuery.selectRelevantSurveys(SurveyType.Predictor).pipe(
      map((predictors) => {
        return predictors.filter(
          (predictor) =>
            predictor.ProcessStatus !== ProcessStatus.Processed ||
            (predictor.ProcessStatus == ProcessStatus.Processed && predictor.HasBeenAnsweredByUser)
        );
      })
    );
  }
}
