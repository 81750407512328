<nav class="nav nav-secondary badges-nav row">
  <!--Account-->
  <a
    class="nav-item first-item col-sm-6"
    [ngClass]="isCorporate ? 'corporate' : 'membership'"
    [routerLink]="['/challenges']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.challenges.screenreader' | translate : { fgbdefault: 'Move to challenges page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">flag</div>
    <label>{{ 'challenges.nav.badge' | translate }}</label>
  </a>
  <a
    class="nav-item second-item col-sm-6"
    [ngClass]="isCorporate ? 'corporate' : 'membership'"
    [routerLink]="['/badges']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.badges.screenreader' | translate : { fgbdefault: 'Move to badges page' } }}"
  >
    <div class="icon material-icons" aria-hidden="true">local_police</div>
    <label>{{ 'badges.nav.badge' | translate }}</label>
  </a>
</nav>
