<fgb-banner title="Auctions" class="d-lg-flex d-none"></fgb-banner>

<div class="container py-2 d-block d-lg-none">
  <fgb-member-card></fgb-member-card>
</div>

<fgb-mixed-loyalty-nav></fgb-mixed-loyalty-nav>

<div class="container mt-4" [ngClass]="isCorporate ? 'corporate-colors' : 'default-color'">
  <fgbcl-auction-list [defaultSort]="0"></fgbcl-auction-list>
</div>
