<div class="px-3">
  <div class="predicition-score font-size-14 font-weight-bold">The correct score was {{ homeScore }} - {{ awayScore }}</div>
  <hr class="border border-mute my-2" />
  <!--Correct Heading-->
  <div class="score-prediction pb-4 pt-lg-0 pt-1" *ngIf="hasSelectedCorrect">
    <div class="text-success font-weight-bold font-size-14">{{ 'score.predictor.win.title' | translate }}</div>
    <div class="font-size-12 px-2 pb-1 pb-lg-0" [innerHTML]="'score.predictor.win' | translate | markdownTranslate">
      {{ survey.PointValue }} points!
    </div>
  </div>
  <!--Incorrect Heading-->
  <div class="score-prediction pb-4 pt-lg-0 pt-1" *ngIf="!hasSelectedCorrect">
    <div class="text-danger font-weight-bold font-size-14">{{ 'score.predictor.no.win.title' | translate }}</div>
    <div class="font-size-12 px-2 pb-1 pb-lg-0" [innerHTML]="'score.predictor.no.win' | translate | markdownTranslate"></div>
  </div>
</div>
