<div class="container">
  <h2 class="featured-offers-title">{{ 'card.link.offers.featured.title' | translate }}</h2>
  <fgbcl-card-linking-featured-offers [numberOfOffers]="3"> </fgbcl-card-linking-featured-offers>
</div>

<!--Featured Partners Section-->
<fgbcl-card-link-featured-brands [Title]="'card.linking.featured.brands.title'"></fgbcl-card-link-featured-brands>

<div class="container all-offers-steps">
  <fgbcl-card-link-earn-steps
    [cardTitle]="'card.link.easy.steps.title'"
    [stepOneIcon]="'ads_click'"
    [stepTwoIcon]="'shopping_cart'"
    [stepThreeIcon]="'star'"
    [stepOneText]="'earn.affilaites.step1'"
    [stepOneSmallPrintText]="'payment.description.step.one.small.print'"
    [stepTwoText]="'earn.affilaites.step2'"
    [stepTwoSmallPrintText]="'payment.description.step.two.small.print'"
    [stepThreeText]="'earn.affilaites.step3'"
    [stepThreeSmallPrintText]="'payment.description.step.three.small.print'"
    [horizontalLine]="true"
    [filled]="true"
    [border]="false"
  >
  </fgbcl-card-link-earn-steps>
</div>

<ng-container *ifScreenSize="screenType.Desktop">
  <div class="container mb-3">
    <div class="affiliate-tool-bar p-3">
      <div class="tool-bar-items py-2">
        <fgbcl-affiliates-offers-category-filter
          [navButtons]="true"
          (categoryTagIdEmitter)="categorytagID = $event; getOffers()"
        ></fgbcl-affiliates-offers-category-filter>

        <fgbcl-shared-search-bar (searchTextEmitter)="searchText = $event; getOffers()"></fgbcl-shared-search-bar>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ifScreenSize="screenType.Mobile">
  <div class="container">
    <div class="affiliate-tool-bar py-4">
      <div class="mx-2 mb-4">
        <fgbcl-shared-search-bar (searchTextEmitter)="searchText = $event; getOffers()"></fgbcl-shared-search-bar>
      </div>
      <fgbcl-affiliates-offers-category-filter
        (categoryTagIdEmitter)="categorytagID = $event; getOffers()"
      ></fgbcl-affiliates-offers-category-filter>
    </div>
  </div>
</ng-container>

<div class="container">
  <hr class="horizontal-line" *ifScreenSize="screenType.Mobile" />
  <fgbcl-card-link-offers-list
    [cardLinkOfferOptions]="cardLinkOfferOptions"
    [horizontalLine]="false"
  ></fgbcl-card-link-offers-list>
</div>

<ng-template #noOffers>
  <div class="text-center no-offer-block">
    <span class="icon material-icons noOffer-icon" aria-hidden="true"> manage_search </span>
    <p class="font-weight-bold font-size-20 text-white">{{ 'card.link.no.search.offer.text' | translate }}</p>
    <p>{{ 'card.link.no.search.offer.text.suggestion' | translate }}</p>
  </div>
</ng-template>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
