import { Component, OnInit, OnDestroy } from '@angular/core';
import { FantasyGameQuery, FantasyGame, FantasyLeaderboard, PopulationQuery } from '@fgb/core';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import * as _moment from 'moment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';
import { Populations } from 'src/app/shared/utilities/populations';
import { FantasyService, MonthlyLeaderboard } from 'src/app/modules/fantasy/fantasy-leaderboard.service';

const moment = _moment;

@Component({
  selector: 'fgb-fantasy-games-page',
  templateUrl: './fantasy-games-page.component.html',
  styleUrls: ['./fantasy-games-page.component.scss'],
})
export class FantasyGamesPageComponent implements OnInit, OnDestroy {
  currentGames$: Observable<FantasyGame[]>;
  pendingGames$: Observable<FantasyGame[]>;
  pendingPeriod: number = 48;

  yearlyLeaderboard$: Observable<FantasyLeaderboard[]>;
  monthlyLeaderboard$: Observable<MonthlyLeaderboard[]>;

  showMonthly: boolean = true;
  year: number = new Date().getFullYear();

  populations = Populations;
  isCorporate: boolean;

  customOptions: OwlOptions = {
    items: 1,
    margin: OwlCarouselSettings.Margin,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    navSpeed: 700,
    center: false,
    nav: true,
    dots: false,
    startPosition: this.fantasyService.monthSlideIndex,
    navText: [
      '<span class="material-icons material-symbols-outlined">chevron_left</span>',
      '<span class="material-icons material-symbols-outlined">chevron_right</span>',
    ],
    stagePadding: 0,
  };

  customOptions2: OwlOptions = {
    items: 1,
    autoHeight: true,
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    autoplay: false,
    navSpeed: 2000,
    responsive: {
      0: { items: 1, autoWidth: false },
      740: { items: 1, autoWidth: true, autoHeight: false },
    },
  };

  fantasySub: Subscription;

  constructor(
    private fantasyQuery: FantasyGameQuery,
    private router: Router,
    private fantasyService: FantasyService,
    private populationQuery: PopulationQuery
  ) {}

  ngOnInit() {
    this.currentGames$ = this.fantasyQuery.selectCurrentGames();
    this.pendingGames$ = this.fantasyQuery.selectFinshedFantasyGamesAfterDate(moment.utc().subtract(this.pendingPeriod, 'hours'));
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);

    this.yearlyLeaderboard$ = this.fantasyService.getYearlyLeaderboard(3, true);
    this.monthlyLeaderboard$ = this.fantasyService.getMonthlyLeaderboard(3, true);
  }

  ngOnDestroy() {
    if (this.fantasySub) {
      this.fantasySub.unsubscribe();
    }
  }

  goToDetail(gameId: number) {
    this.router.navigate([`games/pickaplayer/${gameId}`]);
  }

  hasActivePlayer(game: FantasyGame) {
    return game.GamePlayers.some((g) => g.IsSelected);
  }
}
