<div *ifScreenSize="screenType.Desktop">
  <fgb-banner [title]="'payment.page.banner' | translate"></fgb-banner>
</div>

<div [ngClass]="{ 'hide-container-for-mobile': currentTab != 'payment' }">
  <div class="container my-4" *ifScreenSize="screenType.Mobile">
    <fgb-barcode></fgb-barcode>
  </div>
  <fgb-account-nav></fgb-account-nav>
  <!--How It work section-->
  <div class="container d-flex flex-column d-lg-block">
    <div class="d-block order-2 order-lg-1">
      <hr class="d-flex d-lg-none ecash-hr mt-4">
      <fgbcl-ecash-description [googleIconName2]="'emoji_events'" [googleIconName3]="'star'"> </fgbcl-ecash-description>
    </div>
    <!--My Card Section-->
    <div class="order-1 order-lg-2">
      <h3 class="card-link-add-card-title mb-0 mb-lg-3 mt-3 mt-lg-0">{{ 'card.linking.cards.title' | translate }}</h3>
      <div class="row d-flex flex-lg-row flex-column">
        <div class="col-lg-2 col-12 mb-3 mb-lg-0">
          <div class="d-lg-block d-none">
            <fgbcl-card-link-button [cardLinkingSettings]="cardLinkingSettings"></fgbcl-card-link-button>
          </div>
        </div>
        <div class="col-lg-10 ps-1 ms-lg-0 ms-1 col-12">
          <ng-container *ngIf="linkedCardList$ | async as cards">
            <div class="mb-3">
              <fgbcl-card-linked-list [linkedCardList]="cards"></fgbcl-card-linked-list>
            </div>
          </ng-container>
        </div>
        <div class="col-12">
          <div class="d-lg-none d-block">
            <fgbcl-card-link-button [cardLinkingSettings]="cardLinkingSettings"></fgbcl-card-link-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Transaction Work -->
<div class="container mt-4">
  <!-- <div
    class="mb-3 info-card font-size-16 rounded text-white border-none bg-tertiary text-center d-flex justify-content-center align-items-center fw-bold"
  >
    <div class="transaction-info">{{ 'payment.transaction.info' | translate }}</div>
  </div> -->
  <hr class="d-lg-flex d-none transaction-hr">
  <h2 class="transaction-history-title d-flex d-flex-row align-items-center justify-content-start font-size-20">
    <span class="icon material-icons">history</span>
    {{ 'card.linking.transaction.history.title' | translate }}
  </h2>
  <div class="transaction-info mb-3 font-size-16 fw-bold">{{ 'payment.transaction.info' | translate }}</div>
  <fgbcl-card-linked-transactions-list [memberId]="memberId" [dateFormat]="dateFormat"> </fgbcl-card-linked-transactions-list>
</div>
