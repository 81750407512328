<div class="container px-0">
  <ng-container *ngIf="cardLinkOfferDetail$ | async as cardLinkOfferDetails">
    <fgbcl-card-link-offer-details
      [cardLinkOfferDtail]="cardLinkOfferDetails"
      [clubImageUrl]="'assets/images/logo.svg'"
    ></fgbcl-card-link-offer-details>
  </ng-container>
</div>
<div class="container">
  <fgbcl-card-link-offers-list
    [cardLinkOfferOptions]="cardLinkOfferOptionsOtherOffers"
    [labelName]="'card.linking.other.offer.label'"
  ></fgbcl-card-link-offers-list>
  <fgbcl-card-link-offers-list
    [cardLinkOfferOptions]="cardLinkOfferOptionsRecommendedOffers"
    [labelName]="'card.linking.offer.you.may.like.label'"
  ></fgbcl-card-link-offers-list>
  <div class="mt-3">
    <a class="error-color font-weight-bold d-flex align-item-center font-size-14" [routerLink]="'/affiliates'">
      <span class="icon material-icons" aria-hidden="true">arrow_back_ios</span>
      {{ 'card.linking.offer.detail.back.label' | translate }}
    </a>
  </div>
</div>
