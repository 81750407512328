<div class="answer-container d-flex flex-row" [ngClass]="{ selected: answer.UserSelected }">
  <!-- Hide Image -->
  <ng-container *ngIf="displayType === 0">
    <img
      *ngIf="answer.ImageURL"
      class="image-container icon"
      src="{{ answer.ImageURL | contentImage: '7' }}"
      alt="{{ answer.ImageURL }}"
    />
  </ng-container>
  <div class="answer-text-wrapper w-100 progress" [ngClass]="displayType !== 0 ? 'no-img' : ''">
    <span class="poll-percentage">{{ percentage + '%' }}</span>
    <div class="answer-text-landscape text-end">
      {{ answer.Title }}
    </div>
    <div class="answer-text progress-bar text-start font-weight-bold w-100 h-100">
      <ngb-progressbar [value]="percentage"></ngb-progressbar>
    </div>
  </div>
  <div class="answer-text-bg"></div>
</div>
