import { Component, Input, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { Worksheet, WorksheetQuery, WorksheetService } from '@fgb/core';
import { firstValueFrom, Observable } from 'rxjs';

@Component({
  selector: 'fgb-worksheet-list',
  templateUrl: './worksheet-list.component.html',
  styleUrls: ['./worksheet-list.component.scss'],
})
export class WorksheetListComponent implements OnInit {
  @Input() colOverride: string = '';
  isList: boolean = true;
  worksheets$: Observable<Worksheet[]>;

  constructor(private worksheetService: WorksheetService, private worksheetQuery: WorksheetQuery, private router: Router) {}

  ngOnInit() {
    const currentUrl = this.router.url.replace('/', '').split('?')[0];

    this.worksheets$ = this.worksheetQuery.getWorksheetByRoute(currentUrl);

    if (!this.worksheetQuery.hasRoute(currentUrl)) {
      firstValueFrom(this.worksheetService.fetchWorksheetByRoute(currentUrl));
    }
  }
}
