import { Component, OnDestroy, OnInit } from '@angular/core';
import { CardLinkingQuery, CardLinkingService, CardLinkOffersQuery } from '@fgb/core';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fgb-card-link-affiliates-page',
  templateUrl: './card-link-affiliates-page.component.html',
  styleUrls: ['./card-link-affiliates-page.component.scss'],
})
export class CardLinkAffiliatesPageComponent implements OnInit, OnDestroy {
  activatedOffersCount$: Observable<number>;
  cardCount: number;
  private _destroyed$ = new Subject<void>();
  constructor(
    private cardLinkOffersQuery: CardLinkOffersQuery,
    private cardLinkingServices: CardLinkingService,
    private cardLinkingQuery: CardLinkingQuery
  ) {}

  ngOnInit(): void {
    firstValueFrom(this.cardLinkingServices.fetchFidelLinkedCard());
    this.activatedOffersCount$ = this.cardLinkOffersQuery.selectActivatedOffersCount();
    this.cardLinkingQuery
      .selectCount()
      .pipe(takeUntil(this._destroyed$))
      .subscribe((res) => {
        this.cardCount = res;
      });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }
}
