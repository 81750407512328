<div class="d-block px-3">
  <div *ngIf="!canEdit" class="score-prediction pt-4">
    <hr class="border border-mute my-2" />
    <div class="text-primary font-weight-bold font-size-14">Good Luck!</div>
    <div class="font-size-12 text-black pb-1 pb-lg-0">
      Your prediction has been submitted <br />
      Come back after the match to view your results
    </div>
  </div>
  <div *ngIf="canEdit" class="score-prediction">
    <div class="d-flex justify-content-center pt-2 pt-lg-0">
      <button class="btn btn-primary font-weight-bold mb-2 mt-lg-0" (click)="editAnswer.emit()">Change</button>
    </div>
  </div>
</div>
