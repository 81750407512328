import { Component, OnInit } from '@angular/core';
import { NotificationCenterQuery, NotificationCenterService, PopulationQuery } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss'],
})
export class NotificationButtonComponent implements OnInit {
  unseenNotificationCount$: Observable<number>;
  isOpen: boolean;
  screenSize = ScreenType;
  isJuniorBluebirds: boolean;

  constructor(private notificationService: NotificationCenterService, private notificationQuery: NotificationCenterQuery, private populationQuery: PopulationQuery) {}

  ngOnInit(): void {
    this.isJuniorBluebirds = this.populationQuery.userInPopulation([Populations.JuniorBluebirds]);
    this.unseenNotificationCount$ = this.notificationQuery.unseenNotificationsCount$;
  }

  toggleNotificationCenter(open: boolean) {
    this.isOpen = open;
    this.notificationService.updateIsOpen(open);
  }

  closeDropDown(dropdown: NgbDropdown) {
    dropdown.close();
  }
}
