import { Component, OnInit, Input } from '@angular/core';
import { FantasyGame } from '@fgb/core';
import * as _moment from 'moment';
const moment = _moment;

@Component({
  selector: 'fgb-fantasy-game-panel-next-game',
  templateUrl: './fantasy-game-panel-next-game.component.html',
  styleUrls: ['./fantasy-game-panel-next-game.component.scss'],
})
export class FantasyGamePanelNextGameComponent implements OnInit {
  @Input() game: FantasyGame;
  gameDate: string;
  gameTime: string;
  constructor() {}

  ngOnInit() {
    this.gameDate = moment.utc(this.game.GameDate).local().format('DD MMM');
    this.gameTime = moment.utc(this.game.GameDate).local().format('HH:mm');
  }
}
