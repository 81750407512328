import * as _moment from 'moment';
import { Pipe, PipeTransform } from '@angular/core';
const moment = _moment;

@Pipe({ name: 'UtcToLocaltimePipe' })
export class UtcToLocaltimePipe implements PipeTransform {
  transform(value: any, format: string = 'MMMM D, YYYY hh:mm a') {
    if (value) {
      return moment.utc(value).local().format(format);
    }
    return value;
  }
}
