<div class="card">
  <div class="card-body">
    <h3>Download Mobile Pass</h3>
    <p>Please scan the QR code using your phone to download your pass</p>
    <qrcode
      *ngIf="url"
      [qrdata]="url"
      [width]="256"
      [errorCorrectionLevel]="'M'"
      [elementType]="'svg'"
    ></qrcode>
  </div>
</div>
