import { Component, OnInit } from '@angular/core';
import { PopulationQuery, Survey, SurveyQuery } from '@fgb/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable } from 'rxjs';
import { OwlCarouselSettings } from 'src/app/shared/utilities/owl-carousel-settings';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-score-predictor-page',
  templateUrl: './score-predictor-page.component.html',
  styleUrls: ['./score-predictor-page.component.scss'],
})
export class ScorePredictorPageComponent implements OnInit {
  scorePredictors$: Observable<Survey[]>;
  isCorporate: boolean;
  populations = Populations;
  constructor(private surveyQuery: SurveyQuery, private populationQuery: PopulationQuery) {}

  customOptions: OwlOptions = {
    items: 1,
    autoHeight: true,
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    autoplay: false,
    navSpeed: 2000,
    responsive: {
      0: { autoWidth: true },
      740: { autoWidth: false, autoHeight: false },
    },
  };

  customOptions2: OwlOptions = {
    items: 1,
    autoHeight: true,
    autoWidth: true,
    margin: OwlCarouselSettings.Margin,
    autoplay: false,
    navSpeed: 2000,
    responsive: {
      0: { autoWidth: false },
      740: { autoWidth: true, autoHeight: false },
    },
  };

  ngOnInit() {
    this.scorePredictors$ = this.surveyQuery.selectScorePredictors();
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
  }
}
