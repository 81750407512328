import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fgb-loading',
  templateUrl: 'loading.component.html'
})
export class LoadingComponent implements OnInit {
  @Input() size: 'small' | 'medium' = 'medium';

  ngOnInit() {}
}
