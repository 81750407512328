import { Component, OnInit, Input } from '@angular/core';
import {
  MemberCard,
  MemberCardQuery,
  MemberDetails,
  Balance,
  MemberQuery,
  BalanceQuery,
  config,
  PopulationQuery,
} from '@fgb/core';
import { Observable } from 'rxjs';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-member-card',
  templateUrl: './member-card.component.html',
  styleUrls: ['./member-card.component.scss'],
})
export class MemberCardComponent implements OnInit {
  @Input() showSeating: boolean;
  memberCard$: Observable<MemberCard | undefined>;
  ecash$: Observable<number | undefined>;
  memberDetails$: Observable<MemberDetails | undefined>;
  balance$: Observable<Balance>;
  member$: Observable<MemberDetails | undefined>;
  pointsBalance$: Observable<number | undefined>;
  loyaltyPointsCard$: Observable<number | undefined>;
  populations = Populations;
  isJuniorBluebirds: boolean;
  isCorporate: boolean;
  isFree: boolean;
  constructor(
    private memberCardQuery: MemberCardQuery,
    private memberQuery: MemberQuery,
    private balanceQuery: BalanceQuery,
    private populationQuery: PopulationQuery
  ) {}

  ngOnInit() {
    this.memberCard$ = this.memberCardQuery.selectCurrentCard();
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.pointsBalance$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.ecash$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.loyaltyPointsCard$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.isJuniorBluebirds = this.populationQuery.userInPopulation([Populations.JuniorBluebirds]);
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
    this.isFree = this.populationQuery.userInPopulation([this.populations.Free]);
  }
}
