<div class="card">
  <div class="card-content px-3">
    <div class="pt-2 d-flex justify-content-center">
      <div class="icon material-icons font-size-20 me-2 mt-icon" [ngClass]="isCorporate ? 'text-primary-corporate' : 'text-primary'">desktop_windows</div>
      <h3 class="mb-lg-0 mb-3 text-center" [ngClass]="isCorporate ? 'text-primary-corporate' : 'text-primary'">VOUCHER CODE</h3>
    </div>
    <div>
      <span class="input-group mt-lg-1">
        <input
          onfocus="this.placeholder = ''"
          onblur="this.placeholder = 'INSERT CODE HERE'"
          type="text"
          [(ngModel)]="voucherCode"
          placeholder="INSERT CODE HERE"
          class="form-control text-center border-0 px-5 px-lg-0"
          (keyup.enter)="redeemVoucher()"
        />
        <div class="input-group-append">
          <button
            class="btn btn-primary icon icon-arrow-1-right text-white font-weight-bold p-0 d-flex align-items-center"
            [ngClass]="isCorporate ? 'btn-primary-corporate' : 'btn-primary'"
            type="button"
            (click)="redeemVoucher()"
            [disabled]="disabled"
          >
            <span class="arrow-right material-icons ms-auto"> chevron_right </span>
          </button>
        </div>
      </span>
      <div class="result-box">
        <div class="result mt-0">
          <fgbcl-errors></fgbcl-errors>
          <div class="font-size-14 text-center" *ngIf="redemptionResult !== successMessage">
            {{ redemptionResult }}
          </div>
          <div class="font-size-14 text-success text-center" *ngIf="redemptionResult == successMessage">
            {{ redemptionResult }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
