<div class="login-page">
  <div class="login-banner">
    <div class="login-header container">
      <div class="login-header-title">
        {{ 'login.header.title' | translate }}
      </div>
      <div class="login-header-description">
        {{ 'login.header.description' | translate }}
      </div>
      <div class="login-header-btn">
        <fgb-sports-alliance-user-login></fgb-sports-alliance-user-login>
      </div>
    </div>
  </div>
  <div class="container login-earn-points mb-30">
    <div class="login-earn-points-title">
      <span class="material-icons"> emoji_events </span>
      <span class="text">{{ 'login.earn.points.title' | translate }}</span>
    </div>
    <div class="login-earn-points-description">
      {{ 'login.earn.points.description' | translate }}
    </div>
    <div class="login-earn-points-icon-blocks row">
      <div class="icon-block col-12 ps-0 col-lg-4">
        <div class="row w-100">
          <div class="col-3 img">
            <img src="assets/images/login/attend.svg" width="68" alt="attend" />
          </div>
          <div class="text col-8 ms-lg-1">
            <div class="title">{{ 'login.earn.points.icon.block.title.attend' | translate }}</div>
            <div class="description">{{ 'login.earn.points.icon.block.description.attend' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="icon-block col-12 ps-0 col-lg-4">
        <div class="row w-100">
          <div class="col-3 img">
            <img src="assets/images/login/engage.svg" width="68" alt="engage" />
          </div>
          <div class="text col-8 ms-lg-1">
            <div class="title">{{ 'login.earn.points.icon.block.title.engage' | translate }}</div>
            <div class="description">{{ 'login.earn.points.icon.block.description.engage' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="icon-block col-12 ps-0 col-lg-4">
        <div class="row w-100">
          <div class="col-3 img">
            <img src="assets/images/login/spend.svg" width="68" alt="spend" />
          </div>
          <div class="text col-8 ms-lg-1">
            <div class="title">{{ 'login.earn.points.icon.block.title.spend' | translate }}</div>
            <div class="description">{{ 'login.earn.points.icon.block.description.spend' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container login-redeem mb-40">
    <div class="login-redeem-title">
      <span class="material-icons"> redeem </span>
      <span class="text">{{ 'login.redeem.title' | translate }}</span>
    </div>
    <div class="login-redeem-description mb-40">
      {{ 'login.redeem.description' | translate }}
    </div>
    <div class="login-redeem-icon-blocks row">
      <div class="icon-block col-12 ps-0 col-lg-4">
        <div class="row w-100">
          <div class="col-3 img">
            <img src="assets/images/login/experiences.svg" width="68" alt="experiences" />
          </div>
          <div class="text col-8 ms-lg-1">
            <div class="title">{{ 'login.redeem.icon.block.title.experiences' | translate }}</div>
            <div class="description">{{ 'login.redeem.icon.block.description.experiences' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="icon-block col-12 ps-0 col-lg-4">
        <div class="row w-100">
          <div class="col-3 img">
            <img src="assets/images/login/prize_draws.svg" width="68" alt="prize-draws" />
          </div>
          <div class="text col-8 ms-lg-1">
            <div class="title">{{ 'login.redeem.icon.block.title.prize.draws' | translate }}</div>
            <div class="description">{{ 'login.redeem.icon.block.description.prize.draws' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="icon-block col-12 ps-0 col-lg-4">
        <div class="row w-100">
          <div class="col-3 img">
            <img src="assets/images/login/memorabilia.svg" width="68" alt="memorabilia" />
          </div>
          <div class="text col-8 ms-lg-1">
            <div class="title">{{ 'login.redeem.icon.block.title.memorabilia' | translate }}</div>
            <div class="description">{{ 'login.redeem.icon.block.description.memorabilia' | translate }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container login-join-us mb-40">
    <div class="login-join-us-title">
      <span class="material-icons"> credit_card </span>
      <span class="text">{{ 'login.join.us.title' | translate }}</span>
    </div>
    <div class="login-join-us-description mb-40">
      {{ 'login.join.us.description' | translate }}
    </div>
    <div class="login-join-us-images row align-items-center">
      <div class="col-12 col-lg-4">
        <a href="{{ 'season.ticket.link' | translate }}" target="_blank" rel="noopener noreferrer">
          <img src="assets/images/login/ssticket.png" height="172" />
        </a>
      </div>
      <div class="col-12 col-lg-4">
        <a href="{{ 'membership.link' | translate }}" target="_blank" rel="noopener noreferrer">
          <img src="assets/images/login/125-ticket.png" height="172" />
        </a>
      </div>
      <div class="col-12 col-lg-4">
        <a href="{{ 'season.ticket.link' | translate }}" target="_blank" rel="noopener noreferrer">
          <img class="w-100" src="assets/images/login/hospitality-ticket-v2.png" height="172" />
        </a>
      </div>
      <div class="br">

      </div>
    </div>
  </div>
  <div class="container login-how-to-buy mb-40">
    <div class="login-how-to-buy-title mb-40">{{ 'login.how.to.buy.title' | translate }}</div>
    <div class="login-how-to-buy-ways row m-0">
      <div class="buy-online col-lg-3 col-6">
        <img src="assets/images/login/buy-oneline.png" width="103" alt="buy-oneline" />
        <button class="btn btn-primary">{{ 'login.buy.oneline.btn' | translate }}</button>
      </div>
      <div class="buy-phone col-lg-3 col-6">
        <img src="assets/images/login/phone.png" width="82" alt="phone" />
        <div class="text">
          {{ 'login.buy.phone' | translate }}
        </div>
        <div class="text">
          {{ 'login.buy.phone.number' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="container login-help pb-5">
    <div class="login-help-title">{{ 'login.help.title' | translate }}</div>
    <div class="login-help-subtitle">{{ 'login.help.subtitle' | translate }}</div>
    <div class="login-help-links">
      <div class="link">
        <span class="email"> {{ 'login.help.rewards.link' | translate }}</span>
        <span class="d-inline-block mx-1"> - </span>
        <span>{{ 'login.help.rewards.description' | translate }}</span>
      </div>
      <div class="link">
        <span class="email"> {{ 'login.help.tickets.link' | translate }}</span>
        <span class="d-inline-block mx-1"> - </span>
        <span>{{ 'login.help.tickets.description' | translate }}</span>
      </div>
      <div class="link">
        <span class="email"> {{ 'login.help.sales.link' | translate }}</span>
        <span class="d-inline-block mx-1"> - </span>
        <span>{{ 'login.help.sales.description' | translate }}</span>
      </div>
      <div class="link">
        <span class="email"> {{ 'login.help.questies.link' | translate }}</span>
        <span class="d-inline-block mx-1"> - </span>
        <span>{{ 'login.help.questies.description' | translate }}</span>
      </div>
    </div>
  </div>
</div>
