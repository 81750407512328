import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FantasyLeaderboard, PopulationQuery } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';
@Component({
  selector: 'fgb-fantasy-leaderboard',
  templateUrl: './fantasy-leaderboard.component.html',
  styleUrls: ['./fantasy-leaderboard.component.scss'],
})
export class FantasyLeaderboardComponent implements OnInit {
  @Input() fantasyEntries: FantasyLeaderboard[];
  maximumPosition: number
    
  populations = Populations;
  isCorporate: boolean;
  constructor( private populationQuery: PopulationQuery, private route: ActivatedRoute ) {}
  ngOnInit() {
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
    this.route.url.subscribe((url) => {
      const currentRoute = url.length > 1 ? url[1].path : url[0].path
      if (currentRoute === 'pickaplayer') {
        this.maximumPosition = 3
      } else {
        this.maximumPosition = 10
      }
    })
  }
}
