<div class="survey-container rounded">
  <div class="survey-header text-center">
    <div *ngIf="survey.ImageURL" class="image-container-polls w-100 overflow-hidden position-relative bg-white rounded-top">
      <img class="h-100 w-100" src="{{ survey.ImageURL | contentImage: '1' }}" alt="{{ survey.ImageURL }}" />
    </div>
    <div class="text-center mb-3 pt-3">
      <h3 class="mb-lg-3 font-size-20" [ngClass]="isCorporate ? 'text-primary-corporate' : 'text-primary'">{{ 'poll.title' | translate }}</h3>
    </div>
    <div class="survey-info">
      <div class="row">
        <div class="d-flex align-items-center col-lg-2 col-6">
          <div class="gIcon-polls material-icons inline-block my-0 font-size-20" [ngClass]="isCorporate ? 'text-primary-corporate' : 'text-primary'">assessment</div>
        </div>
        <div class="col-lg-8 d-none d-lg-flex justify-content-center">
          <div class="px-3 d-none d-lg-block text-black font-size-14">{{ survey.SurveyQuestion }}</div>
          <div
            class="text-center font-weight-bold text-white font-size-16"
            [ngClass]="pointsWon > 0 ? 'd-block d-lg-none' : 'd-none'"
          >
            +{{ pointsWon }} {{ 'poll.points' | translate }}
          </div>
        </div>
        <div class="text-end text-danger col-6 col-lg-2 d-flex align-items-center justify-content-end" *ngIf="!survey.HasBeenAnsweredByUser">
          <span class="icon material-icons font-size-20 pe-1">schedule</span>

          <fgbcl-countdown
            [endDateTime]="survey.EndDate"
            [isUTC]="true"
            class="font-size-14 font-weight-bold"
          ></fgbcl-countdown>
        </div>
      </div>
      <div class="d-block d-lg-none mt-3 text-start text-black font-size-14">{{ survey.SurveyQuestion }}</div>
    </div>
  </div>
  <ng-container *ngIf="survey.HasBeenAnsweredByUser; else answer">
    <fgb-survey-item-answer-completed-poll [survey]="survey" [pointsWon]="pointsWon"></fgb-survey-item-answer-completed-poll>
  </ng-container>
  <ng-template #answer>
    <fgb-survey-item-answer [survey]="survey"></fgb-survey-item-answer>
  </ng-template>
</div>
