<div class="container d-flex align-items-center">
  <div class="card shadow border-bottom-0 mx-auto">
    <div class="card-body text-center py-4">
      <div class="money-icon icon svg svg-success mb-3 mx-auto" [inlineSVG]="'assets/images/icons/money-bag.svg'"></div>
      <h3 class="card-heading text-success">Load Successful</h3>

      <div *ngIf="autoTopUp; else topup">
        <div class="mt-4 sub-heading-modal">
          <div class="fw-bold">
            Thank you for topping up
            <span class="text-success fw-bold">{{ amount / 100 | FortressCurrencyPipe: 'USD' }}</span> to your account.
          </div>
          <div class="text-info mt-3">
            Your eCash balance will be updated automatically after your balance falls below the minimum threshold.
          </div>
        </div>
      </div>
      <ng-template #topup>
        <div class="mt-4 sub-heading-modal">
          <div class="fw-bold">
            Thank you for topping up
            <span class="text-success fw-bold">{{ amount / 100 | FortressCurrencyPipe: 'USD' }}</span> to your account.
          </div>
          <div class="text-info mt-3">Your eCash balance will be updated shortly</div>
        </div>
      </ng-template>

      <div class="btn-container mt-4 d-block">
        <button [routerLink]="['/ecash']" type="button" class="btn btn-success mx-2 mt-4 btn-text fw-bold">
          Back to eCash
        </button>
      </div>
    </div>
  </div>
</div>
