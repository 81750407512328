<ng-container *ngIf="memberCard$ | async as memberCard; else loadingRef">
  <div class="member-card text-white" [ngClass]="{
      'bg-primary-corporate': isCorporate,
      'bg-primary': !isCorporate,
      'kid-member': isJuniorBluebirds
    }">
    <div class="d-flex flex-column justify-content-between h-100">
      <div class="d-flex justify-content-between">
        <!--Name and ID-->
        <div class="card-id text-start">
          <h5 class="mb-1 font-size-14 card-name" *ngIf="memberDetails$ | async as memberDetails">
            {{ memberDetails.FirstName }}
          </h5>
          <div class="d-flex font-size-10">
            <div *notInPopulation="populations.JuniorBluebirds">{{ 'member.card.product.code' | translate }}</div>
            <div *inPopulation="populations.JuniorBluebirds">{{ 'member.card.product.code.kid' | translate }}</div>
            <div class="px-2">|</div>
            <div>ID: {{ memberCard.MemberId }}</div>
          </div>
        </div>
        <!--Logo-->
        <ng-container *ngIf="!isCorporate && !isJuniorBluebirds"><img class="card-logo" src="assets/images/logo.svg"
            draggable="false" /></ng-container>
        <ng-container *ngIf="isCorporate"><img class="card-logo" src="assets/images/corporate-logo.png"
            draggable="false" /></ng-container>
        <ng-container *ngIf="isJuniorBluebirds"><img class="kids-card-logo" src="assets/images/kids-logo-v2.png"
            draggable="false" /></ng-container>
      </div>

      <!--Balances-->
      <div class="d-flex justify-content-between align-items-center" [ngClass]="{'invisible': isJuniorBluebirds}">
        <div class="d-flex align-items-end mb-1">
          <div class="card-account-no text-start pe-3" *ngIf="pointsBalance$">
            <label class="mb-1 font-weight-400">{{'member.card.points.balance' | translate }}</label>
            <div class="font-size-14 font-sans-bold">{{ pointsBalance$ | async | number:'1.0-0' }}</div>
          </div>
        </div>
        <div class="d-block d-lg-none">
          <div class="barcode d-lg-none d-block">
            <button type="button"
              class="btn btn-white wallet-button border-0 p-0 d-flex justify-content-center align-items-center"
              [ngClass]="isCorporate ? 'text-primary-corporate' : 'text-primary'" [routerLink]="['/rewards/wallet']">
              <span class="icon icon-qr-code font-weight-bold"></span>
              <span class="ms-1 font-weight-bold font-size-14">{{ 'member.card.show' | translate }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>