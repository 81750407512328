/** A list of helpful regular expressions to keep validation consistent throughout the site. */
export class Regex {
  /** Validates an email address. */
  static email =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  /** Validates a post code. */
  static postCode = /^([a-zA-Z][a-hj-yA-HJ-Y]?[0-9][A-Z0-9]? ?[0-9][a-zA-Z]{2}|GIR ?0A{2})$/;

  /** Validates a gender input field. */
  static gender = /^(M|F|O)$/;

  /** Validates a number input field */
  static number = /^\d+$/;

  /** Validates currency for transfers */
  static currency = /^[0-9]+(\.[0-9]{1,2})?$/;
}
