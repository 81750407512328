<ng-container *ngIf="fantasyHistory$ | async as fantasyHistory">
  <ng-container *ngIf="fantasyHistory.length > 0; else noPlayerHistory">
    <div class="card border-disabled rounded px-2 mx-1 py-2 py-lg-0">
      <ng-container *ngFor="let item of fantasyHistory">
        <fgb-fantasy-history-list-item [fantasyHistoryItem]="item"></fgb-fantasy-history-list-item>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #noPlayerHistory>
    <div class="no-items w-100 text-center no-history-placeholder border rounded">
      <p class="text-black text-center font-size-14 w-100 no-history-description mb-0">
        {{ 'pick.player.history.placeholder.description' | translate }}
      </p>
    </div>
  </ng-template>
</ng-container>
