import { Component, OnInit } from '@angular/core';
import { CardLinkOfferOptions, CardLinkOfferType } from '@fgb/core';

@Component({
  selector: 'fgb-card-link-activated-offers-page',
  templateUrl: './card-link-activated-offers-page.component.html',
  styleUrls: ['./card-link-activated-offers-page.component.scss'],
})
export class CardLinkActivatedOffersPageComponent implements OnInit {
  cardLinkOfferOptionsActivatedOffers: CardLinkOfferOptions = {
    offerType: CardLinkOfferType.ActivatedOffers,
    limit: 4,
    showMoreButton: true,
    showMoreIncrement: 4,
  };

  cardLinkOfferOptionsRecommendedOffers: CardLinkOfferOptions = {
    offerType: CardLinkOfferType.OffersYouMayLike,
    limit: 4,
    showMoreButton: false,
    showMoreIncrement: 1,
  };

  constructor() {}

  ngOnInit(): void {}
}
