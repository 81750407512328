<div id="main" [class]="population?.DisplayName">
  <fgb-navigation-indicator></fgb-navigation-indicator>

  <ng-container *ngIf="!navigationSettings?.mobile.hidden">
    <fgb-mobile-nav-menu></fgb-mobile-nav-menu>
  </ng-container>
  
  <ng-container *ngIf="!navigationSettings?.desktop.hidden">
    <fgb-nav-bar></fgb-nav-bar>
  </ng-container>

  <router-outlet></router-outlet>
</div>

<fgb-footer></fgb-footer>
