<div class="d-block">
  <ul class="nav nav-secondary mixed-loyalty-nav">
    <a
      class="nav-item reward-nav-item"
      [ngClass]="isCorporate ? 'corporate' : 'membership'"
      [routerLink]="['/games/quiz']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <div class="icon material-icons">live_help</div>
      <label>{{ 'games.nav.trivia' | translate }}</label>
    </a>
    <a
      class="nav-item reward-nav-item"
      [ngClass]="isCorporate ? 'corporate' : 'membership'"
      [routerLink]="['/games/polls']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <div class="icon material-icons">assessment</div>
      <label>{{ 'games.nav.polls' | translate }}</label>
    </a>
    <a
      class="nav-item reward-nav-item"
      [ngClass]="isCorporate ? 'corporate' : 'membership'"
      [routerLink]="['/games/predictor']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
    <div class="icon material-icons">sports_score</div>
      <label>{{ 'games.nav.predictor' | translate }}</label>
    </a>
    <a
      class="nav-item reward-nav-item"
      [ngClass]="isCorporate ? 'corporate' : 'membership'"
      [routerLink]="['/games/pickaplayer']"
      [routerLinkActive]="'active'"
      [routerLinkActiveOptions]="{ exact: false }"
    >
      <div class="icon material-icons">supervised_user_circle</div>
      <label>{{ 'games.nav.pick.player' | translate }}</label>
    </a>
  </ul>
</div>
