import { NgModule } from '@angular/core';
import { FeaturedRewardsComponent } from './components/featured-rewards/featured-rewards.component';
import { MixedLoyaltyListComponent } from './components/mixed-loyalty-list/mixed-loyalty-list.component';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { UserModule } from '../user/user.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MixedLoyaltyItemComponent } from './components/mixed-loyalty-item/mixed-loyalty-item.component';
import { MarketplaceDetailsComponent } from './components/marketplace-details/marketplace-details.component';
import { MarketplaceListComponent } from './components/marketplace-list/marketplace-list.component';
import { MarketplaceListItemComponent } from './components/marketplace-list-item/marketplace-list-item.component';
import { AuctionDetailsComponent } from './components/auction-details/auction-details.component';
import { AuctionPanelComponent } from './components/auction-panel/auction-panel.component';
import { AuctionListComponent } from './components/auction-list/auction-list.component';
import { LottosListItemComponent } from './components/lottos-list-item/lottos-list-item.component';
import { LottosListComponent } from './components/lottos-list/lottos-list.component';
import { LottoDetailsComponent } from './components/lotto-details/lotto-details.component';
import { LottosWalletListComponent } from './components/lottos-wallet-list/lottos-wallet-list.component';
import { LottosWalletListItemComponent } from './components/lottos-wallet-list-item/lottos-wallet-list-item.component';
import { LottosWalletDetailsComponent } from './components/lottos-wallet-details/lottos-wallet-details.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { LayoutModule } from '../layouts/layout.module';
import { WorksheetListCarouselComponent } from './components/worksheet-list-carousel/worksheet-list-carousel.component';
import { WorksheetItemComponent } from './components/worksheet-item/worksheet-item.component';
import { CollectAddressModal } from './components/collect-address-modal/collect-address-modal.component';
import { SubscriptionsListComponent } from './components/subscriptions-list/subscriptions-list.component';
import { SubscriptionsListItemComponent } from './components/subscriptions-list-item/subscriptions-list-item.component';
import { SubscriptionsListItemRenewalsComponent } from './components/subscriptions-list-item-renewals/subscriptions-list-item-renewals.component';
import { SubscriptionsListItemSubscribedComponent } from './components/subscriptions-list-item-subscribed/subscriptions-list-item-subscribed.component';
import { CartItemSubscriptionsComponent } from './components/cart-item-subscriptions/cart-item-subscriptions.component';
import { CartSubscriptionsModalComponent } from './components/cart-item-subscriptions/cart-subscriptions-modal/cart-subscriptions-modal.component';
import { ScorecardComponent } from './components/scorecard/scorecard.component';
import { MarketplaceAttributesComponent } from './components/marketplace-details/marketplace-attributes/marketplace-attributes.component';
import { MarketplaceCustomFieldsComponent } from './components/marketplace-details/marketplace-custom-fields/marketplace-custom-fields.component';
import { MarketplaceActionsComponent } from './components/marketplace-details/marketplace-actions/marketplace-actions.component';
import { MarketplacePurchaseConfirmationComponent } from './components/marketplace-details/marketplace-purchase-confirmation/marketplace-purchase-confirmation.component';
import { WorksheetListComponent } from './components/worksheet-list/worksheet-list.component';
import { BadgeFeaturedListComponent } from './components/badge-featured-list/badge-featured-list.component';
import { BadgeListItemComponent } from './components/badge-list-item/badge-list-item.component';
import { MarketplaceListModule } from '@fgb/portal-component-library/src/lib/loyalty/marketplace/marketplace-list';

@NgModule({
  declarations: [
    MixedLoyaltyItemComponent,
    FeaturedRewardsComponent,
    MixedLoyaltyListComponent,
    MarketplaceDetailsComponent,
    MarketplaceListComponent,
    MarketplaceListItemComponent,
    AuctionDetailsComponent,
    AuctionPanelComponent,
    AuctionListComponent,
    LottosListItemComponent,
    LottosListComponent,
    LottoDetailsComponent,
    LottosWalletListComponent,
    LottosWalletListItemComponent,
    LottosWalletDetailsComponent,
    WorksheetListCarouselComponent,
    WorksheetListComponent,
    WorksheetItemComponent,
    CollectAddressModal,
    SubscriptionsListComponent,
    SubscriptionsListItemComponent,
    SubscriptionsListItemRenewalsComponent,
    SubscriptionsListItemSubscribedComponent,
    CartItemSubscriptionsComponent,
    CartSubscriptionsModalComponent,
    ScorecardComponent,
    MarketplaceAttributesComponent,
    MarketplaceCustomFieldsComponent,
    MarketplaceActionsComponent,
    MarketplacePurchaseConfirmationComponent,
    BadgeFeaturedListComponent,
    BadgeListItemComponent,
  ],
  exports: [
    MixedLoyaltyItemComponent,
    FeaturedRewardsComponent,
    MixedLoyaltyListComponent,
    MarketplaceDetailsComponent,
    MarketplaceListComponent,
    MarketplaceListItemComponent,
    AuctionDetailsComponent,
    AuctionPanelComponent,
    AuctionListComponent,
    LottosListItemComponent,
    LottosListComponent,
    LottoDetailsComponent,
    LottosWalletListComponent,
    LottosWalletListItemComponent,
    LottosWalletDetailsComponent,
    WorksheetListCarouselComponent,
    WorksheetListComponent,
    WorksheetItemComponent,
    CollectAddressModal,
    SubscriptionsListComponent,
    SubscriptionsListItemComponent,
    SubscriptionsListItemRenewalsComponent,
    SubscriptionsListItemSubscribedComponent,
    CartItemSubscriptionsComponent,
    CartSubscriptionsModalComponent,
    ScorecardComponent,
  ],
  imports: [
    RouterModule,
    NgbModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CarouselModule,
    BrowserAnimationsModule,
    SharedModule,
    InlineSVGModule.forRoot(),
    UserModule,
    LayoutModule,
    MarketplaceListModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: function HttpLoaderFactory(http: HttpClient) {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
  ],
  // entryComponents: [MarketplacePurchaseConfirmationComponent],
})
export class LoyaltyModule {}
