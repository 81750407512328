<div class="container">
  <div class="text-return pt-3 cursor-pointer d-none d-lg-block" [routerLink]="'/rewards'">
    <span class="material-icons align-middle fw-bold pe-2">navigate_before</span>
    <span class="font-size-14 fw-bold">{{ 'back.to.marketplace' | translate }}</span>
  </div>
</div>
<!--Point Summary Bar-->
<div class="container">
  <fgb-points-summary-bar [backButtonLink]="'/rewards'" [backButtonTitle]="'Rewards'"></fgb-points-summary-bar>
</div>

<!--Lotto Details-->
<fgbcl-lotto-details [lottoId]="id"></fgbcl-lotto-details>