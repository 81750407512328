<div class="survey-container overflow-hidden rounded quiz">
  <div class="survey-header row">
    <div class="image-container col-lg-6 col-12" [ngClass]="isCorporate ? 'bg-primary-corporate' : 'bg-primary'">
      {{'game.trivia.title' | translate}}
      <div class="sponsor-link" *ngIf="survey.Sponsor && survey.Sponsor.SponsorImage">
        <label class="sponsor-label d-lg-block font-weight-normal font-size-10 text-white me-lg-2 mb-0">PRESENTED BY</label>
        <a class="sponsor-image" href="{{ survey.Sponsor.SponsorLink }}" target="_blank">
          <img
            src="{{ survey.Sponsor.SponsorImage | contentImage: '6' }}"
            draggable="false"
            alt="Click to access {{ survey.Sponsor.SponsorText }} website"
          />
        </a>
      </div>
    </div>
    <div class="col-lg-6 question-container pt-2 col-12 d-flex flex-column justify-content-between">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <div class="icon material-icons" [ngClass]="isCorporate ? 'text-primary-corporate' : 'text-primary'">live_help</div>
        </div>
      </div>
      <div class="survey-title line-height-18 d-flex align-self-center h-60 font-size-14 w-100">
        <div>{{ survey.SurveyQuestion }}</div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="comingSoon">
    <p class="text-center">{{ 'trivia.coming.soon' | translate }}</p>
  </ng-container>

  <ng-container *ngIf="!comingSoon">
    <ng-container *ngIf="survey.HasBeenAnsweredByUser; else answer">
      <fgb-survey-item-answer-completed-quiz [survey]="survey"></fgb-survey-item-answer-completed-quiz>
    </ng-container>
    <ng-template #answer>
      <fgb-survey-item-answer [survey]="survey"></fgb-survey-item-answer>
    </ng-template>
  </ng-container>
</div>
