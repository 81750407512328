import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QRCodeModule } from 'angularx-qrcode';
import { ChildPanelComponent } from './components/child-panel/child-panel.component';
import { ChildListComponent } from './components/child-list/child-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { UpgradeChildrenComponent } from './components/upgrade-children/upgrade-children.component';
import { UserModule } from '../user/user.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { BackToGuardianComponent } from './components/back-to-guardian/back-to-guardian.component';
import { ChildCheckInAttendanceComponent } from './components/child-check-in-attendance/child-check-in-attendance.component';
import { RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ChildBarcodesCarouselComponent } from './components/child-barcodes-carousel/child-barcodes-carousel.component';
import { RedsoxChildrenTicketCodesComponent } from './components/redsox-children-ticket-codes/redsox-children-ticket-codes.component';

@NgModule({
  imports: [
    NgbModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CarouselModule,
    RouterModule,
    InlineSVGModule,
    BrowserAnimationsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: function HttpLoaderFactory(http: HttpClient) {
          return new TranslateHttpLoader(http, './assets/i18n/', '.json');
        },
        deps: [HttpClient],
      },
    }),
    QRCodeModule,
    UserModule,
    SharedModule,
  ],
  declarations: [
    ChildPanelComponent,
    ChildListComponent,
    UpgradeChildrenComponent,
    BackToGuardianComponent,
    ChildCheckInAttendanceComponent,
    ChildBarcodesCarouselComponent,
    RedsoxChildrenTicketCodesComponent,
  ],
  exports: [
    ChildPanelComponent,
    ChildListComponent,
    BackToGuardianComponent,
    ChildCheckInAttendanceComponent,
    ChildBarcodesCarouselComponent,
    RedsoxChildrenTicketCodesComponent,
  ],
  // entryComponents: [ChildBarcodesCarouselComponent],
})
export class GuardianModule {}
