<div class="footer text-white text-center">
  <div
    class="footer-text d-flex align-items-center justify-content-center mt-4"
    [ngClass]="isCorporate ? 'bg-primary-corporate' : 'bg-primary'"
  >
    <div class="footer-content pt-3">
      <div class="justify-content-center d-lg-flex">
        <div class="d-flex justify-content-center mb-4">
          <a href="{{ 'footer.visit.malaysia.url' | translate }}" target="_blank">
            <img class="visit-malaysia m-2 mx-3" src="assets/images/footer/visit_malaysia-2.png" draggable="false" />
          </a>
          <a href="{{ 'footer.new.balance.url' | translate }}" target="_blank">
            <img class="nbalance mx-3" src="assets/images/footer/new_balance-2.png" draggable="false" />
          </a>
        </div>
        <div class="d-flex justify-content-center">
          <a href="{{ 'footer.carling.url' | translate }}" target="_blank">
            <img
              class="carling mx-3 m-1"
              src="assets/images/footer/carling_logo_v2.png"
              draggable="false"
              *notInPopulation="populations.JuniorBluebirds"
            />
          </a>
          <a href="{{ 'footer.nathaniel.cars.url' | translate }}" target="_blank">
            <img class="ncars m-2 mx-lg-3 mx-1" src="assets/images/footer/nathaniel-logo-white.png" draggable="false" />
          </a>
        </div>
      </div>
      <ng-container *ngIf="!isCorporate"
        ><img class="mt-5 mb-2 footer-club-logo" src="assets/images/logo.svg" draggable="false"
      /></ng-container>
      <ng-container *ngIf="isCorporate"
        ><img class="mt-5 mb-2 footer-club-logo" src="assets/images/corporate-logo.png" draggable="false"
      /></ng-container>
      <div
        class="copy-right text-white font-size-12 mt-2 text-center mb-4"
        [innerHTML]="'copy.right' | translate | markdownTranslate"
      ></div>
    </div>
  </div>
</div>
