import { Component, OnInit } from '@angular/core';
import { SportsAllianceAuthService } from '@fgb/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-sports-alliance-user-login',
  templateUrl: './sports-alliance-user-login.component.html',
  styleUrls: ['./sports-alliance-user-login.component.scss'],
})
export class SportsAllianceUserLoginComponent implements OnInit {
  constructor(private sportsAllianceService: SportsAllianceAuthService) {}

  ngOnInit() {}

  loginSportsAlliance() {
    firstValueFrom(this.sportsAllianceService.login());
  }
}
