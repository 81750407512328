import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  Barcode,
  MemberCardQuery,
  MemberDetails,
  MemberQuery,
  SeatingDetailQuery,
  SeatingDetail,
  PopulationQuery,
  BarcodeService,
  BarcodeQuery,
} from '@fgb/core';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { MemberCard } from '@fgb/core';
import { config } from '@fgb/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BalanceQuery } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss'],
})
export class BarcodeComponent implements OnInit, OnDestroy {
  @Input() memberId: string;
  barcode$: Observable<Barcode>;
  pointsBalance$: Observable<number | undefined>;
  ecashBalance$: Observable<number | undefined>;
  card$: Observable<MemberCard | undefined>;
  member$: Observable<MemberDetails | undefined>;
  private subscription: Subscription;
  seatingDetail$: Observable<SeatingDetail | undefined>;
  populations = Populations;
  isCorporate: boolean;
  isJuniorBlueBirds: boolean;
  constructor(
    private barcodeService: BarcodeService,
    private barcodeQuery: BarcodeQuery,
    private balanceQuery: BalanceQuery,
    private memberCardQuery: MemberCardQuery,
    private memberDetailsQuery: MemberQuery,
    private modalService: NgbModal,
    private seatingDetailQuery: SeatingDetailQuery,
    private populationQuery: PopulationQuery
  ) {}

  ngOnInit() {
    this.seatingDetail$ = this.seatingDetailQuery.selectCurrentSeatingDetails();
    this.member$ = this.memberDetailsQuery.selectMemberDetails();
    this.pointsBalance$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.ecashBalance$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.card$ = this.memberCardQuery.selectCurrentCard();
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
    this.isJuniorBlueBirds = this.populationQuery.userInPopulation([this.populations.JuniorBluebirds]);
    firstValueFrom(this.barcodeService.fetchBarcode());
    this.barcode$ = this.barcodeQuery.select();
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  close() {
    this.modalService.dismissAll();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
