import { Component, OnInit } from '@angular/core';
import { PopulationQuery } from '@fgb/core';
import { Populations } from 'src/app/shared/utilities/populations';

@Component({
  selector: 'fgb-badges-page',
  templateUrl: './badges-page.component.html',
  styleUrls: ['./badges-page.component.scss'],
})
export class BadgesPageComponent implements OnInit {
  populations = Populations;
  isCorporate: boolean;
  constructor(private populationQuery: PopulationQuery) {}

  ngOnInit(): void {
    this.isCorporate = this.populationQuery.userInPopulation([this.populations.Hospitality]);
  }
}
