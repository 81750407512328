<fgb-banner [title]="'terms.page.banner' | translate"></fgb-banner>

<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="pt-4 terms-page">
        <fgbcl-terms-and-conditions [type]="termsAndConditionsType" [defaultTerms]="defaultTemplate" [ngClass]="isCorporate ? 'corporate' : ''"></fgbcl-terms-and-conditions>
      </div>
    </div>
  </div>
</div>

<ng-template #defaultTemplate>
  <p class="terms-section-content text-start mb-3">Terms and Conditions currently unavailable.</p>
</ng-template>
